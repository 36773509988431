// @ts-nocheck
import { createAction, handleActions } from 'redux-actions'
import { LOCATION_CHANGE } from 'connected-react-router'
import { addInterestResponse, removeInterestResponse } from 'reducers/user'
import { favoriteUser, unfavoriteUser } from 'reducers/users'

// Fetch User
// -----------------------------------------------------------------------------

// Action Types
const USER_FETCH_REQUEST = 'userDetails/USER_FETCH_REQUEST'
const USER_FETCH_DETAILS_REQUEST = 'userDetails/USER_FETCH_DETAILS_REQUEST'
const USER_FETCH_DETAILS_RESPONSE = 'userDetails/USER_FETCH_DETAILS_RESPONSE'

// Action Creators
const fetchUserRequest = createAction(USER_FETCH_REQUEST)
const fetchUserDetailsRequest = createAction(USER_FETCH_DETAILS_REQUEST)
const fetchUserDetailsResponse = createAction(USER_FETCH_DETAILS_RESPONSE)

// Async Actions
export const fetchUserDetails = username => async (
  dispatch,
  getState,
  { v2: Api }
) => {
  dispatch(fetchUserDetailsRequest())

  try {
    const { data } = await Api.axios({
      url: '/api/user',
      params: {
        user: username,
      },
      method: 'GET',
      headers: {
        ...Api.getAuthHeaders(getState().user),
      },
    })

    const response = await Api.axios({
      url: `/api/interests_details`,
      params: {
        user: username,
      },
      method: 'GET',
      headers: {
        ...Api.getAuthHeaders(getState().user),
      },
    })
    const interests = response.data.interests
    return dispatch(fetchUserDetailsResponse({ ...data, interests, username }))
  } catch (error) {
    console.log('error', error)
    return dispatch(fetchUserDetailsResponse(error))
  }
}

// User Reducer
// -----------------------------------------------------------------------------

const buildUserDetails = (payload, state) => {
  const { user, interests } = payload
  user.interests = interests
  return user
}

const buildUserInterests = (payload, state) => {
  const { interest, username, interestData } = payload
  if (!username) return

  const newItems = state[username].interests
    ? state[username].interests.concat()
    : []
  const index = newItems.findIndex(item => item.interest === interest)
  if (index >= 0) newItems[index] = interestData

  return {
    ...state[username],
    interests: newItems,
  }
}

// Default State
const defaultState = {}

// Reducer
export default handleActions(
  {
    [LOCATION_CHANGE]: {
      next: state => {
        return {
          ...state,
          error_message: null,
          isLoading: false,
        }
      },
    },
    [fetchUserRequest]: {
      next: state => ({
        isLoading: true,
        ...state,
      }),
    },
    [fetchUserDetailsResponse]: {
      next: (state, { payload }) => {
        return {
          isLoading: false,
          ...state,
          [payload.username]: buildUserDetails(payload, state),
        }
      },
    },
    [favoriteUser]: {
      next: (state, { payload }) => {
        if (!state[payload]) {
          state[payload] = {}
        }
        const newState = Object.assign({}, state)
        newState[payload]['is_favorite'] = true
        return newState
      },
    },
    [unfavoriteUser]: {
      next: (state, { payload }) => {
        if (!state[payload]) {
          state[payload] = {}
        }
        const newState = Object.assign({}, state)
        newState[payload]['is_favorite'] = false
        return newState
      },
    },
    [addInterestResponse]: {
      next: (state, { payload }) => {
        Object.keys(state).forEach(key => {
          if (
            state[key] &&
            state[key].interests &&
            Array.isArray(state[key].interests)
          ) {
            payload.username = key
            state[key] = buildUserInterests(payload, state)
          }
        })
        return {
          isLoading: false,
          ...state,
        }
      },
    },
    [removeInterestResponse]: {
      next: (state, { payload }) => {
        Object.keys(state).forEach(key => {
          if (
            state[key] &&
            state[key].interests &&
            Array.isArray(state[key].interests)
          ) {
            payload.username = key
            state[key] = buildUserInterests(payload, state)
            if (payload.me === payload.username) {
              const index = state[payload.username].interests.findIndex(
                item => item.interest === payload.interest
              )
              state[payload.username].interests.splice(index, 1)
            }
          }
        })
        return {
          isLoading: false,
          ...state,
        }
      },
    },
  },

  defaultState
)
