import React, { Component } from 'react'
import styles from './NotificationDrawer.module.css'
import Session from 'api/Session'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as usersActions from 'reducers/users'
import * as userActions from 'reducers/user'

class NotificationDrawer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      notificationMsg: '',
      notificationVisible: false,
      incomingCall: false,
    }
  }

  componentDidMount() {
    this.listen()
  }

  componentWillUnmount() {
    console.log(`Notification componentWillUnmount`)
    this.close()
  }

  fetch = () => {
    const {
      actions: { fetchActiveChatSessions },
    } = this.props
    fetchActiveChatSessions()
  }

  close() {
    this.socket && (this.socket.onopen = () => {})
    this.socket && (this.socket.onclose = () => {})
    this.socket && (this.socket.onerror = () => {})
    this.socket && (this.socket.onmessage = () => {})
    this.socket && this.socket.close()
  }

  listen() {
    const origin = process.env.REACT_APP_WEB_PUSH_NOTIFICATIONS_ORIGIN
    const {
      actions: { unreadMessageCountResponse },
    } = this.props
    this.socket = new WebSocket(`${origin}/receive?token=${Session.getToken()}`)
    this.socket.onmessage = async e => {
      console.log(e, e.data)
      const data = JSON.parse(e.data)
      unreadMessageCountResponse(data.aps.badge)
      if (data.aps.action === 'incoming_call') {
        const origin = process.env.REACT_APP_ORIGIN
        const url = '/api/time'
        /** @type {HeadersInit} */
        const headers = {}
        headers['Content-Type'] = 'application/json'
        headers['Username'] = Session.currentUser().username
        headers['Authorization'] = 'Bearer ' + Session.getToken()
        headers['Accept'] = 'text/plain'
        let response = await fetch(origin + url, {
          method: 'GET',
          headers,
        })
        let { time } = await response.json()
        console.log(time)
        console.log(data.aps.time)
        let pastSeconds = time - data.aps.time
        console.log(pastSeconds)
        if (pastSeconds < 30) {
          this.setState({
            incomingCall: true,
            incomingCallData: data,
          })
        }
        return
      }
      this.setState({
        notificationMsg: data.aps.alert,
        notificationVisible: true,
      })
      setTimeout(() => {
        this.setState({
          notificationMsg: '',
          notificationVisible: false,
        })
      }, 3000)
      this.fetch()
    }
    this.socket.onerror = e => {
      console.log(e)
    }
    this.socket.onclose = e => {
      console.log(e)
      setTimeout(() => {
        this.listen()
      }, 2000)
    }
    this.socket.onopen = e => {
      console.log(e)
    }
  }

  onCallClick = e => {
    if (
      !this.state ||
      !this.state.incomingCallData ||
      !this.state.incomingCallData.aps
    ) {
      this.onGoBackClick(e)
      return
    }
    const { user, price } = this.state.incomingCallData.aps
    window.location.href = `/call/${user}/${price}/incoming`
  }

  onGoBackClick = e => {
    const user = Session.getUsername(Session.getToken())
    const callee = Session.getUsername(Session.getToken())
    const caller = this.state.incomingCallData.aps.user
    const room = `@${caller}${callee}`
    const signalingOrigin = process.env.REACT_APP_SIGNALING_ORIGIN
    fetch(`${signalingOrigin}/decline/${room}/${user}`, {
      headers: { 'Content-Type': 'text/plain' },
      body: '',
      method: 'POST',
    })

    this.setState({
      incomingCall: false,
      incomingCallData: null,
    })
  }
  render() {
    if (this.state.incomingCall) {
      const price = this.state.incomingCallData.aps.price
      const caller = this.state.incomingCallData.aps.user.toUpperCase()
      return (
        <div className={styles.incomingCall}>
          <div style={{ position: 'fixed', top: '20%', left: '0', right: '0' }}>
            <div
              style={{
                fontSize: `1.3em`,
                fontFamily: `'Oswald', sans-serif`,
                fontWeight: `bold`,
                width: `100%`,
                textAlign: `center`,
                margin: `0`,
                color: `white`,
              }}
            >
              {`@${caller}`} WANTS TO
              <br />
              <em>
                {price > 0
                  ? ` PAY ${Math.abs(price)} UEN${
                      Math.abs(price) === 1 ? `` : `S`
                    } PER MINUTE`
                  : price < 0
                  ? ` EARN ${Math.abs(price)} UEN${
                      Math.abs(price) === 1 ? `` : `S`
                    } PER MINUTE`
                  : `CHAT FOR FREE`}
              </em>
            </div>
          </div>
          <div
            style={{
              position: 'fixed',
              bottom: '20%',
              left: '0',
              right: '0',
              margin: '0 15px',
            }}
          >
            <div className="row">
              <div className="col-xs-6">
                <button
                  className="btn btn-default btn-block btn-md"
                  onClick={this.onGoBackClick}
                >
                  Decline
                </button>
              </div>
              <div className="col-xs-6">
                <button
                  className="btn btn-success btn-block btn-md"
                  onClick={this.onCallClick}
                >
                  Accept
                </button>
              </div>
            </div>
          </div>
        </div>
      )
    } else if (this.state.notificationVisible) {
      return (
        <div className={styles.notification}>
          <span>{this.state.notificationMsg}</span>
        </div>
      )
    } else return null
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user ? state.user : null,
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch,
  actions: bindActionCreators(
    {
      ...usersActions,
      ...userActions,
    },
    dispatch
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationDrawer)
