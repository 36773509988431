import React, { Component } from 'react'
import { goBack } from 'connected-react-router'
import NavBar from 'components/NavBar'
import Toggle from 'react-toggle'
import styles from './Privacy.module.scss'
import { withRouter } from 'react-router-dom'
import * as userActions from 'reducers/user'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withLastLocation } from 'react-router-last-location'

class Privacy extends Component {
  onBackClick = () => {
    if (!this.props.lastLocation) {
      return this.props.history.push('/search/users')
    } else {
      const { dispatch } = this.props
      dispatch(goBack())
    }
  }

  toggleFullname = () => {
    const {
      actions: { updateUser },
      user,
    } = this.props
    user.discoverable_fullname === 'YES'
      ? (user.discoverable_fullname = 'NO')
      : (user.discoverable_fullname = 'YES')
    updateUser({ discoverable_fullname: user.discoverable_fullname })
  }

  toggleUsername = () => {
    const {
      actions: { updateUser },
      user,
    } = this.props
    user.discoverable_username === 'YES'
      ? (user.discoverable_username = 'NO')
      : (user.discoverable_username = 'YES')
    updateUser({ discoverable_username: user.discoverable_username })
  }

  render() {
    const { user } = this.props

    return (
      <div>
        <div
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            right: '0',
            height: 'calc(1.5em + 20px)',
          }}
        >
          <NavBar title={`Privacy`} onBackClick={this.onBackClick} />
        </div>
        <div
          className="container-fluid"
          style={{
            position: 'fixed',
            backgroundColor: '#2c2d2e',
            top: 'calc(1.5em + 20px)',
            left: '0',
            right: '0',
            height: 'calc(100% - 1.5em - 20px - 34px)',
            overflowX: 'hidden',
            overflowY: 'scroll',
            WebkitOverflowScrolling: 'touch',
            color: 'white',
            marginLeft: 0,
          }}
        >
          <h6>YOU ARE DISCOVERABLE VIA:</h6>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              margin: '25px 15px 10px 10px',
            }}
          >
            <div
              style={{
                width: '85%',
                justifyContent: 'end',
              }}
            >
              <h3
                style={{
                  margin: '0',
                  fontFamily: 'League Gothic',
                  fontWeight: 'normal',
                }}
              >
                USERNAME
              </h3>
              <p>@{user.username}</p>
            </div>

            <div
              style={{
                width: '15%',
                justifyContent: 'end',
                paddingTop: '5px',
              }}
            >
              <Toggle
                defaultChecked={
                  user.discoverable_fullname === 'YES' ? true : false
                }
                onChange={this.toggleFullname}
              />
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              margin: '0px 10px',
            }}
          >
            <div
              style={{
                width: '85%',
                justifyContent: 'end',
              }}
            >
              <h3
                style={{
                  margin: '0',
                  fontFamily: 'League Gothic',
                  fontWeight: 'normal',
                }}
              >
                FULLNAME
              </h3>
              <p>{user.fullname}</p>
            </div>

            <div
              style={{
                width: '15%',
                justifyContent: 'end',
                paddingTop: '5px',
              }}
            >
              <Toggle
                defaultChecked={
                  user.discoverable_username === 'YES' ? true : false
                }
                onChange={this.toggleUsername}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user ? state.user : {},
})

const mapDispatchToProps = dispatch => ({
  dispatch,
  actions: bindActionCreators(
    {
      ...userActions,
    },
    dispatch
  ),
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withLastLocation(Privacy))
)
