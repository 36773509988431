import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getInterests } from 'store/selectors'

import {
  SignUpForm,
  PasswordRecoveryForm,
  AboutYouForm,
  AboutYouPhotoForm,
  InterestsForm,
  ButtonsForm,
} from './Forms'
import { Link } from 'react-router-dom'

import * as userActions from 'reducers/user'
import * as interestsActions from 'reducers/interests'

class SignUpPage extends Component {
  static defaultProps = {
    user: {},
  }

  state = {
    currentStep: 1,
  }

  componentDidUpdate(prevProps) {
    const {
      user: { token },
    } = this.props

    if (token && prevProps.user.token !== token) {
      this.setState({ currentStep: 2 })
    }
  }

  goTo = step => {
    this.setState({
      currentStep: step,
    })
  }

  onSignUp = values => {
    const {
      actions: { signUp, setAppDetails },
    } = this.props

    signUp(values).then(res => {
      if (res.token) {
        const payload = {
          version: 'web',
          language: 'en',
        }
        setAppDetails(payload)
      }
    })
  }

  onPasswordRecovery = values => {
    const {
      actions: { updateUser },
    } = this.props

    updateUser(values, false)
    /**
     * TODO: Figure out a better flow to catch errors here
     */
    this.setState({
      currentStep: 3,
    })
  }

  onAboutYou = values => {
    const {
      actions: { updateUser },
    } = this.props

    updateUser(values, false)
    /**
     * TODO: Figure out a better flow to catch errors here
     */
    this.setState({
      currentStep: 4,
    })
  }

  onAboutYouPhoto = formData => {
    const {
      actions: { updateUserPhoto },
    } = this.props
    updateUserPhoto(formData)
  }

  finish = async () => {
    const {
      actions: { fetchUser },
    } = this.props
    fetchUser()
  }

  render() {
    const {
      user,
      interests,
      newInterests,
      actions,
      isLoadingInterest,
    } = this.props
    const { currentStep } = this.state

    return (
      <div
        style={{
          backgroundColor: '#191919',
          position: 'fixed',
          top: '0',
          left: '0',
          right: '0',
          height: '100%',
          overflowX: 'hidden',
          overflowY: 'scroll',
          WebkitOverflowScrolling: 'touch',
          color: '#fff',
        }}
      >
        <div>
          <div className="row">
            <div className="col-lg-12">
              {currentStep === 1 ? (
                <SignUpForm user={user} onSubmit={this.onSignUp} />
              ) : currentStep === 2 ? (
                <PasswordRecoveryForm
                  onSkip={() => this.goTo(3)}
                  user={user}
                  onSubmit={this.onPasswordRecovery}
                />
              ) : currentStep === 3 ? (
                <>
                  <AboutYouPhotoForm
                    onSkip={() => this.goTo(4)}
                    user={user}
                    onBack={() => this.goTo(2)}
                    onSubmit={this.onAboutYouPhoto}
                  />
                  <AboutYouForm user={user} onSubmit={this.onAboutYou} />
                </>
              ) : currentStep === 4 ? (
                <InterestsForm
                  newInterests={newInterests}
                  onSkip={() => this.goTo(5)}
                  onBack={() => this.goTo(3)}
                  user={user}
                  interests={interests}
                  actions={actions}
                  isLoadingInterest={isLoadingInterest}
                />
              ) : currentStep === 5 ? (
                <ButtonsForm onFinished={this.finish} />
              ) : null}
              {currentStep === 1 && (
                <div className="container-fluid">
                  <br />
                  <br />
                  <p>
                    Already have an account? <Link to="/login">Log In</Link>
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user ? state.user : {},
  interests: getInterests(state, 'top16'),
  isLoadingInterest: state.interests.isLoading, // forces a re-render
  isLoading: state.users.isLoading, // forces a re-render
  newInterests: state.interests.newInterests
    ? state.interests.newInterests
    : [],
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...userActions,
      ...interestsActions,
    },
    dispatch
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUpPage)
