import React from 'react'
import { withRouter } from 'react-router-dom'

const ForgotPassword = ({ history }) => {
  const onBack = () => {
    history.goBack()
  }

  return (
    <div
      style={{
        backgroundColor: '#191919',
        position: 'fixed',
        left: '0',
        right: '0',
        height: '100%',
        color: '#fff',
      }}
    >
      <div style={{ marginTop: '20px', marginLeft: '20px' }} onClick={onBack}>
        <i
          style={{ color: 'white', fontSize: 'x-large' }}
          className="fa fa-angle-left"
        />
      </div>
      <div className="container-fluid">
        <h3 style={{ marginBottom: '20px' }}>Forgot Password?</h3>
        <p style={{ marginBottom: '10px' }}>
          Password recovery is only possible if you connected you Email or Phone
          Number during registration.
        </p>
        <p style={{ marginBottom: '50px' }}>
          You can read more about our{' '}
          <a href="https://www.uenme.co/privacypolicy">privacy policy here.</a>
        </p>
        <button
          className="btn btn-primary btn-block btn-lg"
          style={{
            borderRadius: '25px',
            backgroundColor: '#00abea',
            color: 'white',
            borderColor: '#00abea',
            marginTop: '10px',
            marginBottom: '15px',
            fontSize: '1em',
          }}
          onClick={() => history.push('/forgot-password/email')}
        >
          RECOVER VIA EMAIL
        </button>
        <button
          className="btn btn-primary btn-block btn-lg"
          style={{
            borderRadius: '25px',
            backgroundColor: '#00abea',
            color: 'white',
            borderColor: '#00abea',
            marginTop: '10px',
            fontSize: '1em',
          }}
          onClick={() => history.push('/forgot-password/sms')}
        >
          RECOVER VIA PHONE NUMBER
        </button>
      </div>
    </div>
  )
}

export default withRouter(ForgotPassword)
