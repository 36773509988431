export const getUsers = (state, path = '/') => {
  if (state.users && state.users[path]) {
    return state.users[path]
  }
  return []
}

export const getUser = (state, username) => {
  if (state.userDetails && state.userDetails[username]) {
    return state.userDetails[username]
  }
  return {}
}

export const getUserInterests = (state, username) => {
  if (
    state.userDetails &&
    state.userDetails[username] &&
    state.userDetails[username].interests
  ) {
    return state.userDetails[username].interests
  }
  return []
}

export const getInterest = (state, path) => {
  if (state.interestDetails && state.interestDetails[path]) {
    return state.interestDetails[path]
  }
  return {}
}

export const getInterests = (state, path) => {
  if (state.interests && state.interests[path]) {
    return state.interests[path]
  }
  return []
}
