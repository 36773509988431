/* eslint-disable jsx-a11y/anchor-is-valid */
// @ts-nocheck
import React, { Component } from 'react'
import cx from 'classnames'
import styles from './Forms.module.scss'
import priming from '../../../assets/images/priming.png'

class ButtonsForm extends Component {
  state = {
    buttons: [
      {
        name: 'Enable Camera',
        className: 'fa-camera',
        isActivated: false,
      },
      {
        name: 'Enable Microphone',
        className: 'fa-microphone',
        isActivated: false,
      },
      {
        name: 'Enable Notifications',
        className: 'fa-exclamation',
        isActivated: false,
      },
    ],
  }

  toggleButton = index => {
    const { buttons } = this.state

    const newButtons = buttons.concat()
    newButtons[index].isActivated = true

    const activatedButtons = newButtons.filter(({ isActivated }) => isActivated)
    const { onFinished } = this.props

    if (activatedButtons.length === 3) {
      return onFinished()
    }
    this.setState({ buttons: newButtons })
  }

  componentDidUpdate(_, prevState) {}

  render() {
    const { buttons } = this.state
    return (
      <div className="signup">
        <h3
          style={{
            marginTop: '50px',
            marginLeft: '20px',
            marginBottom: '20px',
          }}
        >
          Welcome to Uenme!
        </h3>
        <p
          style={{
            marginLeft: '28px',
            marginRight: '28px',
            marginBottom: '40px',
          }}
        >
          To get the most out of it, we need you to enable access to your camera
          and microphone.
        </p>
        <ul
          className="list-unstyled"
          style={{
            margin: '0 20px',
          }}
        >
          {buttons.map(({ name, className, isActivated }, index) => (
            <li key={name} className={styles.buttonListItem}>
              <a
                rel="presentation"
                href="#"
                onClick={this.toggleButton.bind(this, index)}
                title={name}
                className={cx(
                  'btn btn-md btn-block',
                  styles.buttonListItemButton,
                  { [styles.buttonListItemButtonActive]: isActivated }
                )}
              >
                {!isActivated ? (
                  <figure
                    className={cx(
                      'fa fa-fw',
                      className,
                      styles.buttonListItemIcon
                    )}
                  />
                ) : (
                  <figure
                    className={cx('fa fa-check', styles.buttonListItemIcon)}
                  />
                )}
                {name}
              </a>
            </li>
          ))}
        </ul>
        <div style={{ position: 'fixed', bottom: '0', textAlign: 'center' }}>
          <img src={priming} alt="" style={{ width: '80%' }} />
        </div>
      </div>
    )
  }
}

export default ButtonsForm
