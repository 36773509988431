import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as userActions from 'reducers/user'
import { withRouter } from 'react-router-dom'
// @ts-ignore
import uenme from '../../assets/images/uenme-white.png'
import { Formik } from 'formik'
import * as Yup from 'yup'
import FloatingLabel from 'floating-label-react'
import cx from 'classnames'
import swal from 'sweetalert'
import styles from './ResetPassword.module.scss'

const VALIDATION = {
  new_password: Yup.string()
    .min(3)
    .max(64)
    .required('Required'),
}

class ResetPassword extends Component {
  resetPassword = async newPassword => {
    const {
      actions: { resetPassword },
      match: { params },
    } = this.props
    const result = await resetPassword({ ...params, ...newPassword })
    // @ts-ignore
    if (result.error) {
      return swal({
        title: 'Reset Password Failed!',
        text: 'Invalid Password Recovery Request.',
        className: styles.swalModal,
      })
    }
  }

  render() {
    return (
      <div
        style={{
          backgroundColor: '#191919',
          position: 'fixed',
          left: '0',
          right: '0',
          height: '100%',
          color: '#fff',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <img
            src={uenme}
            alt=""
            style={{
              width: '30%',
              paddingTop: '50px',
              paddingBottom: '50px',
            }}
          />
        </div>
        <div className="container-fluid">
          <h4>Welcome back!</h4>
          <h5>Reset your password below.</h5>
          <div className="signup">
            <Formik
              validateOnBlur={false}
              initialValues={{ new_password: '' }}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false)
                this.resetPassword(values)
              }}
              validationSchema={Yup.object().shape(VALIDATION)}
            >
              {props => {
                const {
                  values,
                  touched,
                  errors,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                } = props
                return (
                  <form className="form" onSubmit={handleSubmit}>
                    <div
                      className="form-group"
                      style={{ marginBottom: '30px', marginTop: '20px' }}
                    >
                      <FloatingLabel
                        id="new_password"
                        type="password"
                        placeholder="Password"
                        autoComplete="off"
                        value={values.new_password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={cx('', {
                          'has-error':
                            errors.new_password && touched.new_password,
                        })}
                      />
                      {errors.new_password && touched.new_password && (
                        <div className="invalid-feedback">
                          {errors.new_password}
                        </div>
                      )}
                    </div>
                    <button
                      className="btn btn-primary btn-block btn-lg"
                      style={{
                        borderRadius: '3px',
                        backgroundColor: values.new_password
                          ? '#00abea'
                          : '#8e8b8f',
                        color: 'white',
                        borderColor: values.new_password
                          ? '#00abea'
                          : '#8e8b8f',
                        marginTop: '10px',
                        marginBottom: '15px',
                        fontSize: '1.4em',
                        height: '50px',
                      }}
                      onClick={() => console.log('object')}
                      disabled={isSubmitting}
                    >
                      Save
                    </button>
                  </form>
                )
              }}
            </Formik>
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...userActions,
    },
    dispatch
  ),
})

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(ResetPassword)
)
