/**
 * Version 2 of the API
 * A more simplified wrapper for API calls,
 * using [axios](https://github.com/axios/axios).
 */
import axios from 'axios'

class Fetch {
  /**
   * View helper function, useful to quickly generate headers.
   * Might refactor this to accept things differently, but works for now.
   */
  getAuthHeaders = user => {
    return {
      ...this.headers,
      Username: user.username,
      Authorization: `Bearer ${user.token}`,
    }
  }

  constructor() {
    this.axios = axios.create()
    this.axios.defaults.baseURL = process.env.REACT_APP_ORIGIN

    this.headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
  }
}

export default Fetch
