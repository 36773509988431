import React, { Component } from 'react'
import { goBack } from 'connected-react-router'
import NavBar from 'components/NavBar'
import { connect } from 'react-redux'
import { withLastLocation } from 'react-router-last-location'

class AccountLayout extends Component {
  onBackClick = () => {
    if (!this.props.lastLocation) {
      return this.props.history.push('/search/users')
    } else {
      const { dispatch } = this.props
      dispatch(goBack())
    }
  }

  render() {
    const { children } = this.props
    return (
      <div>
        <div
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            right: '0',
            height: 'calc(1.5em + 20px)',
          }}
        >
          <NavBar title={`Account Settings`} onBackClick={this.onBackClick} />
        </div>
        <div
          style={{
            backgroundColor: 'white',
            position: 'fixed',
            top: 'calc(1.5em + 20px)',
            left: '0',
            right: '0',
            height: 'calc(100% - 1.5em - 20px - 34px)',
            overflowX: 'hidden',
            overflowY: 'scroll',
            WebkitOverflowScrolling: 'touch',
          }}
        >
          {children}
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch,
})

export default withLastLocation(connect(mapDispatchToProps)(AccountLayout))
