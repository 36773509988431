// @ts-nocheck
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import cx from 'classnames'
import styles from './Account.module.scss'
import RangeSlider from 'components/RangeSlider'
import { Link, withRouter } from 'react-router-dom'
import Signout from './Signout'

import * as userActions from 'reducers/user'

class Account extends Component {
  static defaultProps = {
    user: {
      thumb: 'default.jpg',
    },
  }

  componentDidMount() {
    this.fetch()
  }

  fetch = () => {
    const {
      actions: { fetchUser },
    } = this.props

    fetchUser()
  }

  onAvailable = isAvailable => {
    const {
      actions: { updateUser },
    } = this.props

    updateUser({
      available: isAvailable ? 'NO' : 'YES',
    })
  }

  onUpdateCost = cost_per_min => {
    const {
      actions: { updateUser },
    } = this.props

    updateUser({
      cost_per_min,
    })
  }

  render() {
    const origin = process.env.REACT_APP_ORIGIN
    const { user } = this.props

    const { username, thumb, available, cost_per_min, uens } = user
    const isAvailable = available === 'YES'

    return (
      <div
        style={{
          backgroundColor: '#2c2d2f',
          position: 'fixed',
          top: '0',
          left: '0',
          right: '0',
          height: 'calc(100% - 34px)',
          overflowX: 'hidden',
          overflowY: 'scroll',
          WebkitOverflowScrolling: 'touch',
        }}
      >
        <header>
          <Link className={styles.header} to={`/user/${username}`}>
            <img src={`${origin}/uploads/${thumb}`} alt="User" />
            <span>uenme.co/{username}</span>
          </Link>
        </header>
        <div className={styles.content}>
          <h4>I WANT TO CHARGE</h4>
          <div className={styles.slider}>
            <RangeSlider
              onEnd={this.onUpdateCost}
              value={cost_per_min}
              min={0}
              max={30}
            />
          </div>
          <p>uens per minute</p>
          <h3>BALANCE: {uens}</h3>
          <br />
          <br />
          <div style={{ textAlign: 'center', marginRight: '30px' }}>
            <Link to="/account/add-uens" className="btn">
              <figure
                className="fa fa-plus"
                style={{
                  backgroundColor: 'black',
                  color: 'white',
                  borderRadius: '100%',
                  display: `flex`,
                  alignItems: `center`,
                  justifyContent: `center`,
                  width: `40px`,
                  height: `40px`,
                  paddingTop: '1px',
                }}
              />
            </Link>
            <br />
            Add Uens
          </div>
          <div style={{ textAlign: 'center' }}>
            <Link to="/account/sellers-console" className="btn">
              <figure
                className="fa fa-money"
                style={{
                  backgroundColor: 'black',
                  color: 'white',
                  borderRadius: '100%',
                  display: `flex`,
                  alignItems: `center`,
                  justifyContent: `center`,
                  width: `40px`,
                  height: `40px`,
                }}
              />
            </Link>
            <br />
            Cash Out
          </div>
        </div>
        <div className={styles.content}>
          <button
            onClick={this.onAvailable.bind(this, isAvailable)}
            className={cx('btn btn-lg', {
              ' btn-success': isAvailable,
              ' btn-danger': !isAvailable,
            })}
            type="button"
            style={{ borderRadius: 0 }}
          >
            Your Status: {isAvailable ? 'AVAILABLE' : 'UNAVAILABLE'}
          </button>
        </div>
        <ul className={styles.nav}>
          <li>
            <Link to={`/user/${username}`}>
              View Profile
              <i className="fa fa-chevron-right"></i>
            </Link>
          </li>
          <li>
            <Link to="/account/edit-profile">
              Edit Profile
              <i className="fa fa-chevron-right"></i>
            </Link>
          </li>
          <li>
            <Link to="/account/settings">
              Settings
              <i className="fa fa-chevron-right"></i>
            </Link>
          </li>
          <li>
            <Link to="/account/privacy">
              Privacy
              <i className="fa fa-chevron-right"></i>
            </Link>
          </li>
          <li>
            <Link to="/account/help">
              Help
              <i className="fa fa-chevron-right"></i>
            </Link>
          </li>
        </ul>

        <Signout onLogout={this.signOut} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user ? state.user : {},
})

const mapDispatchToProps = dispatch => ({
  dispatch,
  actions: bindActionCreators(
    {
      ...userActions,
    },
    dispatch
  ),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Account))

export { default as CashOut } from './pages/CashOut'
export { default as AddUens } from './pages/AddUens'
export { default as Help } from './pages/Help'
export { default as Privacy } from './pages/Privacy'
export { default as SellersConsole } from './pages/SellersConsole'
export { default as Settings } from './pages/Settings'
export { default as ChangePassword } from './pages/Settings/ChangePassword'
export { default as ShareProfile } from './pages/ShareProfile'
export { default as EditProfile } from './pages/EditProfile'
export { default as BlockedUsers } from './pages/BlockedUsers'
export { default as EditPasswordRecovery } from './pages/EditPasswordRecovery'
