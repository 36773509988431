// @ts-nocheck
import { createAction, handleActions } from 'redux-actions'
import { addInterestResponse, removeInterestResponse } from 'reducers/user'

// Action Types
const INTEREST_FETCH_REQUEST = 'interestDetails/INTEREST_FETCH_REQUEST'
const INTEREST_FETCH_RESPONSE = 'interestDetails/INTEREST_FETCH_RESPONSE'

// Action Creators
const fetchInterestRequest = createAction(INTEREST_FETCH_REQUEST)
const fetchInterestResponse = createAction(INTEREST_FETCH_RESPONSE)

export const fetchInterest = interest => async (
  dispatch,
  getState,
  { v2: Api }
) => {
  dispatch(fetchInterestRequest())

  try {
    const { data } = await Api.axios({
      url: `/api/interest_details?interest=${encodeURIComponent(interest)}`,
      method: 'GET',
      headers: {
        ...Api.getAuthHeaders(getState().user),
      },
    })
    return dispatch(fetchInterestResponse(data))
  } catch (error) {
    return dispatch(fetchInterestResponse(error))
  }
}

// Interest Details Reducer
// -----------------------------------------------------------------------------

// Default State
const defaultState = {}

// Reducer
export default handleActions(
  {
    [fetchInterestRequest]: {
      next: state => ({
        ...state,
        isLoading: true,
      }),
    },
    [fetchInterestResponse]: {
      next: (state, { payload: { interest } }) => ({
        ...state,
        isLoading: false,
        [interest.interest]: interest,
      }),
    },
    [addInterestResponse]: {
      next: (state, { payload }) => {
        return {
          isLoading: false,
          ...state,
          [payload.interest]: payload.interestData,
        }
      },
    },
    [removeInterestResponse]: {
      next: (state, { payload }) => {
        return {
          isLoading: false,
          ...state,
          [payload.interest]: payload.interestData,
        }
      },
    },
  },

  defaultState
)
