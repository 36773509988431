import React from 'react'

const EarnedUens = ({ user }) => {
  return (
    <div
      style={{
        textAlign: 'center',
        backgroundColor: '#01a3d0',
        color: 'white',
        padding: '20px 0',
        marginTop: '20px',
      }}
    >
      <h4 style={{}}>{user.earned_uens} EARNED UENS x .25 =</h4>
      <h1 style={{ fontSize: '70px', fontWeight: 'bold' }}>
        ${(user.earned_uens * 0.25).toFixed(2)}
      </h1>
    </div>
  )
}

export default EarnedUens
