import React, { Component } from 'react'

import styles from './BlockedUserCell.module.scss'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as usersActions from 'reducers/users'
import * as userActions from 'reducers/user'

class BlockedUserCell extends Component {
  onClick = e => {
    e.preventDefault()
    e.stopPropagation()
    const {
      user: { username },
      unblockUser,
    } = this.props
    return unblockUser(username)
  }

  render() {
    const user = this.props.user
    const origin = process.env.REACT_APP_ORIGIN
    return (
      <tr
        onClick={this.onClick}
        style={{
          width: '100%',
          fontSize: 'smaller',
          borderLeft: '0',
          borderRight: '0',
          borderBottom: '1px solid black',
        }}
      >
        <td style={{ width: '40%', padding: '0', border: '0' }}>
          <div className="user-img-wrapper">
            <img src={`${origin}/uploads/${user.thumb}`} alt="User" />
          </div>
        </td>
        <td
          style={{
            position: 'relative',
            verticalAlign: 'baseline',
            padding: '2%',
          }}
        >
          <div className={styles.userName}>
            <b>@{user.username}</b>
          </div>
        </td>
      </tr>
    )
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    user: { username, is_favorite },
  } = ownProps

  const {
    favoriteUserByUsername,
    unfavoriteUserByUsername,
  } = bindActionCreators(
    {
      ...usersActions,
      ...userActions,
    },
    dispatch
  )

  return {
    goToUser: () => dispatch(push(`/user/${username}`)),
    goToChat: () => dispatch(push(`/chat/${username}`)),
    goToNegotiation: () => dispatch(push(`/negotiation/${username}`)),
    onFavoritesClick: () => {
      const action = !is_favorite
        ? favoriteUserByUsername
        : unfavoriteUserByUsername

      action(username)
    },
  }
}

export default connect(
  null,
  mapDispatchToProps
)(BlockedUserCell)
