import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import UserCell from 'components/UserCell'
import InterestedUsersNavBar from 'components/NavBar/InterestedUsersNavBar'
import * as userActions from 'reducers/user'
import * as usersActions from 'reducers/users'
import * as interestsActions from 'reducers/interests'
import * as interestDetailsActions from 'reducers/interestDetails'
import { getUsers, getInterest } from 'store/selectors'
import { withLastLocation } from 'react-router-last-location'
import { goBack } from 'connected-react-router'
import { withRouter } from 'react-router-dom'

class InterestedUsers extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      users: [],
    }
  }

  componentDidMount() {
    this.fetch()
  }

  componentDidUpdate(prevProps) {
    if (
      decodeURIComponent(this.props.match.params.interest) !==
      decodeURIComponent(prevProps.match.params.interest)
    ) {
      this.fetch()
    }
  }

  fetch = () => {
    const {
      actions: { fetchInterestedUsers, fetchUser, fetchInterest },
      match: {
        params: { interest },
      },
    } = this.props

    fetchInterestedUsers(interest)
    fetchUser()

    fetchInterest(interest)
  }

  onBackClick = () => {
    if (!this.props.lastLocation) {
      return this.props.history.push('/search/users')
    } else {
      const { dispatch } = this.props
      dispatch(goBack())
    }
  }

  updateGenderFilterToggle = async prefers_gender => {
    const {
      actions: { updateUser, fetchInterestedUsers },
      match: {
        params: { interest },
      },
    } = this.props

    await updateUser({ prefers_gender })
    fetchInterestedUsers(interest)
  }

  onToggleInterest = async (interest, isMutual) => {
    const {
      actions: { addInterest, removeInterest },
    } = this.props

    if (isMutual) {
      removeInterest(interest)
    } else {
      addInterest(interest)
    }
  }

  render() {
    const { users, user, interest, isLoadingInterests, isLoading } = this.props

    const rows = users.map(user => (
      <UserCell key={user.id} user={user} is_favorite={user.is_favorite} />
    ))
    return (
      <div>
        <div
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            right: '0',
            height: 'calc(1.5em + 20px)',
          }}
        >
          <InterestedUsersNavBar
            title={`#${interest.interest}`}
            onBackClick={this.onBackClick}
            onGenderFilterToggle={this.updateGenderFilterToggle}
            user={user}
            interest={interest}
            onToggleInterest={this.onToggleInterest}
            isLoadingInterests={isLoadingInterests}
          />
        </div>
        <div
          style={{
            position: 'fixed',
            top: 'calc(1.5em + 20px)',
            left: '0',
            right: '0',
            height: 'calc(100% - 1.5em - 20px - 34px)',
            overflowX: 'hidden',
            overflowY: 'scroll',
            WebkitOverflowScrolling: 'touch',
            backgroundColor: 'white',
          }}
        >
          <table style={{ width: '100%' }}>
            <tbody>
              {rows.length ? (
                rows
              ) : isLoading ? (
                <tr>
                  <td>Loading...</td>
                </tr>
              ) : (
                <tr>
                  <td>The system has no interested users.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { interest },
    },
  } = ownProps
  return {
    users: getUsers(state, `/interested-users/${interest}`),
    interest: getInterest(state, interest),
    isLoadingInterests: state.interests.isLoading, // forces a re-render
    isLoading: state.users.isLoading, // forces a re-render
    user: state.user ? state.user : null,
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch,
  actions: bindActionCreators(
    {
      ...usersActions,
      ...userActions,
      ...interestsActions,
      ...interestDetailsActions,
    },
    dispatch
  ),
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withLastLocation(InterestedUsers))
)
