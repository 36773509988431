import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'

import { Route, Redirect, Switch, withRouter } from 'react-router-dom'

import SignIn from './SignIn'
import FirstPage from './FirstPage'
import User from './User'
import Users from './Users'
import Followers from './Followers'
import Favorites from './Favorites'
import Interests from './Interests'
import InterestedUsers from './InterestedUsers'
import Chat from './Chat'
import Call from './Call'
import Negotiation from './Negotiation'
import Messages from './Messages'
import Account, {
  CashOut,
  AddUens,
  EditProfile,
  ShareProfile,
  SellersConsole,
  Settings,
  Privacy,
  Help,
  ChangePassword,
  BlockedUsers,
  EditPasswordRecovery,
} from './Account'

import TabBar from 'components/TabBar'
import NotificationDrawer from 'components/NotificationDrawer'
import SignUp from './SignUp'
import ForgotPassword from './ForgotPassword'
import ResetPassword from './ResetPassword'
import PasswordRecoveryEmail from './ForgotPassword/pages/PasswordRecoveryEmail'
import EmailNotFound from './ForgotPassword/pages/PasswordRecoveryEmail/EmailNotFound'
import EmailLinkSent from './ForgotPassword/pages/PasswordRecoveryEmail/EmailLinkSent'
import PasswordRecoverySms from './ForgotPassword/pages/PasswordRecoverySms'
import SmsNotFound from './ForgotPassword/pages/PasswordRecoverySms/SmsNotFound'
import SmsLinkSent from './ForgotPassword/pages/PasswordRecoverySms/SmsLinkSent'
import BuyerList from './CrownClub/BuyerList'
import SellerList from './CrownClub/SellerList'
import BrowserIncompatible from '../components/BrowserIncompatible'
import { bindActionCreators } from 'redux'
import * as userActions from '../reducers/user'
import { LastLocationProvider } from 'react-router-last-location'

class Routes extends Component {
  componentDidMount() {
    this.refreshToken()
  }

  isLoggedIn = () => {
    if (this.props.user && this.props.user.token && this.props.user.id) {
      return this.tokenExpiry()
    } else {
      return false
    }
  }

  tokenExpiry = () => {
    let token = this.props.user.token
    let payload = token.split('.')[1]
    payload = window.atob(payload)
    payload = JSON.parse(payload)
    if (payload.exp < Date.now() / 1000) {
      this.signOut()
      return false
    } else {
      return true
    }
  }

  refreshToken = () => {
    if (this.props.user && this.props.user.token && this.tokenExpiry()) {
      const {
        actions: { refreshToken },
      } = this.props
      refreshToken()
    }
  }

  signOut = () => {
    const {
      actions: { signOutUser, updateUser },
    } = this.props
    // Set Available
    updateUser({ available: 'NO' })
    signOutUser()
    this.props.history.push('/')
  }

  detectBrowserAndMedia = () => {
    var ua = navigator.userAgent,
      tem,
      M =
        ua.match(
          /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
        ) || []
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || []
      return 'IE ' + (tem[1] || '')
    }
    console.log(`browser: `, M)
    if (M[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edge?)\/(\d+)/)
      if (tem != null)
        return tem
          .slice(1)
          .join(' ')
          .replace('OPR', 'Opera')
          .replace('Edg ', 'Edge ')
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?']
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1])
    let browser = M.join(' ')
    return (
      (browser.includes('Chrome') ||
        browser.includes('Firefox') ||
        browser.includes('Safari')) &&
      Boolean(navigator.mediaDevices)
    )
  }

  render() {
    if (!this.detectBrowserAndMedia()) {
      return (
        <Fragment>
          <Route path="/browser-incompatible" component={BrowserIncompatible} />
          <Redirect to="/browser-incompatible" />
        </Fragment>
      )
    }
    if (this.isLoggedIn() && this.detectBrowserAndMedia()) {
      return (
        <>
          <LastLocationProvider>
            <Switch>
              <Route
                path="/search/crown_club_buyers_list/:searchTerm"
                component={BuyerList}
              />
              <Route
                path="/search/crown_club_buyers_list"
                component={BuyerList}
              />
              <Route
                path="/search/crown_club_sellers_list/:searchTerm"
                component={SellerList}
              />
              <Route
                path="/search/crown_club_sellers_list"
                component={SellerList}
              />
              <Route path="/search/users/:searchTerm" component={Users} />
              <Route path="/search/users" component={Users} />
              <Route
                path="/search/followers/:searchTerm"
                component={Followers}
              />
              <Route path="/search/followers" component={Followers} />
              <Route
                path="/search/interests/:searchTerm"
                component={Interests}
              />
              <Route path="/search/interests" component={Interests} />
              <Route path="/favorites" component={Favorites} />
              <Route path="/messages" component={Messages} />
              <Route
                exact
                path="/account/edit-profile"
                component={EditProfile}
              />
              <Route
                exact
                path="/account/share-profile"
                component={ShareProfile}
              />
              <Route
                exact
                path="/account/settings/change-password"
                component={ChangePassword}
              />
              <Route
                exact
                path="/account/settings/password-recovery-options"
                component={EditPasswordRecovery}
              />
              <Route
                exact
                path="/account/settings/blocked-users"
                component={BlockedUsers}
              />
              <Route
                exact
                path="/account/sellers-console/cash-out"
                component={CashOut}
              />
              <Route
                exact
                path="/account/sellers-console"
                component={SellersConsole}
              />
              <Route exact path="/account/settings" component={Settings} />
              <Route exact path="/account/privacy" component={Privacy} />
              <Route exact path="/account/help" component={Help} />
              <Route exact path="/account/cash-out" component={CashOut} />
              <Route exact path="/account/add-uens" component={AddUens} />
              <Route exact path="/account" component={Account} />
              <Route
                path="/interested-users/:interest"
                component={InterestedUsers}
              />
              <Route path="/user/:username" component={User} />
              <Route path="/chat/:username" component={Chat} />
              <Route path="/negotiation/:username" component={Negotiation} />
              <Route path="/call/:peer/:cost/:incoming" component={Call} />
              <Route path="/call/:peer/:cost" component={Call} />
              <Route render={() => <Redirect to="/search/users" />} />
            </Switch>
            <Route
              path="(/search|/favorites|/messages|/account|/user|/interested-users)"
              component={TabBar}
            />
            <Route
              path="(/search|/favorites|/messages|/account|/user|/interested-users|/chat)"
              component={NotificationDrawer}
            />
          </LastLocationProvider>
        </>
      )
    } else {
      return (
        <>
          <Switch>
            <Route
              exact
              path="/forgot-password/email/not-found"
              component={EmailNotFound}
            />
            <Route
              exact
              path="/forgot-password/email/link-sent"
              component={EmailLinkSent}
            />
            <Route
              exact
              path="/forgot-password/sms/not-found"
              component={SmsNotFound}
            />
            <Route
              exact
              path="/forgot-password/sms/link-sent"
              component={SmsLinkSent}
            />
            <Route
              exact
              path="/forgot-password/email"
              component={PasswordRecoveryEmail}
            />
            <Route
              exact
              path="/forgot-password/sms"
              component={PasswordRecoverySms}
            />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route
              exact
              path="/reset-password/:username/:key"
              component={ResetPassword}
            />
            <Route path="/login" render={() => <SignIn />} />
            <Route path="/signup" render={() => <SignUp />} />

            {/* TODO: Probably want to set up a proper redirection pattern here */}
            <Route path="/" component={FirstPage} />
          </Switch>
        </>
      )
    }
  }
}

const mapStateToProps = state => ({
  user: state.user ? state.user : {},
})

const mapDispatchToProps = dispatch => ({
  dispatch,
  actions: bindActionCreators(
    {
      ...userActions,
    },
    dispatch
  ),
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Routes)
)
