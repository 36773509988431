function dateTimeToJSDate(datetime) {
  // Split timestamp into [ Y, M, D, h, m, s ]
  const t = datetime.split(/[- :]/)
  // Apply each element to the Date function
  const d = new Date(Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4], t[5]))
  return d
}

function prettyPrintDateTime(datetime) {
  return dateTimeToJSDate(datetime).toLocaleString()
}

function dateToString(datetime) {
  return dateTimeToJSDate(datetime).toDateString()
}

function dateTimeNow() {
  return new Date()
    .toISOString()
    .slice(0, 19)
    .replace('T', ' ')
}

function timeSince(datetime) {
  const date = dateTimeToJSDate(datetime).getTime()
  const seconds = Math.floor((new Date().getTime() - date) / 1000)
  if (seconds < 60) {
    return `less than a minute ago`
  } else if (seconds < 3600) {
    let diff = Math.round(seconds / 60)
    return `${diff} minute${diff !== 1 ? `s` : ``} ago`
  } else if (seconds < 86400) {
    let diff = Math.round(seconds / 60 / 60)
    return `${diff} hour${diff !== 1 ? `s` : ``} ago`
  } else {
    let diff = Math.round(seconds / 60 / 60 / 24)
    return `${diff} day${diff !== 1 ? `s` : ``} ago`
  }
}

function secondsToTime(time) {
  const hours = Math.floor(time / 3600)
  time -= hours * 3600

  const minutes = Math.floor(time / 60)
  time -= minutes * 60

  const seconds = time % 60
  if (hours > 0) {
    return (
      hours +
      ':' +
      (minutes < 10 ? '0' + minutes : minutes) +
      ':' +
      (seconds < 10 ? '0' + seconds : seconds)
    )
  }
  return minutes + ':' + (seconds < 10 ? '0' + seconds : seconds)
}
export {
  dateTimeToJSDate,
  prettyPrintDateTime,
  timeSince,
  secondsToTime,
  dateTimeNow,
  dateToString,
}
