import React, { Component } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import cx from 'classnames'

import styles from './Forms.module.scss'

const VALIDATION = {
  fullname: Yup.string(),
  location: Yup.string(),
  about: Yup.string(),
  gender: Yup.string(),
}

class AboutYouForm extends Component {
  toggleGender = (value, _values, setFieldValue) => {
    return setFieldValue('gender', value)
  }

  render() {
    const { onSubmit, user } = this.props

    return (
      <div className="signup">
        <Formik
          enableReinitialize
          initialValues={{
            fullname: user.fullname || '',
            location: user.location || '',
            about: user.about || '',
            gender: user.gender ? [user.gender] : [],
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false)
            onSubmit(values)
          }}
          validationSchema={Yup.object().shape(VALIDATION)}
        >
          {props => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            } = props

            return (
              <form
                className="form"
                onSubmit={handleSubmit}
                style={{
                  marginTop: '35px',
                  //backgroundColor: '#2c3031'
                }}
              >
                <div className="form-group" style={{ marginBottom: '0' }}>
                  <input
                    id="fullname"
                    placeholder="Name"
                    type="text"
                    autoComplete="off"
                    value={values.fullname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    style={{
                      border: 'none',
                      borderRadius: '0',
                      borderTop: '1px solid #686868',
                      borderBottom: '1px solid #686868',
                      boxShadow: 'none',
                      backgroundColor: '#2c3031',
                      color: 'white',
                      fontSize: '16px',
                    }}
                    className={cx('form-control input-lg', {
                      'has-error': errors.fullname && touched.fullname,
                    })}
                  />
                  {errors.fullname && touched.fullname && (
                    <div className="invalid-feedback">{errors.fullname}</div>
                  )}
                </div>
                <div className="form-group" style={{ marginBottom: '0' }}>
                  <input
                    id="location"
                    placeholder="Location"
                    type="text"
                    autoComplete="off"
                    value={values.location}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    style={{
                      border: 'none',
                      borderRadius: '0',
                      borderBottom: '1px solid #686868',
                      boxShadow: 'none',
                      backgroundColor: '#2c3031',
                      color: 'white',
                      fontSize: '16px',
                    }}
                    className={cx('form-control input-lg', {
                      'has-error': errors.location && touched.location,
                    })}
                  />
                  {errors.location && touched.location && (
                    <div className="invalid-feedback">{errors.location}</div>
                  )}
                </div>
                <div className="form-group" style={{ marginBottom: '0' }}>
                  <textarea
                    id="about"
                    placeholder="About"
                    type="text"
                    autoComplete="off"
                    value={values.about}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    style={{
                      border: 'none',
                      borderRadius: '0',
                      borderBottom: '1px solid #686868',
                      boxShadow: 'none',
                      backgroundColor: '#2c3031',
                      color: 'white',
                      fontSize: '16px',
                      height: '110px',
                    }}
                    className={cx('form-control input-lg', {
                      'has-error': errors.about && touched.about,
                    })}
                  />
                  {errors.about && touched.about && (
                    <div className="invalid-feedback">{errors.about}</div>
                  )}
                </div>
                <div
                  className="form-group"
                  style={{
                    backgroundColor: '#2c3031',
                    border: 'none',
                    borderRadius: '0',
                    borderBottom: '1px solid #686868',
                    height: '46px',
                    paddingTop: '12px',
                    paddingBottom: '45px',
                  }}
                >
                  <label
                    htmlFor="gender"
                    style={{
                      margin: '8px 0 0 13px',
                      fontWeight: 'normal',
                      color: '#959595',
                    }}
                  >
                    Gender
                  </label>
                  <div
                    className="d-block"
                    style={{
                      marginLeft: 'auto',
                      marginRight: '25px',
                      float: 'right',
                    }}
                  >
                    <div
                      onClick={() =>
                        this.toggleGender('Male', values.gender, setFieldValue)
                      }
                      className={cx('btn', styles.genderButton, {
                        'btn-link': !values.gender.includes('Male'),
                        //'btn-primary': values.gender.includes('Male'),
                      })}
                      style={{
                        borderRadius: '50%',
                        marginRight: '10px',
                        backgroundColor: values.gender.includes('Male')
                          ? '#41acdd'
                          : '',
                        border: !values.gender.includes('Male')
                          ? '1px solid white'
                          : '',
                      }}
                    >
                      <figure
                        className="fa fa-male"
                        style={{ color: 'white' }}
                      />
                    </div>
                    <div
                      onClick={() =>
                        this.toggleGender(
                          'Female',
                          values.gender,
                          setFieldValue
                        )
                      }
                      className={cx('btn', styles.genderButton, {
                        'btn-link': !values.gender.includes('Female'),
                        //'btn-primary': values.gender.includes('Female'),
                      })}
                      style={{
                        borderRadius: '50%',
                        backgroundColor: values.gender.includes('Female')
                          ? '#41acdd'
                          : '',
                        border: !values.gender.includes('Female')
                          ? '1px solid white'
                          : '',
                      }}
                    >
                      <figure
                        className="fa fa-female"
                        style={{ color: 'white' }}
                      />
                    </div>
                  </div>
                  {errors.gender && touched.gender && (
                    <div className="invalid-feedback">{errors.gender}</div>
                  )}
                </div>

                {user.error_message && (
                  <div className="invalid-feedback">{user.error_message}</div>
                )}
                <div style={{ marginTop: '15px', backgroundColor: '#191919' }}>
                  <button
                    className="btn btn-primary btn-block btn-lg"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Next
                  </button>
                </div>
              </form>
            )
          }}
        </Formik>
      </div>
    )
  }
}

export default AboutYouForm
