import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import UserCell from 'components/UserCell'

import * as usersActions from 'reducers/users'
import { getUsers } from 'store/selectors'

class Favorites extends Component {
  componentDidMount() {
    this.fetch()
  }

  fetch = () => {
    const {
      actions: { fetchFavorites },
    } = this.props
    fetchFavorites()
  }

  onFavoritesClick = (username, is_favorite) => {
    const {
      favoriteUserByUsername,
      unfavoriteUserByUsername,
      removeUser,
    } = this.props.actions

    const action = !is_favorite
      ? favoriteUserByUsername
      : unfavoriteUserByUsername

    action(username)

    if (is_favorite) {
      removeUser(username)
    }
  }

  render() {
    const { users, isLoading } = this.props

    const rows = users.map(user => (
      <UserCell key={user.id} user={user} isLoading={isLoading} />
    ))

    return (
      <div
        style={{
          position: 'fixed',
          top: '0',
          left: '0',
          right: '0',
          height: 'calc(100% - 34px)',
          overflowX: 'hidden',
          overflowY: 'scroll',
          WebkitOverflowScrolling: 'touch',
          backgroundColor: 'white',
        }}
      >
        <table style={{ width: '100%' }}>
          <tbody>
            {rows.length ? (
              rows
            ) : isLoading ? (
              <tr>
                <td>Loading...</td>
              </tr>
            ) : (
              <tr>
                <td>You have no favorites.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    isLoading: state.users.isLoading,
    users: getUsers(state, `/favorites`),
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch,
  actions: bindActionCreators(
    {
      ...usersActions,
    },
    dispatch
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Favorites)
