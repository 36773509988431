import React, { Component } from 'react'
import NavBar from 'components/NavBar'
import { goBack } from 'connected-react-router'
import { connect } from 'react-redux'

class Help extends Component {
  onBackClick = () => {
    const { dispatch } = this.props
    dispatch(goBack())
  }

  render() {
    return (
      <div>
        <div
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            right: '0',
            height: 'calc(1.5em + 20px)',
          }}
        >
          <NavBar title={`Help`} onBackClick={this.onBackClick} />
        </div>

        <div
          style={{
            backgroundColor: '#2c2d2f',
            color: 'white',
            position: 'fixed',
            height: 'calc(100% - 1.5em - 20px - 34px)',
            top: 'calc(1.5em + 20px)',
            left: '0',
            right: '0',
            overflowX: 'hidden',
            overflowY: 'scroll',
            WebkitOverflowScrolling: 'touch',
          }}
        >
          <ul
            style={{
              listStyleType: 'none',
              color: 'white',
              fontSize: '19px',
            }}
          >
            <li style={{ paddingTop: '60px' }}>
              <a
                href="https://www.uenme.co/faq"
                style={{ textDecoration: 'none', color: 'white' }}
              >
                FAQ
              </a>
            </li>

            <hr style={{ marginRight: '40px' }}></hr>

            <h4
              style={{
                paddingTop: '20px',
                fontFamily: 'League Gothic',
                fontSize: '1.3em',
              }}
            >
              MORE HELP
            </h4>

            <li style={{ paddingTop: '25px' }}>
              <a
                href="https://www.uenme.co/blog/whatareuens"
                style={{ textDecoration: 'none', color: 'white' }}
                target="_blank"
                rel="noopener noreferrer"
              >
                What are Uens?
              </a>
            </li>

            <li style={{ paddingTop: '30px' }}>
              <a
                href="https://www.uenme.co/privacypolicy"
                style={{ textDecoration: 'none', color: 'white' }}
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </li>

            <li style={{ paddingTop: '30px' }}>
              <a
                href="https://www.uenme.co/contactus"
                style={{ textDecoration: 'none', color: 'white' }}
                target="_blank"
                rel="noopener noreferrer"
              >
                Contact Us
              </a>
            </li>

            <li style={{ paddingTop: '30px' }}>
              <a href="/" style={{ textDecoration: 'none', color: 'white' }}>
                Show Me Tips
              </a>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch,
})

export default connect(mapDispatchToProps)(Help)
