// @ts-nocheck
import React, { Component } from 'react'
import InterestCell from 'components/InterestCell'

class InterestsForm extends Component {
  state = {
    newInterest: '',
  }

  componentDidMount() {
    this.fetch()
  }

  fetch = () => {
    const {
      actions: { fetchUserInterests },
    } = this.props
    fetchUserInterests(this.props.user.username)
  }

  isMutualInterest = () => {
    if (!this.props.interest) return false
    const {
      interest: { mutual_interest },
    } = this.props

    return mutual_interest === '1'
  }

  onToggleInterest = (interest, isMutual) => {
    const {
      actions: { addInterest, removeInterest },
    } = this.props

    if (isMutual) {
      removeInterest(interest)
    } else {
      addInterest(interest)
    }
  }

  onSetInterest = async e => {
    const {
      target: { value },
    } = e

    if (value) {
      this.setState({ newInterest: value })
    } else {
      this.setState({ newInterest: '' })
    }
  }

  onAddInterest = async e => {
    e.preventDefault()
    const { newInterest } = this.state
    const {
      actions: { addInterest },
    } = this.props

    try {
      addInterest(newInterest)

      this.setState({
        newInterest: '',
      })
    } catch (error) {
      console.log('error fetching interest.', error)
    }
  }

  render() {
    const { interests, isLoadingInterest } = this.props
    const { newInterest } = this.state

    return (
      <div
        style={{
          backgroundColor: '#2c2d2f',
          color: 'white',
        }}
      >
        <form className="form" action="#" onSubmit={this.onAddInterest}>
          <div
            className="form-group"
            style={{ padding: '15px 10px', marginBottom: '0' }}
          >
            <input
              placeholder="What are you interested in?"
              type="text"
              autoComplete="off"
              className="form-control"
              value={newInterest}
              onChange={this.onSetInterest}
              style={{ fontSize: '16px' }}
            />
            {/* <button className="btn btn-sm btn-info">Submit</button> */}
          </div>
        </form>
        <table style={{ width: '100%' }}>
          <tbody>
            {interests &&
              interests.map(interest => (
                <InterestCell
                  key={interest.id}
                  interest={interest}
                  onClick={this.onToggleInterest}
                  onToggleInterest={this.onToggleInterest}
                  isLoadingInterest={isLoadingInterest}
                />
              ))}
          </tbody>
        </table>
      </div>
    )
  }
}

export default InterestsForm
