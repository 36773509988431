import React from 'react'
import { dateToString } from '../../routes/Chat/Date'

const PaymentHistory = props => {
  const { paymentHistory } = props

  return (
    <div
      style={{
        textAlign: 'center',
        borderRadius: '5px',
        color: 'white',
        backgroundColor: 'white',
      }}
    >
      <h3
        style={{
          padding: '10px 0',
          marginBottom: '0',
          backgroundColor: '#01a3d0',
          borderTopLeftRadius: '5px',
          borderTopRightRadius: '5px',
        }}
      >
        Payment History
      </h3>

      {paymentHistory.map((payment, index) => {
        return (
          <div key={index}>
            <div
              style={{
                display: 'flex',
                backgroundColor: 'white',
                borderBottomLeftRadius: '7px',
                borderBottomRightRadius: '7px',
                padding: '10px 0',
              }}
            >
              <div
                className="container-fluid"
                style={{ textAlign: 'left', width: '70%' }}
              >
                <h4 style={{ color: 'black', textTransform: 'capitalize' }}>
                  <strong>{payment.status}</strong>
                </h4>
                <p style={{ color: '#b8b8b8' }}>
                  Processed on {dateToString(payment.processed_date_time)}
                </p>
              </div>
              <div
                style={{
                  width: '30%',
                  textAlign: 'right',
                  paddingRight: '15px',
                  display: 'inline-table',
                }}
              >
                <h2 style={{ color: '#01a3d0' }}>
                  ${(payment.uens * 0.25).toFixed(2)}
                </h2>
              </div>
            </div>

            {paymentHistory.length !== index + 1 ? (
              <hr style={{ margin: '0' }} />
            ) : null}
          </div>
        )
      })}

      {paymentHistory.length === 0 ? (
        <h4 style={{ color: 'black', margin: 0, padding: '10px 0' }}>
          No Data
        </h4>
      ) : null}
    </div>
  )
}

export default PaymentHistory
