import React, { Component } from 'react'
import { dateTimeNow, timeSince } from '../../routes/Chat/Date'
const origin = process.env.REACT_APP_ORIGIN

class SentMessage extends Component {
  render() {
    const self = this.props.self
    const messages = this.props.message
    const message = !this.props.message.msg
      ? this.props.message
      : this.props.message.msg

    return (
      <div
        style={{
          textAlign: 'right',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row-reverse',
        }}
      >
        <img
          style={{
            verticalAlign: 'middle',
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            marginLeft: '10px',
            marginRight: '10px',
          }}
          src={`${origin}/uploads/${self.thumb}`}
          alt="user"
        />
        <div
          style={{
            border: '2px 2px 2px 0 solid #dedede',
            backgroundColor: '#01a9ed',
            borderRadius: '20px 20px 0 20px',
            padding: '10px',
            margin: '10px 0',
            width: '75%',
          }}
        >
          <p
            style={{
              color: 'white',
              marginRight: '10px',
            }}
            dangerouslySetInnerHTML={{
              __html: message,
            }}
          ></p>
          <span
            style={{
              float: 'right',
              color: 'white',
              marginRight: '10px',
              fontSize: '10px',
            }}
          >
            {timeSince(messages.date || dateTimeNow())}
          </span>
        </div>
        <hr />
      </div>
    )
  }
}

export default SentMessage
