import React from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import styles from './FirstPage.module.scss'

const VIDEO_URL =
  'https://jwt-api-dev.uenme.co/uploads/UenMe-Intro-Video-Web-Quality.mp4'

export default () => (
  <div className={styles.container}>
    <video
      className={styles.video}
      playsInline
      autoPlay
      muted
      loop
      src={VIDEO_URL}
    />
    <footer className={styles.actions}>
      <Link className={styles.link} to="/login">
        Log In
      </Link>
      <Link className={cx(styles.link, styles.linkDark)} to="/signup">
        Sign Up
      </Link>
    </footer>
  </div>
)
