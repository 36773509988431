import React, { Component } from 'react'
import { FaCamera } from 'react-icons/fa'
import swal from 'sweetalert'

class AboutYouPhotoForm extends Component {
  onChange = e => {
    const {
      user: { username },
    } = this.props

    const { files } = e.currentTarget
    if (files) {
      const fileExt = files[0].name.split('.').pop()
      if (fileExt === 'jpg' || fileExt === 'png') {
        const data = new FormData()
        const blob = new Blob([files[0]], { type: 'application/octet-stream' })

        data.append('photo', blob, `${username}-photo.jpg`)

        const { onSubmit } = this.props

        onSubmit(data)
      } else {
        swal({ title: 'Incorrect Format', text: 'Please select .jpg format.' })
      }
    }
  }

  changeImage = () => {
    let input = this.refs.photo
    // @ts-ignore
    input.click()
  }

  render() {
    const { onSkip, onBack, user } = this.props
    const origin = process.env.REACT_APP_ORIGIN

    return (
      <div className="signup" style={{ paddingTop: '30px' }}>
        <button
          style={{
            background: 'rgba(0,0,0,0)',
            position: 'fixed',
            top: '10px',
            left: '10px',
            color: 'white',
          }}
          className="btn btn-sm"
          onClick={onBack}
        >
          <span className="fa fa-chevron-left mr-3 fa-lg" />
        </button>
        <button
          style={{
            background: 'rgba(0,0,0,0)',
            position: 'fixed',
            top: '10px',
            right: '10px',
            color: 'white',
            fontSize: '14px',
          }}
          className="btn btn-sm"
          onClick={onSkip}
        >
          Skip
        </button>

        <div
          className="row"
          style={{
            marginTop: '25px',
            marginLeft: '10px',
          }}
        >
          <div className="col-xs-3">
            {user &&
            user.thumb !== 'default_thumb.jpg' &&
            user.thumb !== undefined ? (
              <img
                onClick={this.changeImage}
                style={{ width: '100%', borderRadius: '100%' }}
                src={`${origin}/uploads/${user.thumb}`}
                alt="User"
              />
            ) : (
              ''
            )}

            <div
              className="button"
              style={{
                display:
                  user &&
                  user.thumb !== 'default_thumb.jpg' &&
                  user.thumb !== undefined
                    ? 'none'
                    : '',
              }}
            >
              <div>
                <div
                  style={{
                    position: 'absolute',
                    background: '#41acdd',
                    width: '63.8px',
                    height: '63.8px',
                    borderRadius: '50%',
                    textAlign: 'center',
                    lineHeight: '72px',
                  }}
                >
                  <label htmlFor="photo">
                    <FaCamera
                      style={{
                        color: 'white',
                        height: '21px',
                        width: '21px',
                      }}
                    />
                  </label>
                </div>
              </div>
              <input
                type="file"
                accept=".jpg, .png, image/jpeg, image/png"
                id="photo"
                ref="photo"
                placeholder="photo (optional)"
                style={{ display: 'none' }}
                onChange={this.onChange}
              />
            </div>

            {/* <input
              id="photo"
              placeholder="photo (optional)"
              type="file"
              onChange={this.onChange}
            /> */}
          </div>
          <div className="col-xs-9 mt-5">
            <h5>Your Profile</h5>
            <h6 style={{ color: '#41acdd' }}>All fields are optional</h6>
          </div>
        </div>
      </div>
    )
  }
}

export default AboutYouPhotoForm
