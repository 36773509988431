import { createTransform } from 'redux-persist'

const UserTransform = createTransform(
  (inboundState, _key) => {
    // What the heck am I doing here? I'm glad you asked.
    // I'm persisting a small amount of data,
    // such that the app functions offline,
    // but not storing the entireity of UenMe
    // in a 5-6 MB limited localstorage.
    if (_key === 'users' || _key === 'interests') {
      Object.keys(inboundState).forEach(key => {
        if (Array.isArray(inboundState[key])) {
          inboundState[key].splice(50)
        }
      })
    }
    return { ...inboundState }
  },
  (outboundState, _key) => {
    return { ...outboundState, error_message: null }
  },
  {
    whitelist: ['user', 'users', 'interests', 'userDetails', 'interestDetails'],
  }
)

export default UserTransform
