import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import InterestCell from 'components/InterestCell'
import SearchBar from 'components/SearchBar'
import * as userActions from 'reducers/user'
import * as userDetailActions from 'reducers/userDetails'
import * as interestsActions from 'reducers/interests'
import { getInterests } from 'store/selectors'
import { push } from 'connected-react-router'

class Interests extends Component {
  componentDidMount() {
    this.fetch()
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.searchTerm !== '' &&
      prevProps.searchTerm !== this.props.searchTerm
    ) {
      this.fetch()
    }
  }

  fetch = () => {
    const {
      searchTerm,
      actions: { searchInterests, fetchUser, fetchInterests },
    } = this.props

    fetchUser()

    if (searchTerm) {
      return searchInterests(searchTerm)
    }
    fetchInterests()
  }

  onSearch = str => {
    const { dispatch } = this.props

    if (str && str !== '') {
      dispatch(push(`/search/interests/${str}`))
    } else {
      dispatch(push(`/search/interests`))
    }
  }

  onToggleInterest = (interest, isMutual) => {
    const {
      actions: { addInterest, removeInterest },
    } = this.props

    if (isMutual) {
      removeInterest(interest)
    } else {
      addInterest(interest)
    }
  }

  render() {
    const { user, interests, isLoadingInterest, searchTerm } = this.props

    const rows = interests.map(interest => (
      <InterestCell
        key={interest.id}
        interest={interest}
        interested={interest.mutual_interest}
        onToggleInterest={this.onToggleInterest}
      />
    ))

    return (
      <div>
        <div
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            right: '0',
          }}
        >
          <SearchBar
            user={user}
            selected="interests"
            onSearch={this.onSearch}
            value={searchTerm}
          />
        </div>
        <div
          style={{
            position: 'fixed',
            top: 'calc(107px)',
            left: '0',
            right: '0',
            height: 'calc(100% - 107px - 34px)',
            overflowX: 'hidden',
            overflowY: 'scroll',
            WebkitOverflowScrolling: 'touch',
            backgroundColor: 'white',
          }}
        >
          <table style={{ width: '100%' }}>
            <tbody>
              {rows.length ? (
                rows
              ) : isLoadingInterest ? (
                <tr>
                  <td>Loading...</td>
                </tr>
              ) : (
                <tr>
                  <td>No Interests Found.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { searchTerm },
    },
  } = ownProps

  return {
    searchTerm,
    user: state.user ? state.user : {},
    interests: getInterests(state, searchTerm ? searchTerm : 'top500'),
    isLoadingInterest: state.interests.isLoading,
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch,
  actions: bindActionCreators(
    {
      ...userActions,
      ...interestsActions,
      ...userDetailActions,
    },
    dispatch
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Interests)
