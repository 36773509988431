import React, { Component } from 'react'
import styles from './InterestedButton.module.css'
import cx from 'classnames'

class InterestedButton extends Component {
  render() {
    const { toggle, interested } = this.props
    const classname = interested
      ? cx(styles.activeInterest, 'fa fa-check-circle fa-lg')
      : 'fa fa-plus-circle fa-lg'
    return (
      <div onClick={toggle} className={styles.action}>
        <figure className={classname} />
      </div>
    )
  }
}

export default InterestedButton
