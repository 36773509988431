import React, { Component } from 'react'

import styles from './UserCell.module.scss'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as usersActions from 'reducers/users'
import * as userActions from 'reducers/user'

class UserCell extends Component {
  onClick = e => {
    e.preventDefault()
    e.stopPropagation()
    const {
      onClick,
      goToUser,
      user: { username },
    } = this.props

    if (onClick) {
      return onClick(username)
    }

    if (onClick === false) {
      return
    }

    return goToUser()
  }

  onChatClick = e => {
    e.preventDefault()
    e.stopPropagation()
    const { goToChat } = this.props

    goToChat()
  }

  onNegotiationClick = e => {
    e.preventDefault()
    e.stopPropagation()
    const { goToNegotiation } = this.props

    goToNegotiation()
  }

  onFavoriteClick = e => {
    e.preventDefault()
    e.stopPropagation()
    const { onFavoritesClick } = this.props

    onFavoritesClick()
  }

  render() {
    const user = this.props.user
    const interests = user.interests.map(interest => `#${interest} `)
    const cost = Math.round(parseInt(this.props.user.cost_per_min))
    const costStr =
      cost > 0 ? `${cost} uen${cost !== 1 ? 's' : ''}/min` : `FREE`
    const origin = process.env.REACT_APP_ORIGIN
    return (
      <tr
        onClick={this.onClick}
        style={{
          width: '100%',
          fontSize: 'smaller',
          borderLeft: '0',
          borderRight: '0',
          borderBottom: '2px solid white',
          backgroundColor: 'white',
        }}
      >
        <td style={{ width: '40%', padding: '0', border: '0' }}>
          <div className="user-img-wrapper">
            <img src={`${origin}/uploads/${user.thumb}`} alt="User" />
          </div>
        </td>
        <td
          style={{
            position: 'relative',
            verticalAlign: 'baseline',
            padding: '2%',
          }}
        >
          <div className={styles.userName}>
            <b>@{user.username}</b>
            {user.is_favorite ? (
              <i onClick={this.onFavoriteClick} className="fa fa-star" />
            ) : (
              <i onClick={this.onFavoriteClick} className="fa fa-star-o" />
            )}
          </div>
          <div
            style={{
              marginTop: '5%',
              maxHeight: '51px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {interests}
          </div>
          {user.available.toLowerCase() === 'yes' ? (
            <div
              style={{
                height: '30%',
                position: 'absolute',
                left: '0',
                right: '0',
                bottom: '0',
                backgroundColor: '#5cb85c',
                color: 'white',
                cursor: 'pointer',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: '0',
                  left: '0',
                  right: '30%',
                  bottom: '0',
                }}
                onClick={this.onNegotiationClick}
              >
                <div
                  style={{
                    position: 'absolute',
                    top: 'calc(50% - 15px)',
                    left: '5%',
                    height: '30px',
                  }}
                >
                  <div>{costStr}</div>
                  <div style={{ fontSize: 'smaller' }}>Call Now</div>
                </div>
              </div>
              <div
                style={{
                  position: 'absolute',
                  top: '0',
                  width: '20%',
                  right: '0',
                  bottom: '0',
                  backgroundColor: 'rgba(0,0,0,0.25)',
                }}
                onClick={this.onNegotiationClick}
              >
                <i
                  style={{
                    position: 'absolute',
                    top: 'calc(50% - 5px)',
                    left: 'calc(50% - 2px)',
                    display: 'block',
                  }}
                  className="fa fa-phone"
                />
              </div>
            </div>
          ) : (
            <div
              style={{
                height: '30%',
                position: 'absolute',
                left: '0',
                right: '0',
                bottom: '0',
                backgroundColor: '#d22421',
                color: 'white',
                cursor: 'pointer',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: '0',
                  left: '0',
                  right: '30%',
                  bottom: '0',
                }}
                onClick={this.onChatClick}
              >
                <div
                  style={{
                    position: 'absolute',
                    top: 'calc(50% - 15px)',
                    left: '5%',
                    height: '30px',
                  }}
                >
                  <div>{costStr}</div>
                  <div style={{ fontSize: 'smaller' }}>Chat Now</div>
                </div>
              </div>
              <div
                style={{
                  position: 'absolute',
                  top: '0',
                  width: '20%',
                  right: '0',
                  bottom: '0',
                  backgroundColor: 'rgba(0,0,0,0.25)',
                }}
                onClick={this.onChatClick}
              >
                <i
                  style={{
                    position: 'absolute',
                    top: 'calc(50% - 5px)',
                    left: 'calc(50% - 2px)',
                    display: 'block',
                  }}
                  className="fa fa-comment"
                />
              </div>
            </div>
          )}
        </td>
      </tr>
    )
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    user: { username, is_favorite },
  } = ownProps

  const {
    favoriteUserByUsername,
    unfavoriteUserByUsername,
  } = bindActionCreators(
    {
      ...usersActions,
      ...userActions,
    },
    dispatch
  )

  return {
    goToUser: () => dispatch(push(`/user/${username}`)),
    goToChat: () => dispatch(push(`/chat/${username}`)),
    goToNegotiation: () => dispatch(push(`/negotiation/${username}`)),
    onFavoritesClick: () => {
      const action = !is_favorite
        ? favoriteUserByUsername
        : unfavoriteUserByUsername

      action(username)
    },
  }
}

export default connect(
  null,
  mapDispatchToProps
)(UserCell)
