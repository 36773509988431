import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import BlockedUserCell from '../../../../components/BlockedUserCell'
import * as usersActions from 'reducers/users'
import * as userActions from 'reducers/user'
import NavBar from 'components/NavBar'
import { goBack } from 'connected-react-router'
import styles from './BlockedUsers.module.scss'
import swal from 'sweetalert'
import { withLastLocation } from 'react-router-last-location'

class BlockedUsers extends Component {
  componentDidMount() {
    this.fetch()
    this.alert()
  }

  onBackClick = () => {
    if (!this.props.lastLocation) {
      return this.props.history.push('/search/users')
    } else {
      const { dispatch } = this.props
      dispatch(goBack())
    }
  }

  fetch = () => {
    const {
      actions: { fetchBlockedUsers },
    } = this.props
    fetchBlockedUsers()
  }

  alert = () => {
    swal('Manage Blocked Users', 'Tap any row to unblock the user.', {
      className: styles.swalModal,
    })
  }

  unblockUser = async username => {
    const {
      actions: { unblockUser },
    } = this.props
    const res = await unblockUser(username)
    if (res) this.fetch()
  }

  render() {
    let { blockedUsers } = this.props
    let rows = []
    if (blockedUsers.length > 0) {
      rows = blockedUsers.map(user => (
        <BlockedUserCell
          key={user.id}
          user={user}
          unblockUser={this.unblockUser}
        />
      ))
    }

    return (
      <div>
        <div
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            right: '0',
            height: 'calc(1.5em + 20px)',
          }}
        >
          <NavBar
            title={`Manage Blocked Users`}
            onBackClick={this.onBackClick}
          />
        </div>

        <div
          style={{
            position: 'fixed',
            top: 'calc(1.5em + 20px)',
            left: '0',
            right: '0',
            height: 'calc(100% - 1.5em - 20px - 34px)',
            overflowX: 'hidden',
            overflowY: 'scroll',
            WebkitOverflowScrolling: 'touch',
            backgroundColor: 'white',
          }}
        >
          <table
            style={{
              width: '100%',
            }}
          >
            <tbody>
              {rows.length ? (
                rows
              ) : (
                <tr
                  style={{
                    textAlign: 'center',
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                >
                  <td>You have no blocked users.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  blockedUsers: state.users.blockedUsers ? state.users.blockedUsers : [],
})

const mapDispatchToProps = dispatch => ({
  dispatch,
  actions: bindActionCreators(
    {
      ...usersActions,
      ...userActions,
    },
    dispatch
  ),
})

export default withLastLocation(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BlockedUsers)
)
