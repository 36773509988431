import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import users from './users'
import user from './user'
import interests from './interests'
import userDetails from './userDetails'
import interestDetails from './interestDetails'
import { createBrowserHistory } from 'history'
export const history = createBrowserHistory()

const appReducer = combineReducers({
  users,
  user,
  interests,
  userDetails,
  interestDetails,
  router: connectRouter(history),
})

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === 'user/SIGNOUT') {
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer
