import React, { Component } from 'react'
import styles from './GenderFilter.module.scss'
import GenderButton from 'components/GenderFilter/button'

class GenderFilter extends Component {
  render() {
    const { user, onGenderFilterToggle } = this.props

    const prefersBothGenders = () =>
      user.prefers_gender === 'Both' || user.prefers_gender.length === 0

    const prefersGender = gender =>
      gender === user.prefers_gender || prefersBothGenders()

    return (
      <div className={styles.genderIcons}>
        <GenderButton onClick={onGenderFilterToggle} gender={'Male'} active={prefersGender('Male')} />
        <GenderButton onClick={onGenderFilterToggle} gender={'Female'} active={prefersGender('Female')} />
      </div>
    )
  }
}

export default GenderFilter
