// @ts-nocheck
import React, { Component } from 'react'
import InterestCell from 'components/InterestCell'
import { FaGripfire } from 'react-icons/fa'

class InterestsForm extends Component {
  state = {
    newInterest: '',
    newInterests: [],
  }

  componentDidMount() {
    this.listen()
    this.fetch()
  }

  fetch = () => {
    const {
      actions: { fetchInterests },
    } = this.props

    fetchInterests(16)
  }

  isMutualInterest = () => {
    if (!this.props.interest) return false
    const {
      interest: { mutual_interest },
    } = this.props

    return mutual_interest === '1'
  }

  onToggleInterest = (interest, isMutual) => {
    const {
      actions: { addInterest, removeInterest },
    } = this.props

    if (isMutual) {
      removeInterest(interest)
    } else {
      addInterest(interest)
    }
  }

  onSetInterest = async e => {
    const {
      target: { value },
    } = e

    if (value) {
      this.setState({ newInterest: value })
    } else {
      this.setState({ newInterest: '' })
    }
  }

  onAddInterest = async e => {
    e.preventDefault()
    const { newInterest } = this.state
    const {
      actions: { addInterest },
    } = this.props

    try {
      addInterest(newInterest)

      this.setState({
        newInterest: '',
      })
    } catch (error) {
      console.log('error fetching interest.', error)
    }
  }

  listen = () => {
    const interestDiv = document.querySelector('.interest')
    interestDiv &&
      interestDiv.addEventListener(
        'keypress',
        /** @param {KeyboardEvent} e */ e => {
          if (e.keyCode === 13) {
            this.saveInterestOnEnter()
          }
        }
      )
  }

  saveInterestOnEnter = () => {
    /** @type {HTMLInputElement|null} */
    let interestInput = document.querySelector('#interest')
    if (!interestInput) return
    let interestText = interestInput.value
    if (!interestText) {
      return
    }
    const {
      actions: { addInterest },
    } = this.props

    try {
      addInterest(interestText)
      interestInput.value = ''
      this.setState({ newInterest: '' })
    } catch (error) {
      console.log('error fetching interest.', error)
    }
  }

  render() {
    const {
      interests,
      onSkip,
      onBack,
      newInterests,
      isLoadingInterest,
    } = this.props
    const { newInterest } = this.state

    return (
      <div className="interest">
        <div
          style={{
            backgroundColor: '#00aedc',
            padding: '30px 0',
            marginBottom: '20px',
          }}
        >
          <button
            style={{
              background: 'rgba(0,0,0,0)',
              position: 'fixed',
              top: '10px',
              left: '10px',
              color: 'white',
            }}
            className="btn btn-sm"
            onClick={onBack}
          >
            <span className="fa fa-chevron-left mr-3 fa-lg" />
          </button>
          <button
            style={{
              background: 'rgba(0,0,0,0)',
              position: 'fixed',
              top: '10px',
              right: '10px',
              color: 'white',
              fontSize: '14px',
            }}
            className="btn btn-sm"
            onClick={onSkip}
          >
            Skip
          </button>
          <div className="container-fluid" style={{ marginTop: '20px' }}>
            <h3>Follow what matters to you</h3>
            <p>
              Start by following a trending #interest or add one of your own.
            </p>
          </div>
        </div>

        <div className="signup">
          <div className="form-group container-fluid">
            <input
              id="interest"
              placeholder="Add  #interest..."
              type="text"
              autoComplete="off"
              className="form-control"
              value={newInterest}
              onChange={this.onSetInterest}
            />
          </div>

          {!!newInterests.length && (
            <table className="container-fluid" style={{ width: '100%' }}>
              <tbody>
                {newInterests.map(({ interest, mutual_interest, count }) => (
                  <InterestCell
                    key={interest}
                    interest={{
                      mutual_interest,
                      interest,
                      count,
                    }}
                    onClick={this.onToggleInterest}
                    onToggleInterest={this.onToggleInterest}
                    isLoadingInterest={isLoadingInterest}
                  />
                ))}
              </tbody>
            </table>
          )}

          <div
            className="form-group"
            style={{
              backgroundColor: '#2c3031',
              marginTop: '15px',
              position: 'relative',
              padding: '6px 0',
            }}
          >
            <h5
              style={{
                marginLeft: '55px',
              }}
            >
              #TRENDING
            </h5>
            <FaGripfire
              style={{
                height: '25px',
                width: '25px',
                position: 'absolute',
                top: '11px',
                marginLeft: '15px',
                color: '#fcaa52',
              }}
            />
          </div>

          <table className="container-fluid" style={{ width: '100%' }}>
            <tbody>
              {interests.map(interest => (
                <InterestCell
                  key={interest.id}
                  interest={interest}
                  onClick={this.onToggleInterest}
                  onToggleInterest={this.onToggleInterest}
                  isLoadingInterest={isLoadingInterest}
                />
              ))}
            </tbody>
          </table>
        </div>
        <button
          onClick={onSkip}
          className="btn btn-primary btn-block btn-lg"
          style={{
            borderRadius: '0',
          }}
        >
          Done
        </button>
      </div>
    )
  }
}

export default InterestsForm
