import React, { Component } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import cx from 'classnames'
import FloatingLabel from 'floating-label-react'

const VALIDATION = {
  username: Yup.string()
    .min(3)
    .max(32)
    .required('Required'),
  password: Yup.string()
    .min(3)
    .max(64)
    .required('Required'),
}

class SignUpForm extends Component {
  render() {
    const { onSubmit, user } = this.props

    return (
      <>
        <div className="container-fluid" style={{ paddingTop: '30px' }}>
          <div className="row">
            <div className="col-lg-12">
              <h2>Create an account</h2>
            </div>
          </div>
          <div className="signup">
            <Formik
              validateOnBlur={false}
              initialValues={{ username: '', password: '' }}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false)
                onSubmit(values)
              }}
              validationSchema={Yup.object().shape(VALIDATION)}
            >
              {props => {
                const {
                  values,
                  touched,
                  errors,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                } = props
                return (
                  <form className="form" onSubmit={handleSubmit}>
                    <div className="form-group">
                      <FloatingLabel
                        id="username"
                        placeholder="Username"
                        type="text"
                        autoComplete="off"
                        value={values.username}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={cx('', {
                          'has-error': errors.username && touched.username,
                        })}
                      />
                      {errors.username && touched.username && (
                        <div className="invalid-feedback">
                          {errors.username}
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <FloatingLabel
                        id="password"
                        placeholder="Password"
                        type="password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={cx('', {
                          'has-error': errors.password && touched.password,
                        })}
                      />
                      {errors.password && touched.password && (
                        <div className="invalid-feedback">
                          {errors.password}
                        </div>
                      )}
                    </div>
                    {user.error_message && (
                      <div className="invalid-feedback">
                        {user.error_message}
                      </div>
                    )}
                    <button
                      className="btn btn-primary btn-block btn-lg"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Next
                    </button>
                  </form>
                )
              }}
            </Formik>
          </div>
        </div>
      </>
    )
  }
}

export default SignUpForm
