import React, { Component } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import cx from 'classnames'
import _ from 'lodash'

import styles from './Forms.module.scss'

const VALIDATION = {
  fullname: Yup.string(),
  location: Yup.string(),
  about: Yup.string(),
  gender: Yup.string(),
}

class AboutYouForm extends Component {
  state = {
    stateUser: this.props.user,
  }

  submitHandler = null

  toggleGender = (value, _values, setFieldValue) => {
    return setFieldValue('gender', value)
  }

  changePreferenceGender = (value, _values, setFieldValue) => {
    if (_values.includes('Female') && value === 'Male') {
      _values = 'Both'
    } else if (_values.includes('Male') && value === 'Female') {
      _values = 'Both'
    } else if (_values.includes('Both') && value === 'Female') {
      _values = 'Male'
    } else if (_values.includes('Both') && value === 'Male') {
      _values = 'Female'
    } else if (_values.includes('Male') && value === 'Male') {
      _values = ''
    } else if (_values.includes('Female') && value === 'Female') {
      _values = ''
    } else if (_values === '') {
      _values = value
    }
    setFieldValue('prefers_gender', _values)
    return _values
  }

  render() {
    const { user } = this.props

    const {
      actions: { updateUser },
    } = this.props

    const self = this
    return (
      <Formik
        enableReinitialize
        initialValues={{
          fullname: this.state.stateUser.fullname || '',
          location: this.state.stateUser.location || '',
          about: this.state.stateUser.about || '',
          gender: this.state.stateUser.gender ? user.gender : '',
          prefers_gender: this.state.stateUser.prefers_gender
            ? user.prefers_gender
            : '',
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false)
          updateUser(values, false)
        }}
        validationSchema={Yup.object().shape(VALIDATION)}
      >
        {props => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          } = props

          if (!this.submitHandler) {
            this.submitHandler = _.throttle(
              e => {
                console.log(this, self)
                console.log('a', e)
                self.setState(
                  {
                    stateUser: {
                      ...self.props.user,
                      [e.target.id]: e.target.value,
                    },
                  },
                  () => {
                    updateUser({ ...self.state.stateUser })
                  }
                )
              },
              3000,
              { trailing: true }
            )
          }

          const inputsOnChangeHandler = e => {
            e.persist()
            handleChange(e)
            return this.submitHandler(e)
          }

          return (
            <form
              id="aboutyouform"
              className="form"
              style={{
                backgroundColor: '#2c2d2f',
                color: 'white',
              }}
              onSubmit={handleSubmit}
            >
              <div
                className="form-group row"
                style={{ marginTop: '0px', marginBottom: '0px' }}
              >
                <label
                  htmlFor="fullname"
                  className="col-xs-2 col-form-label"
                  style={{
                    margin: '13px 0 0 13px',
                    fontWeight: 'normal',
                    color: '#959595',
                  }}
                >
                  Name
                </label>
                <div className="col-xs-8">
                  <input
                    id="fullname"
                    placeholder="Name"
                    type="text"
                    autoComplete="off"
                    value={values.fullname}
                    onChange={inputsOnChangeHandler}
                    onBlur={handleBlur}
                    style={{
                      border: 'none',
                      boxShadow: 'none',
                      backgroundColor: '#2c2d2f',
                      color: 'white',
                      fontSize: '16px',
                    }}
                    className={cx('form-control input-lg', {
                      'has-error': errors.fullname && touched.fullname,
                    })}
                  />
                </div>
                {errors.fullname && touched.fullname && (
                  <div className="invalid-feedback">{errors.fullname}</div>
                )}
              </div>

              <hr style={{ marginTop: '0', marginBottom: '0' }} />

              <div
                className="form-group row"
                style={{ marginTop: '4px', marginBottom: '4px' }}
              >
                <label
                  htmlFor="location"
                  className="col-xs-2 col-form-label"
                  style={{
                    margin: '13px 0 0 13px',
                    fontWeight: 'normal',
                    color: '#959595',
                  }}
                >
                  Location
                </label>
                <div className="col-xs-8">
                  <input
                    id="location"
                    placeholder="Location"
                    type="text"
                    autoComplete="off"
                    style={{
                      border: 'none',
                      boxShadow: 'none',
                      backgroundColor: '#2c2d2f',
                      color: 'white',
                      fontSize: '16px',
                    }}
                    value={values.location}
                    onChange={inputsOnChangeHandler}
                    onBlur={handleBlur}
                    className={cx('form-control input-lg', {
                      'has-error': errors.location && touched.location,
                    })}
                  />
                </div>
                {errors.location && touched.location && (
                  <div className="invalid-feedback">{errors.location}</div>
                )}
              </div>

              <hr style={{ marginTop: '0', marginBottom: '0' }} />

              <div
                className="form-group row"
                style={{ marginTop: '10px', marginBottom: '10px' }}
              >
                <div className="col-xs-12">
                  <textarea
                    id="about"
                    placeholder="about"
                    type="text"
                    autoComplete="off"
                    style={{
                      border: 'none',
                      boxShadow: 'none',
                      backgroundColor: '#2c2d2f',
                      color: 'white',
                      fontSize: '16px',
                      height: '130px',
                    }}
                    value={values.about}
                    onChange={inputsOnChangeHandler}
                    onBlur={handleBlur}
                    className={cx('form-control input-lg', {
                      'has-error': errors.about && touched.about,
                    })}
                  />
                </div>
                {errors.about && touched.about && (
                  <div className="invalid-feedback">{errors.about}</div>
                )}
              </div>

              <hr style={{ marginTop: '0', marginBottom: '0' }} />

              <div
                className="form-group row"
                style={{ marginTop: '10px', marginBottom: '10px' }}
              >
                <label
                  htmlFor="gender"
                  className="col-xs-2 col-form-label"
                  style={{
                    margin: '8px 0 0 13px',
                    fontWeight: 'normal',
                    color: '#959595',
                  }}
                >
                  Gender
                </label>
                <div
                  className="d-block"
                  style={{
                    marginLeft: 'auto',
                    marginRight: '25px',
                    float: 'right',
                  }}
                >
                  <div
                    onClick={() => {
                      this.toggleGender('Male', values.gender, setFieldValue)
                      updateUser({ ...values, gender: 'Male' }, false)
                    }}
                    style={{
                      borderRadius: '50%',
                      //marginLeft: '53%',
                      background: values.gender.includes('Male')
                        ? '#41acdd'
                        : '',
                      border: !values.gender.includes('Male')
                        ? '1px solid white'
                        : '',
                    }}
                    className={cx('btn', styles.genderButton, {
                      'btn-link': !values.gender.includes('Male'),
                      'btn-primary': values.gender.includes('Male'),
                    })}
                  >
                    <figure className="fa fa-male" style={{ color: 'white' }} />
                  </div>
                  <div
                    onClick={() => {
                      this.toggleGender('Female', values.gender, setFieldValue)
                      updateUser({ ...values, gender: 'Female' }, false)
                    }}
                    style={{
                      borderRadius: '50%',
                      background: values.gender.includes('Female')
                        ? '#41acdd'
                        : '',
                      marginLeft: '10px',
                      border: !values.gender.includes('Female')
                        ? '1px solid white'
                        : '',
                    }}
                    className={cx('btn', styles.genderButton, {
                      'btn-link': !values.gender.includes('Female'),
                      'btn-primary': values.gender.includes('Female'),
                    })}
                  >
                    <figure
                      className="fa fa-female"
                      style={{ color: 'white' }}
                    />
                  </div>
                </div>
                {errors.gender && touched.gender && (
                  <div className="invalid-feedback">{errors.gender}</div>
                )}
              </div>

              <hr style={{ marginTop: '0', marginBottom: '0' }} />

              <div
                className="form-group row"
                style={{ marginTop: '10px', marginBottom: '10px' }}
              >
                <label
                  htmlFor="gender"
                  className="col-xs-4 col-form-label"
                  style={{
                    margin: '8px 0 0 13px',
                    fontWeight: 'normal',
                    color: '#959595',
                  }}
                >
                  I want to see
                </label>
                <div
                  className="d-block"
                  style={{
                    marginLeft: 'auto',
                    marginRight: '25px',
                    float: 'right',
                  }}
                >
                  <div
                    onClick={() => {
                      let pref = this.changePreferenceGender(
                        'Male',
                        values.prefers_gender,
                        setFieldValue
                      )
                      updateUser({ ...values, prefers_gender: pref }, false)
                    }}
                    style={{
                      borderRadius: '50%',
                      //marginLeft: '36.5%',
                      background:
                        values.prefers_gender.includes('Male') ||
                        values.prefers_gender.includes('Both')
                          ? '#41acdd'
                          : '',
                      border:
                        !values.prefers_gender.includes('Male') &&
                        !values.prefers_gender.includes('Both')
                          ? '1px solid white'
                          : '',
                    }}
                    className={cx('btn', styles.genderButton, {
                      'btn-link': !values.prefers_gender.includes('Male'),
                    })}
                  >
                    <figure className="fa fa-male" style={{ color: 'white' }} />
                  </div>
                  <div
                    onClick={() => {
                      let pref = this.changePreferenceGender(
                        'Female',
                        values.prefers_gender,
                        setFieldValue
                      )
                      updateUser({ ...values, prefers_gender: pref }, false)
                    }}
                    style={{
                      borderRadius: '50%',
                      background:
                        values.prefers_gender.includes('Female') ||
                        values.prefers_gender.includes('Both')
                          ? '#41acdd'
                          : '',
                      marginLeft: '10px',
                      border:
                        !values.prefers_gender.includes('Female') &&
                        !values.prefers_gender.includes('Both')
                          ? '1px solid white'
                          : '',
                    }}
                    className={cx('btn', styles.genderButton, {
                      'btn-link': !values.prefers_gender.includes('Female'),
                    })}
                  >
                    <figure
                      className="fa fa-female"
                      style={{ color: 'white' }}
                    />
                  </div>
                </div>
                {errors.prefers_gender && touched.prefers_gender && (
                  <div className="invalid-feedback">
                    {errors.prefers_gender}
                  </div>
                )}
              </div>

              <hr style={{ marginTop: '0', marginBottom: '0' }} />

              <div
                className="form-group row"
                style={{ marginTop: '10px', marginBottom: '10px' }}
              >
                <label
                  htmlFor="gender"
                  className="col-xs-12 col-form-label"
                  style={{
                    margin: '8px 0 8px 13px',
                    fontWeight: 'normal',
                    color: '#959595',
                  }}
                >
                  Let's talk about...
                </label>
              </div>

              <hr style={{ marginTop: '0', marginBottom: '0' }} />

              {user.error_message && (
                <div className="invalid-feedback">{user.error_message}</div>
              )}

              {/* <button
                className="btn btn-success btn-block btn-lg"
                type="submit"
                disabled={isSubmitting}
              >
                Save
              </button> */}
            </form>
          )
        }}
      </Formik>
    )
  }
}

export default AboutYouForm
