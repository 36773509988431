const subscribers = []

function subscribe(cb) {
  subscribers.push(cb)
}

function emit() {
  subscribers.forEach(cb => cb(isLoggedIn()))
}

function getToken() {
  return window.localStorage.token
}

function saveToken(token) {
  window.localStorage.token = token
  emit()
}

function removeToken() {
  delete window.localStorage.token
  emit()
}

function isLoggedIn() {
  const token = getToken()
  let payload

  if (token) {
    payload = token.split('.')[1]
    payload = window.atob(payload)
    payload = JSON.parse(payload)

    if (payload.exp > Date.now() / 1000) {
      return true
    }
    removeToken()
  }
  return false
}

function getUsername(token) {
  let payload = token.split('.')[1]
  payload = window.atob(payload)
  const { username } = JSON.parse(payload)
  return username
}

function currentUser(token = getToken()) {
  if (isLoggedIn()) {
    // const token = getToken();
    let payload = token.split('.')[1]
    payload = window.atob(payload)
    payload = JSON.parse(payload)
    return {
      username: payload.username,
    }
  } else
    return {
      username: null,
    }
}

export default {
  getUsername,
  getToken,
  saveToken,
  removeToken,
  isLoggedIn,
  currentUser,
  subscribe,
}
