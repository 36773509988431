// @ts-nocheck
import { createAction, handleActions } from 'redux-actions'
import qs from 'qs'

// Fetch User
// -----------------------------------------------------------------------------

// Action Types
const USERS_FETCH_REQUEST = 'users/USERS_FETCH_REQUEST'
const USERS_FETCH_RESPONSE = 'users/USERS_FETCH_RESPONSE'
const FETCH_FOLLOWERS_RESPONSE = 'users/FETCH_FOLLOWERS_RESPONSE'
const SEARCH_FOLLOWERS_LIST_RESPONSE = 'SEARCH_FOLLOWERS_LIST_RESPONSE'
const FAVORITE_USER = 'users/FAVORITE_USER'
const UNFAVORITE_USER = 'users/UNFAVORITE_USER'
const REMOVE_USER = 'users/REMOVE_USER'
const SEARCH_USERS_REQUEST = 'users/SEARCH'
const SEARCH_USERS_RESPONSE = 'users/SEARCH_RESPONSE'
const BLOCKED_USERS_REQUEST = 'users/BLOCKED_USERS_REQUEST'
const BLOCKED_USERS_RESPONSE = 'users/BLOCKED_USERS_RESPONSE'
const GET_VIP_SELLER_LIST_REQUEST = 'users/GET_VIP_SELLER_LIST_REQUEST'
const GET_VIP_BUYER_LIST_REQUEST = 'users/GET_VIP_BUYER_LIST_REQUEST'
const GET_VIP_BUYER_MESSAGE_REQUEST = 'users/GET_VIP_BUYER_MESSAGE_REQUEST'
const GET_VIP_SELLER_MESSAGE_REQUEST = 'users/GET_VIP_SELLER_MESSAGE_REQUEST'
const SEARCH_VIP_SELLER_RESPONSE = 'SEARCH_VIP_SELLER_RESPONSE'
const SEARCH_VIP_BUYER_RESPONSE = 'SEARCH_VIP_BUYER_RESPONSE'
const DELETE_CHAT_RESPONSE = 'DELETE_CHAT_RESPONSE'
const UNREAD_MESSAGE_COUNT_RESPONSE = 'users/UNREAD_MESSAGE_COUNT_RESPONSE'
const MESSAGE_THE_DAY_RESPONSE = 'users/MESSAGE_THE_DAY_RESPONSE'
const GET_FOLLOWER_INFO_RESPONSE = 'users/GET_FOLLOWER_INFO_RESPONSE'

// Action Creators
const fetchUsersRequest = createAction(USERS_FETCH_REQUEST)
const fetchUsersResponse = createAction(USERS_FETCH_RESPONSE)
const fetchFollowersResponse = createAction(FETCH_FOLLOWERS_RESPONSE)
const fetchSearchUsersResponse = createAction(SEARCH_USERS_RESPONSE)
const searchUsersRequest = createAction(SEARCH_USERS_REQUEST)
export const favoriteUser = createAction(FAVORITE_USER)
export const unfavoriteUser = createAction(UNFAVORITE_USER)
const removeUserFromArray = createAction(REMOVE_USER)
const fetchBlockedUsersRequest = createAction(BLOCKED_USERS_REQUEST)
const fetchBlockedUsersResponse = createAction(BLOCKED_USERS_RESPONSE)
const searchFollowersListResponse = createAction(SEARCH_FOLLOWERS_LIST_RESPONSE)
const vipBuyerListRespone = createAction(GET_VIP_BUYER_LIST_REQUEST)
const vipSellerListRespone = createAction(GET_VIP_SELLER_LIST_REQUEST)
const vipBuyerMessgeRespone = createAction(GET_VIP_BUYER_MESSAGE_REQUEST)
const vipSellerMessgeRespone = createAction(GET_VIP_SELLER_MESSAGE_REQUEST)
const searchVipSellerResponse = createAction(SEARCH_VIP_SELLER_RESPONSE)
const searchVipBuyerResponse = createAction(SEARCH_VIP_BUYER_RESPONSE)
const deleteChatResponse = createAction(DELETE_CHAT_RESPONSE)
export const messageOfTheDayResponse = createAction(MESSAGE_THE_DAY_RESPONSE)
export const getFollowerInfoResponse = createAction(GET_FOLLOWER_INFO_RESPONSE)
export const unreadMessageCountResponse = createAction(
  UNREAD_MESSAGE_COUNT_RESPONSE
)

// Convenience:
export const fetchActiveChatSessions = () =>
  fetchUsers('/api/missed_calls_and_messages', 'messages', '/messages')
export const fetchFavorites = () =>
  fetchUsers('/api/favorites', 'users', '/favorites')
export const fetchAvailablePromotedUsers = () =>
  fetchUsers('/api/available_promoted_users', 'users', '/')
export const fetchInterestedUsers = interest =>
  fetchUsers(
    `/api/interested_users?interest=${decodeURIComponent(interest)}`,
    'users',
    `/interested-users/${interest}`
  )

/**
 * fetchUsers action creator
 * for example, for `/api/missed_calls_and_messages` the users reside in the `missed_calls` key, but we don't need to or want to create a new action creator for the same model.
 * @param path a path to fetch the items from
 * @param target the resulting key in the returned result we want to add to the USERS array. This is useful to pluck out the users and use the same `fetchUsers` action.
 */
export const fetchUsers = (
  path = '/api/available_promoted_users',
  target = 'users',
  viewPath = '/'
) => async (dispatch, getState, { v2: Api }) => {
  dispatch(fetchUsersRequest())

  try {
    const { data } = await Api.axios({
      method: 'GET',
      url: path,
      headers: {
        ...Api.getAuthHeaders(getState().user),
      },
    })
    return dispatch(fetchUsersResponse({ ...data, target, viewPath }))
  } catch (error) {
    console.log('error', error)
    return dispatch(fetchUsersResponse(error))
  }
}

export const fetchFollowers = () => async (dispatch, getState, { v2: Api }) => {
  dispatch(fetchUsersRequest())

  try {
    const { data } = await Api.axios({
      method: 'GET',
      url: '/api/followers',
      headers: {
        ...Api.getAuthHeaders(getState().user),
      },
    })
    return dispatch(fetchFollowersResponse({ ...data }))
  } catch (error) {
    console.log('error', error)
    return dispatch(fetchFollowersResponse(error))
  }
}

export const fetchBuyers = () => async (dispatch, getState, { v2: Api }) => {
  dispatch(fetchUsersRequest())

  try {
    const { data } = await Api.axios({
      method: 'GET',
      url: '/api/vip_buyers_list',
      headers: {
        ...Api.getAuthHeaders(getState().user),
      },
    })
    return dispatch(vipBuyerListRespone({ ...data }))
  } catch (error) {
    console.log('error', error)
    return dispatch(vipBuyerListRespone(error))
  }
}

export const fetchSellers = () => async (dispatch, getState, { v2: Api }) => {
  dispatch(fetchUsersRequest())

  try {
    const { data } = await Api.axios({
      method: 'GET',
      url: '/api/vip_sellers_list',
      headers: {
        ...Api.getAuthHeaders(getState().user),
      },
    })
    return dispatch(vipSellerListRespone({ ...data }))
  } catch (error) {
    console.log('error', error)
    return dispatch(vipSellerListRespone(error))
  }
}

export const getVipSellerInfoMessage = () => async (
  dispatch,
  getState,
  { v2: Api }
) => {
  dispatch(fetchUsersRequest())

  try {
    const { data } = await Api.axios({
      method: 'GET',
      url: '/api/vip_sellers_info',
      headers: {
        ...Api.getAuthHeaders(getState().user),
      },
    })
    return dispatch(vipSellerMessgeRespone({ ...data }))
  } catch (error) {
    console.log('error', error)
    return dispatch(vipSellerMessgeRespone(error))
  }
}

export const getVipBuyerInfoMessage = () => async (
  dispatch,
  getState,
  { v2: Api }
) => {
  dispatch(fetchUsersRequest())

  try {
    const { data } = await Api.axios({
      method: 'GET',
      url: '/api/vip_buyers_info',
      headers: {
        ...Api.getAuthHeaders(getState().user),
      },
    })
    return dispatch(vipBuyerMessgeRespone({ ...data }))
  } catch (error) {
    console.log('error', error)
    return dispatch(vipBuyerMessgeRespone(error))
  }
}

export const getMessageOfTheDay = () => async (
  dispatch,
  getState,
  { v2: Api }
) => {
  dispatch(fetchUsersRequest())

  try {
    const { data } = await Api.axios({
      method: 'GET',
      url: '/api/motd',
      headers: {
        ...Api.getAuthHeaders(getState().user),
      },
    })
    return dispatch(messageOfTheDayResponse({ ...data }))
  } catch (error) {
    console.log('error', error)
    return dispatch(messageOfTheDayResponse(error))
  }
}

export const getFollowerInfo = () => async (
  dispatch,
  getState,
  { v2: Api }
) => {
  dispatch(fetchUsersRequest())

  try {
    const { data } = await Api.axios({
      method: 'GET',
      url: '/api/followers_info',
      headers: {
        ...Api.getAuthHeaders(getState().user),
      },
    })
    return dispatch(getFollowerInfoResponse({ ...data }))
  } catch (error) {
    console.log('error', error)
    return dispatch(getFollowerInfoResponse(error))
  }
}

export const favoriteUserByUsername = username => async (
  dispatch,
  getState,
  { v2: Api }
) => {
  dispatch(fetchUsersRequest())

  const payload = encodeURIComponent(username)

  try {
    await Api.axios({
      url: '/api/favorite',
      method: 'POST',
      data: qs.stringify({
        favorite_username: payload,
      }),
      headers: {
        ...Api.getAuthHeaders(getState().user),
      },
    })
    return dispatch(favoriteUser(payload))
  } catch (error) {
    console.log('error favoriting user:', error, payload)
  }
}

export const searchUsers = (searchTerm, target = 'users') => async (
  dispatch,
  getState,
  { v2: Api }
) => {
  const payload = encodeURIComponent(searchTerm)
  dispatch(searchUsersRequest(payload))

  try {
    const { data } = await Api.axios({
      url: '/api/search_users',
      method: 'GET',
      params: {
        term: payload,
      },
      headers: {
        ...Api.getAuthHeaders(getState().user),
      },
    })
    return dispatch(fetchUsersResponse({ ...data, target, viewPath: payload }))
  } catch (error) {
    console.log('error favoriting user:', error, payload)
  }
}

export const searchFollowers = searchTerm => async (
  dispatch,
  getState,
  { v2: Api }
) => {
  const payload = encodeURIComponent(searchTerm)
  dispatch(searchUsersRequest(payload))

  try {
    const { data } = await Api.axios({
      url: '/api/search_followers',
      method: 'GET',
      params: {
        term: payload,
      },
      headers: {
        ...Api.getAuthHeaders(getState().user),
      },
    })
    return dispatch(searchFollowersListResponse({ ...data }))
  } catch (error) {
    console.log('error favoriting user:', error, payload)
  }
}

export const searchVipSeller = searchTerm => async (
  dispatch,
  getState,
  { v2: Api }
) => {
  const payload = encodeURIComponent(searchTerm)
  dispatch(searchUsersRequest(payload))

  try {
    const { data } = await Api.axios({
      url: '/api/search_vip_sellers_list',
      method: 'GET',
      params: {
        term: payload,
      },
      headers: {
        ...Api.getAuthHeaders(getState().user),
      },
    })
    return dispatch(searchVipSellerResponse({ ...data }))
  } catch (error) {
    console.log('error searching user:', error, payload)
  }
}

export const searchVipBuyer = searchTerm => async (
  dispatch,
  getState,
  { v2: Api }
) => {
  const payload = encodeURIComponent(searchTerm)
  dispatch(searchUsersRequest(payload))

  try {
    const { data } = await Api.axios({
      url: '/api/search_vip_buyers_list',
      method: 'GET',
      params: {
        term: payload,
      },
      headers: {
        ...Api.getAuthHeaders(getState().user),
      },
    })
    return dispatch(searchVipBuyerResponse({ ...data }))
  } catch (error) {
    console.log('error searching user:', error, payload)
  }
}

export const unfavoriteUserByUsername = username => async (
  dispatch,
  getState,
  { v2: Api }
) => {
  dispatch(fetchUsersRequest())

  const payload = encodeURIComponent(username)

  try {
    await Api.axios({
      url: '/api/unfavorite',
      method: 'POST',
      data: qs.stringify({
        favorite_username: payload,
      }),
      headers: {
        ...Api.getAuthHeaders(getState().user),
      },
    })
    return dispatch(unfavoriteUser(payload))
  } catch (error) {
    console.log('error unfavoriting user:', error, payload)
  }
}

export const deleteChatByChatID = payload => async (
  dispatch,
  getState,
  { v2: Api }
) => {
  dispatch(fetchUsersRequest())

  try {
    const { data } = await Api.axios({
      url: '/api/delete_chat',
      method: 'POST',
      data: qs.stringify({
        chat_id: payload,
      }),
      headers: {
        ...Api.getAuthHeaders(getState().user),
      },
    })
    if (data.success === 1) fetchActiveChatSessions()
    return dispatch(deleteChatResponse(payload))
  } catch (error) {
    console.log('error deleting chat:', error, payload)
  }
}

export const fetchBlockedUsers = (path = '/api/get_blocked_users') => async (
  dispatch,
  getState,
  { v2: Api }
) => {
  dispatch(fetchBlockedUsersRequest())

  try {
    const { data } = await Api.axios({
      method: 'GET',
      url: path,
      headers: {
        ...Api.getAuthHeaders(getState().user),
      },
    })

    return dispatch(fetchBlockedUsersResponse(data.users))
  } catch (error) {
    console.log('error', error)
    return dispatch(fetchBlockedUsersResponse(error))
  }
}

export const unreadMessageCount = () => async (
  dispatch,
  getState,
  { v2: Api }
) => {
  dispatch(fetchUsersRequest())

  try {
    const { data } = await Api.axios({
      method: 'GET',
      url: '/api/chat_count',
      headers: {
        ...Api.getAuthHeaders(getState().user),
      },
    })
    return dispatch(unreadMessageCountResponse(data.messages))
  } catch (error) {
    console.log('error', error)
    return dispatch(unreadMessageCountResponse(error))
  }
}

export const removeUser = username => (dispatch, getState, Api) => {
  dispatch(removeUserFromArray(username))
}

// User Reducer
// -----------------------------------------------------------------------------

// Default State
const defaultState = {
  isSearching: false,
  isLoading: false,
}

const setFavoriteUsers = (state, username, isFavorite) => {
  const newState = Object.assign({}, state)
  /**
   * We assume that any keys on this path that are arrays are the collections.
   */
  const paths = Object.keys(newState).filter(i => Array.isArray(newState[i]))
  paths.forEach(path => {
    const index = newState[path].findIndex(item => item.username === username)
    if (newState[path][index]) newState[path][index].is_favorite = isFavorite
  })

  return { ...newState, isLoading: false }
}

const setRemoveUser = (state, payload) => {
  const newState = Object.assign({}, state)
  /**
   * We assume that any keys on this path that are arrays are the collections.
   */
  const paths = Object.keys(newState).filter(i => Array.isArray(newState[i]))
  paths.forEach(path => {
    newState[path] = newState[path].filter(i => i.username !== payload)

    if (!newState[path].length) {
      delete newState[path]
    }
  })

  return { ...newState, isLoading: false }
}

// Reducer
export default handleActions(
  {
    [fetchUsersRequest]: {
      next: state => ({
        ...state,
        isLoading: true,
      }),
    },
    [searchUsersRequest]: {
      next: (state, { payload }) => ({
        ...state,
        isSearching: true,
        searchTerm: payload,
        isLoading: true,
      }),
    },
    [fetchUsersResponse]: {
      next: (state, { payload }) => ({
        ...state,
        isSearching: false,
        isLoading: false,
        [payload.viewPath]: payload[payload.target],
      }),
    },
    [fetchFollowersResponse]: {
      next: (state, { payload }) => ({
        ...state,
        isLoading: false,
        followers: payload.users,
      }),
    },
    [vipSellerListRespone]: {
      next: (state, { payload }) => ({
        ...state,
        isLoading: false,
        vipSellers: payload.users,
      }),
    },
    [vipBuyerListRespone]: {
      next: (state, { payload }) => ({
        ...state,
        isLoading: false,
        vipBuyers: payload.users,
      }),
    },
    [vipSellerMessgeRespone]: {
      next: (state, { payload }) => ({
        ...state,
        isLoading: false,
        vipSellerMessage: payload.message,
      }),
    },
    [vipBuyerMessgeRespone]: {
      next: (state, { payload }) => ({
        ...state,
        isLoading: false,
        vipBuyerMessage: payload.message,
      }),
    },
    [messageOfTheDayResponse]: {
      next: (state, { payload }) => ({
        ...state,
        isLoading: false,
        msgOfTheDay: payload.message,
      }),
    },
    [getFollowerInfoResponse]: {
      next: (state, { payload }) => ({
        ...state,
        isLoading: false,
        followersInfoMsg: payload.message,
      }),
    },
    [fetchSearchUsersResponse]: {
      next: (state, { payload }) => ({
        ...state,
        isSearching: false,
        isLoading: false,
        [payload.searchTerm]: payload[payload.target],
      }),
    },
    [searchFollowersListResponse]: {
      next: (state, { payload }) => ({
        ...state,
        isLoading: false,
        isSearching: false,
        followers: payload.users,
      }),
    },
    [searchVipSellerResponse]: {
      next: (state, { payload }) => ({
        ...state,
        isLoading: false,
        isSearching: false,
        vipSellers: payload.users,
      }),
    },
    [searchVipBuyerResponse]: {
      next: (state, { payload }) => ({
        ...state,
        isLoading: false,
        isSearching: false,
        vipBuyers: payload.users,
      }),
    },
    [favoriteUser]: {
      next: (state, { payload }) => setFavoriteUsers(state, payload, true),
    },
    [unfavoriteUser]: {
      next: (state, { payload }) => setFavoriteUsers(state, payload, false),
    },
    [removeUserFromArray]: {
      next: (state, { payload }) => setRemoveUser(state, payload),
    },
    [fetchBlockedUsersRequest]: {
      next: state => ({
        ...state,
        isLoading: true,
      }),
    },
    [fetchBlockedUsersResponse]: {
      next: (state, { payload }) => ({
        ...state,
        blockedUsers: payload,
        isLoading: false,
      }),
    },
    [deleteChatResponse]: {
      next: (state, { payload }) => ({
        ...state,
        isLoading: false,
      }),
    },
    [unreadMessageCountResponse]: {
      next: (state, { payload }) => ({
        ...state,
        isLoading: false,
        messageCount: payload,
      }),
    },
  },

  defaultState
)
