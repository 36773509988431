import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'

class NavBar extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  onBackClick = e => {
    e.preventDefault()
    this.props.onBackClick && this.props.onBackClick()
  }

  onActionClick = e => {
    e.preventDefault()
    this.props.onActionClick && this.props.onActionClick()
  }

  onTitleClick = e => {
    e.preventDefault()
    this.props.onTitleClick && this.props.onTitleClick()
  }

  render() {
    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />
    }
    return (
      <div style={{ background: '#222', padding: '10px' }}>
        <div className="row" style={{ height: '21px' }}>
          <div className="col-xs-2">
            <div onClick={this.onBackClick} style={{ textAlign: 'left' }}>
              <i style={{ color: 'white' }} className="fa fa-angle-left" />
            </div>
          </div>
          <div className="col-xs-8">
            <div onClick={this.onTitleClick} style={{ textAlign: 'center' }}>
              <b style={{ color: 'white' }}>{this.props.title}</b>
            </div>
          </div>
          {this.props.onActionClick && (
            <div className="col-xs-2">
              <div onClick={this.onActionClick} style={{ textAlign: 'right' }}>
                <i
                  style={{ color: 'white', marginTop: '4px' }}
                  className="fa fa-ellipsis-h"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default NavBar
