// @ts-nocheck
import { createAction, handleActions } from 'redux-actions'
import { addInterestResponse, removeInterestResponse } from 'reducers/user'

// Action Types
const INTERESTS_FETCH_REQUEST = 'interests/INTERESTS_FETCH_REQUEST'
const INTERESTS_FETCH_RESPONSE = 'interests/INTERESTS_FETCH_RESPONSE'

// Action Creators
const fetchInterestsRequest = createAction(INTERESTS_FETCH_REQUEST)
const fetchInterestsResponse = createAction(INTERESTS_FETCH_RESPONSE)

// Async Actions
export const fetchInterests = (limit = 500) => async (
  dispatch,
  getState,
  { v2: Api }
) => {
  dispatch(fetchInterestsRequest())

  try {
    const { data } = await Api.axios({
      url: `/api/popular_interests_details?limit=${limit}`,
      method: 'GET',
      headers: {
        ...Api.getAuthHeaders(getState().user),
      },
    })
    return dispatch(
      fetchInterestsResponse({ ...data, viewPath: `top${limit}` })
    )
  } catch (error) {
    return dispatch(fetchInterestsResponse(error))
  }
}

export const searchInterests = str => async (
  dispatch,
  getState,
  { v2: Api }
) => {
  dispatch(fetchInterestsRequest())

  try {
    const { data } = await Api.axios({
      url: `/api/search_interests?term=${str}`,
      method: 'GET',
      headers: {
        ...Api.getAuthHeaders(getState().user),
      },
    })
    return dispatch(fetchInterestsResponse({ ...data, viewPath: str }))
  } catch (error) {
    return dispatch(fetchInterestsResponse(error))
  }
}

const addInterestToItems = (state, interest, interestData) => {
  let newState = Object.assign({}, state, {
    isLoading: false,
  })
  Object.keys(newState).forEach(key => {
    if (Array.isArray(newState[key])) {
      const index = newState[key].findIndex(item => item.interest === interest)
      if (key === 'top16' && index < 0) {
        newState.newInterests = newState.newInterests.concat(interestData)
      } else if (index >= 0) {
        newState[key][index] = interestData
      }
    }
  })

  return { ...newState }
}

const removeInterestFromItems = (state, interest, interestData) => {
  let newState = Object.assign({}, state, {
    isLoading: false,
  })
  Object.keys(newState).forEach(key => {
    if (Array.isArray(newState[key])) {
      const index = newState[key].findIndex(item => item.interest === interest)
      if (key === 'newInterests' && index >= 0) {
        newState[key].splice(index, 1)
      } else if (index >= 0) {
        newState[key][index] = interestData
      }
    }
  })
  return { ...newState }
}

// Interests Reducer
// -----------------------------------------------------------------------------

// Default State
const defaultState = {
  newInterests: [],
}

// Reducer
export default handleActions(
  {
    [fetchInterestsResponse]: {
      next: (state, { payload }) => ({
        ...state,
        isLoading: false,
        [`${payload.viewPath}`]: payload.interests,
      }),
    },
    [fetchInterestsRequest]: {
      next: state => ({
        ...state,
        isLoading: true,
      }),
    },
    [addInterestResponse]: {
      next: (state, { payload: { interest, interestData } }) =>
        addInterestToItems(state, interest, interestData),
    },
    [removeInterestResponse]: {
      next: (state, { payload: { interest, interestData } }) =>
        removeInterestFromItems(state, interest, interestData),
    },
  },

  defaultState
)
