import React, { Component } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import cx from 'classnames'
import FloatingLabel from 'floating-label-react'
import { withRouter } from 'react-router-dom'

const VALIDATION = {
  username: Yup.string()
    .min(3)
    .max(32)
    .required('Required'),
  password: Yup.string()
    .min(3)
    .max(64)
    .required('Required'),
}

class SignInForm extends Component {
  render() {
    const { onSubmit, setAppDetails, user, history } = this.props

    return (
      <>
        <div className="row">
          <div className="col-lg-12">
            <h2>Log In</h2>
          </div>
        </div>
        <div className="signin">
          <Formik
            validateOnBlur={false}
            initialValues={{ username: '', password: '' }}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false)
              onSubmit(values).then(res => {
                if (res.token) {
                  const payload = {
                    version: 'web',
                    language: 'en',
                  }
                  setAppDetails(payload)
                }
              })
            }}
            validationSchema={Yup.object().shape(VALIDATION)}
          >
            {props => {
              const {
                values,
                touched,
                errors,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                isValid,
              } = props
              return (
                <form className="form" onSubmit={handleSubmit}>
                  <div className="form-group">
                    <FloatingLabel
                      id="username"
                      placeholder="Username"
                      type="text"
                      autoComplete="off"
                      value={values.username}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={cx('', {
                        'has-error': errors.username && touched.username,
                      })}
                    />
                    {errors.username && touched.username && (
                      <div className="invalid-feedback">{errors.username}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <FloatingLabel
                      id="password"
                      placeholder="Password"
                      type="password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={cx('', {
                        'has-error': errors.password && touched.password,
                      })}
                    />
                    {errors.password && touched.password && (
                      <div className="invalid-feedback">{errors.password}</div>
                    )}
                  </div>
                  {user.error_message && (
                    <div className="invalid-feedback">{user.error_message}</div>
                  )}
                  <button
                    className="btn btn-primary btn-block btn-lg"
                    style={{
                      borderRadius: '3px',
                      backgroundColor: isValid ? '#00abea' : '#b7b4b8',
                      borderColor: isValid ? '#00abea' : '#b7b4b8',
                    }}
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Log In
                  </button>
                  <button
                    className="btn btn-primary btn-block btn-lg"
                    style={{
                      borderRadius: '3px',
                      backgroundColor: '#191919',
                      color: '#0a8dc2',
                      borderColor: '#0a8dc2',
                      marginTop: '10px',
                    }}
                    onClick={() => history.push('/forgot-password')}
                  >
                    Forgot Password?
                  </button>
                </form>
              )
            }}
          </Formik>
        </div>
      </>
    )
  }
}

export default withRouter(SignInForm)
