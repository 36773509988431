import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import cx from 'classnames'
import styles from './SearchBar.module.scss'
import GenderFilter from 'components/GenderFilter'
import { FaCrown } from 'react-icons/fa'

class SearchBar extends Component {
  state = {
    value: '',
  }

  componentWillReceiveProps() {
    this.setState({ redirect: null })
  }

  onSearchInterestsClick = e => {
    e.preventDefault()
    this.setState({ redirect: `/search/interests` })
  }

  onSearchUsersClick = e => {
    e.preventDefault()
    this.setState({ redirect: `/search/users` })
  }

  onSearchFollowersClick = e => {
    e.preventDefault()
    this.setState({ redirect: `/search/followers` })
  }

  onSearchCrownClick = e => {
    e.preventDefault()
    const { user } = this.props
    if (user.is_vip_seller === 'YES' && user.is_vip_buyer === 'YES') {
      this.setState({ redirect: `/search/crown_club_buyers_list` })
    } else if (user.is_vip_seller === 'YES') {
      this.setState({ redirect: `/search/crown_club_sellers_list` })
    } else if (user.is_vip_buyer === 'YES') {
      this.setState({ redirect: `/search/crown_club_buyers_list` })
    }
  }

  onChange = e => {
    const { onSearch } = this.props

    this.setState(
      {
        value: e.target.value,
      },
      () => {
        if (onSearch) {
          return onSearch(this.state.value)
        }
      }
    )
  }

  render() {
    const { onGenderFilterToggle, value, user } = this.props

    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />
    }
    const selected = this.props.selected || 'users'
    return (
      <div className={styles.bar}>
        <input
          id="search"
          type="text"
          autoComplete="off"
          className="form-control input-lg"
          placeholder="Search..."
          onChange={this.onChange}
          value={value ? value : this.state.value}
          ref={input => {
            this.nameInput = input
          }}
        />
        <div className={styles.barNavigation}>
          <div
            onClick={this.onSearchUsersClick}
            className={styles.userIconWrapper}
            style={{
              borderBottom: `2px solid ${
                selected === 'users' ? 'lime' : '#222'
              }`,
            }}
          >
            <i className={cx('fa fa-user', styles.userIcon)} />
          </div>
          <div
            onClick={this.onSearchInterestsClick}
            className={styles.interestsIconWrapper}
            style={{
              borderBottom: `2px solid ${
                selected === 'interests' ? 'lime' : '#222'
              }`,
            }}
          >
            <i className={cx('fa fa-slack', styles.interestsIcon)} />
          </div>
          <div
            onClick={this.onSearchFollowersClick}
            className={styles.followersIconWrapper}
            style={{
              borderBottom: `2px solid ${
                selected === 'followers' ? 'lime' : '#222'
              }`,
            }}
          >
            <i className={cx('fa fa-users', styles.followersIcon)} />
          </div>

          {user.is_vip_seller === 'YES' || user.is_vip_buyer === 'YES' ? (
            <div
              onClick={this.onSearchCrownClick}
              className={styles.crownIconWrapper}
              style={{
                borderBottom: `2px solid ${
                  selected === 'crownclubseller' ||
                  selected === 'crownclubbuyer'
                    ? 'lime'
                    : '#222'
                }`,
              }}
            >
              <FaCrown className={cx(styles.crownIcon)} />
            </div>
          ) : null}

          <div
            style={{ height: '30px', width: '70px', margin: '10px 0 0 auto' }}
          >
            {onGenderFilterToggle && <GenderFilter {...this.props} />}
          </div>
        </div>
      </div>
    )
  }
}

export default SearchBar
