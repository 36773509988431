import React, { Component } from 'react'
import { connect } from 'react-redux'
import AccountLayout from '../../layout'
import { bindActionCreators } from 'redux'
import * as userActions from 'reducers/user'
import * as interestsActions from 'reducers/interests'
import * as userDetailActions from 'reducers/userDetails'

import { AboutYouForm, AboutYouPhotoForm, InterestsForm } from './Forms'

class EditProfile extends Component {
  render() {
    const { user, userInterests, actions, isLoadingInterest } = this.props
    return (
      <>
        <AccountLayout>
          <AboutYouPhotoForm user={user} actions={actions} />
          <AboutYouForm user={user} actions={actions} />
          <InterestsForm
            user={user}
            interests={userInterests}
            actions={actions}
            isLoadingInterest={isLoadingInterest}
            cb={Math.round(Math.random() * 100000)}
          />
        </AccountLayout>
      </>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user ? state.user : {},
  isLoadingInterest: state.interests.isLoading, // forces a re-render
  isLoading: state.users.isLoading, // forces a re-render
  userInterests: state.user.interests,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...userActions,
      ...interestsActions,
      ...userDetailActions,
    },
    dispatch
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditProfile)
