// @ts-nocheck
import { createAction, handleActions } from 'redux-actions'
import { LOCATION_CHANGE, goBack } from 'connected-react-router'
import qs from 'qs'
import Session from '../api/Session'

// Fetch User
// -----------------------------------------------------------------------------

// Action Types
const USER_FETCH_REQUEST = 'user/USER_FETCH_REQUEST'
const USER_FETCH_RESPONSE = 'user/USER_FETCH_RESPONSE'
const USER_FETCH_INTERESTS_RESPONSE = 'user/USER_FETCH_INTERESTS_RESPONSE'
const USER_UPDATE_RESPONSE = 'user/USER_UPDATE_RESPONSE'
const USER_SIGNIN_SUCCESS = 'user/SIGNIN_SUCCESS'
const USER_SIGNIN_ERROR = 'user/SIGNIN_ERROR'
const USER_SIGNUP_SUCCESS = 'user/USER_SIGNUP_SUCCESS'
const USER_SIGNUP_ERROR = 'user/USER_SIGNUP_ERROR'
const USER_SIGNOUT = 'user/SIGNOUT'
const ADD_INTEREST = 'user/ADD_INTEREST'
const REPORT_USER = 'user/REPORT_USER'
const BLOCK_USER = 'user/BLOCK_USER'
const UNBLOCK_USER = 'user/UNBLOCK_USER'
const REMOVE_INTEREST = 'user/REMOVE_INTEREST'
const CHANGE_PASSWORD_REQUEST = 'user/CHANGE_PASSWORD_REQUEST'
const CHANGE_PASSWORD_RESPONSE = 'user/CHANGE_PASSWORD_RESPONSE'
const UPDATE_USER_RESPONSE = 'user/UPDATE_USER_RESPONSE'
const DELETE_USER_RESPONSE = 'user/DELETE_USER_RESPONSE'
const RECOVER_PASSWORD_VIA_EMAIL_RESPONSE =
  'user/RECOVER_PASSWORD_VIA_EMAIL_RESPONSE'
const RECOVER_PASSWORD_VIA_SMS_RESPONSE =
  'user/RECOVER_PASSWORD_VIA_SMS_RESPONSE'
const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR'
const GET_EARNED_UENS_SUCCESS = 'GET_EARNED_UENS_SUCCESS'
const GET_PENDING_PAYMENT_SUCCESS = 'GET_PENDING_PAYMENT_SUCCESS'
const GET_PAYMENT_HISTORY_SUCCESS = 'GET_PAYMENT_HISTORY_SUCCESS'
const GET_CALL_HISTORY_SUCCESS = 'GET_CALL_HISTORY_SUCCESS'
const GET_FINANCIAL_INFO_SUCCESS = 'GET_FINANCIAL_INFO_SUCCESS'
const UPDATE_FINANCIAL_INFO_SUCCESS = 'UPDATE_FINANCIAL_INFO_SUCCESS'
const QUEUE_DISTRIBUTION_SUCCESS = 'QUEUE_DISTRIBUTION_SUCCESS'
const CASHOUT_MESSAGE_SUCCESS = 'CASHOUT_MESSAGE_SUCCESS'
const CREATE_SUBMERCHANT_SUCCESS = 'CREATE_SUBMERCHANT_SUCCESS'
const NOTIFICATION_TIME_SUCCESS = 'NOTIFICATION_TIME_SUCCESS'
const CAN_CALL_USER_SUCCESS = 'CAN_CALL_USER_SUCCESS'
const CAN_CALL_USER_ERROR = 'CAN_CALL_USER_ERROR'

// Action Creators
const fetchUserRequest = createAction(USER_FETCH_REQUEST)
const fetchUserResponse = createAction(USER_FETCH_RESPONSE)
export const reportUserResponse = createAction(REPORT_USER)
export const blockUserResponse = createAction(BLOCK_USER)
export const unblockUserResponse = createAction(UNBLOCK_USER)
export const addInterestResponse = createAction(ADD_INTEREST)
export const removeInterestResponse = createAction(REMOVE_INTEREST)
export const fetchUserInterestsResponse = createAction(
  USER_FETCH_INTERESTS_RESPONSE
)
const signInUserSuccess = createAction(USER_SIGNIN_SUCCESS)
const signInUserError = createAction(USER_SIGNIN_ERROR)
const signUpUserSuccess = createAction(USER_SIGNUP_SUCCESS)
const signUpUserError = createAction(USER_SIGNUP_ERROR)
const changePasswordSuccess = createAction(CHANGE_PASSWORD_REQUEST)
const changePasswordError = createAction(CHANGE_PASSWORD_RESPONSE)
const updateUserError = createAction(UPDATE_USER_RESPONSE)
const fetchUserUpdateResponse = createAction(USER_UPDATE_RESPONSE)
export const signOutUser = createAction(USER_SIGNOUT)
const deleteUserResponse = createAction(DELETE_USER_RESPONSE)
const recoverPassViaEmailResponse = createAction(
  RECOVER_PASSWORD_VIA_EMAIL_RESPONSE
)
const recoverPassViaSmsResponse = createAction(
  RECOVER_PASSWORD_VIA_SMS_RESPONSE
)
const resetPasswordSuccess = createAction(RESET_PASSWORD_SUCCESS)
const resetPasswordError = createAction(RESET_PASSWORD_ERROR)
const getEarnedUensSuccess = createAction(GET_EARNED_UENS_SUCCESS)
const getPendingPaymentSuccess = createAction(GET_PENDING_PAYMENT_SUCCESS)
const getPaymentHistorySuccess = createAction(GET_PAYMENT_HISTORY_SUCCESS)
const getCallHistorySuccess = createAction(GET_CALL_HISTORY_SUCCESS)
const getFinancialInfoResponse = createAction(GET_FINANCIAL_INFO_SUCCESS)
const updateFinancialInfoResponse = createAction(UPDATE_FINANCIAL_INFO_SUCCESS)
const queueDistributionResponse = createAction(QUEUE_DISTRIBUTION_SUCCESS)
const getCashoutMessageResponse = createAction(CASHOUT_MESSAGE_SUCCESS)
const createSubmerchantResponse = createAction(CREATE_SUBMERCHANT_SUCCESS)
const notificationTimeResponse = createAction(NOTIFICATION_TIME_SUCCESS)
const canCallUserResponse = createAction(CAN_CALL_USER_SUCCESS)
const canCallUserError = createAction(CAN_CALL_USER_ERROR)
// Async Actions

export const fetchUserInterests = () => async (
  dispatch,
  getState,
  { v2: Api }
) => {
  dispatch(fetchUserRequest())

  try {
    const { data } = await Api.axios({
      url: `/api/interests_details`,
      method: 'GET',
      headers: {
        ...Api.getAuthHeaders(getState().user),
      },
    })
    return dispatch(
      fetchUserInterestsResponse({
        interests: data.interests,
        username: getState().user.username,
      })
    )
  } catch (error) {
    return dispatch(fetchUserInterestsResponse(error))
  }
}

export const reportUser = (username, reason) => async (
  dispatch,
  getState,
  { v2: Api }
) => {
  dispatch(fetchUserRequest())

  try {
    await Api.axios({
      url: '/api/report_user',
      method: 'POST',
      data: qs.stringify({
        user: username,
        reason,
      }),
      headers: {
        ...Api.getAuthHeaders(getState().user),
      },
    })

    return dispatch(reportUserResponse({ username, reason }))
  } catch (error) {
    console.log('error', error)
    return dispatch(reportUserResponse(error))
  }
}

export const blockUser = username => async (
  dispatch,
  getState,
  { v2: Api }
) => {
  dispatch(fetchUserRequest())

  try {
    await Api.axios({
      url: '/api/block_user',
      method: 'POST',
      data: qs.stringify({
        user: username,
      }),
      headers: {
        ...Api.getAuthHeaders(getState().user),
      },
    })

    return dispatch(blockUserResponse({ username }))
  } catch (error) {
    console.log('error', error)
    return dispatch(blockUserResponse(error))
  }
}

export const unblockUser = username => async (
  dispatch,
  getState,
  { v2: Api }
) => {
  dispatch(fetchUserRequest())

  try {
    await Api.axios({
      url: '/api/unblock_user',
      method: 'POST',
      data: qs.stringify({
        user: username,
      }),
      headers: {
        ...Api.getAuthHeaders(getState().user),
      },
    })

    return dispatch(unblockUserResponse({ username }))
  } catch (error) {
    console.log('error', error)
    return dispatch(unblockUserResponse(error))
  }
}

export const addInterest = interest => async (
  dispatch,
  getState,
  { v2: Api }
) => {
  dispatch(fetchUserRequest())

  try {
    await Api.axios({
      url: '/api/add_interest',
      method: 'POST',
      data: qs.stringify({
        interest,
      }),
      headers: {
        ...Api.getAuthHeaders(getState().user),
      },
    })

    const { data } = await Api.axios({
      url: `/api/interest_details?interest=${encodeURIComponent(interest)}`,
      method: 'GET',
      headers: {
        ...Api.getAuthHeaders(getState().user),
      },
    })
    const interestData = data.interest
    const me = getState().user.username
    return dispatch(addInterestResponse({ interest, interestData, me }))
  } catch (error) {
    console.log('error', error)
    return dispatch(addInterestResponse(error))
  }
}

export const removeInterest = interest => async (
  dispatch,
  getState,
  { v2: Api }
) => {
  dispatch(fetchUserRequest())

  try {
    await Api.axios({
      url: '/api/remove_interest',
      method: 'POST',
      data: qs.stringify({
        interest,
      }),
      headers: {
        ...Api.getAuthHeaders(getState().user),
      },
    })
    const { data } = await Api.axios({
      url: `/api/interest_details?interest=${encodeURIComponent(interest)}`,
      method: 'GET',
      headers: {
        ...Api.getAuthHeaders(getState().user),
      },
    })
    const interestData = data.interest
    const me = getState().user.username
    return dispatch(removeInterestResponse({ interest, interestData, me }))
  } catch (error) {
    console.log('error', error)
    return dispatch(removeInterestResponse(error))
  }
}

export const signInUser = payload => async (
  dispatch,
  getState,
  { v2: Api }
) => {
  dispatch(signInUserError())
  try {
    const { data } = await Api.axios({
      method: 'POST',
      url: '/sign-in',
      data: qs.stringify(payload),
    })
    dispatch(signInUserSuccess({ ...data, username: payload.username }))
    dispatch(fetchUser())
    return data
  } catch (error) {
    return dispatch(
      signInUserError({
        error_message: error.response.data.error_message,
      })
    )
  }
}

export const fetchUser = () => async (dispatch, getState, { v2: Api }) => {
  dispatch(fetchUserRequest())

  try {
    const { data } = await Api.axios({
      url: '/api/user',
      method: 'GET',
      headers: {
        ...Api.getAuthHeaders(getState().user),
      },
    })

    return dispatch(fetchUserResponse(data))
  } catch (error) {
    return dispatch(fetchUserResponse(error))
  }
}

export const updateUser = (
  payload,
  cb = true,
  postUpdateKeys = ['id']
) => async (dispatch, getState, { v2: Api }) => {
  dispatch(fetchUserRequest())
  try {
    const updateResp = await Api.axios({
      url: '/api/update',
      method: 'POST',
      data: qs.stringify(payload),
      headers: {
        ...Api.getAuthHeaders(getState().user),
      },
    })

    /**
     * Manually fetch user details and other info, but let's exclude the ID so we don't log them in.
     */
    const { data } = await Api.axios({
      url: '/api/user',
      method: 'GET',
      headers: {
        ...Api.getAuthHeaders(getState().user),
      },
    })

    /**
     * Semi hacky way to delete
     * keys on certain update flows so they don't get persisted back to the store.
     * Mainly used in signup to avoid the default photo from rolling in the next screen.
     */

    postUpdateKeys.forEach(key => delete data.user[key])
    dispatch(fetchUserResponse(data))

    if (cb) {
      dispatch(fetchUserUpdateResponse(payload))
      dispatch(fetchUser())
    }
    if (updateResp.data.error_message)
      dispatch(
        updateUserError({ error_message: updateResp.data.error_message })
      )

    return updateResp.data
  } catch (error) {
    return dispatch(fetchUserResponse(error))
  }
}

export const updateUserPhoto = (payload, cb = false) => async (
  dispatch,
  getState,
  { v2: Api }
) => {
  dispatch(fetchUserRequest())

  try {
    await Api.axios({
      url: '/api/update',
      method: 'POST',
      data: payload,
      headers: {
        'Content-Type': 'multipart/form-data',
        ...Api.getAuthHeaders(getState().user),
      },
    })

    /**
     * Manually fetch user details and other info, but let's exclude the ID so we don't log them in.
     */
    const { data } = await Api.axios({
      url: '/api/user',
      method: 'GET',
      headers: {
        ...Api.getAuthHeaders(getState().user),
      },
    })

    delete data.user.id
    dispatch(fetchUserResponse(data))
  } catch (error) {
    return dispatch(fetchUserResponse(error))
  }
}

export const signUp = payload => async (dispatch, getState, { v2: Api }) => {
  dispatch(fetchUserRequest())

  try {
    const { data } = await Api.axios({
      url: '/sign-up',
      method: 'POST',
      data: qs.stringify(payload),
      headers: {
        ...Api.getAuthHeaders(getState().user),
      },
    })
    dispatch(signUpUserSuccess({ ...data, username: payload.username }))
    return data
  } catch (error) {
    return dispatch(
      signUpUserError({
        error_message: error.response.data.error_message,
      })
    )
  }
}

export const changePassword = payload => async (
  dispatch,
  getState,
  { v2: Api }
) => {
  dispatch(fetchUserRequest())

  try {
    const { data } = await Api.axios({
      url: `/api/change_password`,
      method: 'POST',
      data: qs.stringify(payload),
      headers: {
        ...Api.getAuthHeaders(getState().user),
      },
    })

    if (data.success !== 1) {
      return dispatch(
        changePasswordError({ error_message: data.error_message })
      )
    }

    dispatch(changePasswordSuccess(data))
    return dispatch(goBack())
  } catch (error) {
    return dispatch(
      changePasswordError({
        error_message: error,
      })
    )
  }
}

export const deleteUser = payload => async (
  dispatch,
  getState,
  { v2: Api }
) => {
  try {
    const { data } = await Api.axios({
      url: '/api/delete_user',
      method: 'POST',
      data: qs.stringify(payload),
      headers: {
        ...Api.getAuthHeaders(getState().user),
      },
    })

    return dispatch(deleteUserResponse(data))
  } catch (error) {
    return dispatch(deleteUserResponse(error))
  }
}

export const recoverPassViaEmail = payload => async (
  dispatch,
  getState,
  { v2: Api }
) => {
  try {
    const { data } = await Api.axios({
      url: '/reset-password-email',
      method: 'POST',
      data: qs.stringify(payload),
    })
    dispatch(recoverPassViaEmailResponse(data))
    return data
  } catch (error) {
    console.log(error)
    return dispatch(recoverPassViaEmailResponse(error))
  }
}

export const recoverPassViaNumber = payload => async (
  dispatch,
  getState,
  { v2: Api }
) => {
  try {
    const { data } = await Api.axios({
      url: '/reset-password-sms',
      method: 'POST',
      data: qs.stringify(payload),
    })
    dispatch(recoverPassViaSmsResponse(data))
    return data
  } catch (error) {
    return dispatch(recoverPassViaSmsResponse(error))
  }
}

export const resetPassword = payload => async (
  dispatch,
  getState,
  { v2: Api }
) => {
  try {
    const { data } = await Api.axios({
      url: '/reset-password',
      method: 'POST',
      data: qs.stringify(payload),
    })
    if (data.success === 1) {
      dispatch(resetPasswordSuccess({ ...data, username: payload.username }))
      dispatch(fetchUser())
    }
    return data
  } catch (error) {
    return dispatch(resetPasswordError(error))
  }
}

export const getEarnedUens = () => async (dispatch, getState, { v2: Api }) => {
  try {
    const { data } = await Api.axios({
      url: '/api/earned_uens',
      method: 'GET',
      headers: {
        ...Api.getAuthHeaders(getState().user),
      },
    })
    return dispatch(getEarnedUensSuccess(data))
  } catch (error) {
    return dispatch(getEarnedUensSuccess(error))
  }
}

export const getPendingPayment = () => async (
  dispatch,
  getState,
  { v2: Api }
) => {
  try {
    const { data } = await Api.axios({
      url: '/api/queued_distributions',
      method: 'GET',
      headers: {
        ...Api.getAuthHeaders(getState().user),
      },
    })
    return dispatch(getPendingPaymentSuccess(data))
  } catch (error) {
    return dispatch(getPendingPaymentSuccess(error))
  }
}

export const queueDistribution = () => async (
  dispatch,
  getState,
  { v2: Api }
) => {
  try {
    const { data } = await Api.axios({
      url: '/api/queue_distribution',
      method: 'POST',
      data: qs.stringify({}),
      headers: {
        ...Api.getAuthHeaders(getState().user),
      },
    })
    return data
  } catch (error) {
    return dispatch(queueDistributionResponse(error))
  }
}

export const getPaymentHistory = () => async (
  dispatch,
  getState,
  { v2: Api }
) => {
  try {
    const { data } = await Api.axios({
      url: '/api/payment_history',
      method: 'GET',
      headers: {
        ...Api.getAuthHeaders(getState().user),
      },
    })
    return dispatch(getPaymentHistorySuccess(data))
  } catch (error) {
    return dispatch(getPaymentHistorySuccess(error))
  }
}

export const getCallHistory = () => async (dispatch, getState, { v2: Api }) => {
  try {
    const { data } = await Api.axios({
      url: '/api/completed_calls',
      method: 'GET',
      headers: {
        ...Api.getAuthHeaders(getState().user),
      },
    })
    return dispatch(getCallHistorySuccess(data))
  } catch (error) {
    return dispatch(getCallHistorySuccess(error))
  }
}

export const getFinancialInfo = () => async (
  dispatch,
  getState,
  { v2: Api }
) => {
  try {
    const { data } = await Api.axios({
      url: '/api/get_braintree_submerchant',
      method: 'GET',
      headers: {
        ...Api.getAuthHeaders(getState().user),
      },
    })
    dispatch(getFinancialInfoResponse(data))
  } catch (error) {
    return dispatch(getFinancialInfoResponse(error))
  }
}

export const updateFinancialInfo = payload => async (
  dispatch,
  getState,
  { v2: Api }
) => {
  try {
    const { data } = await Api.axios({
      url: '/api/update_braintree_submerchant',
      method: 'POST',
      data: qs.stringify(payload),
      headers: {
        ...Api.getAuthHeaders(getState().user),
      },
    })
    return dispatch(updateFinancialInfoResponse(data))
  } catch (error) {
    return dispatch(updateFinancialInfoResponse(error))
  }
}

export const createSubmerchant = payload => async (
  dispatch,
  getState,
  { v2: Api }
) => {
  try {
    const { data } = await Api.axios({
      url: '/api/create_braintree_submerchant',
      method: 'POST',
      data: qs.stringify(payload),
      headers: {
        ...Api.getAuthHeaders(getState().user),
      },
    })
    return dispatch(createSubmerchantResponse(data))
  } catch (error) {
    return dispatch(createSubmerchantResponse(error))
  }
}

export const validateBankAccount = bankAccountNumber => async (
  dispatch,
  getState,
  { v2: Api }
) => {
  try {
    const { data } = await Api.axios({
      url: `/banking/validate/account?number=${bankAccountNumber}`,
      method: 'GET',
      headers: {
        ...Api.getAuthHeaders(getState().user),
      },
    })
    return data
  } catch (error) {
    return error
  }
}

export const validateRoutingNumber = routingNumber => async (
  dispatch,
  getState,
  { v2: Api }
) => {
  try {
    const { data } = await Api.axios({
      url: `/banking/validate/routing?number=${routingNumber}`,
      method: 'GET',
      headers: {
        ...Api.getAuthHeaders(getState().user),
      },
    })
    return data
  } catch (error) {
    return error
  }
}

export const getCashoutMessage = () => async (
  dispatch,
  getState,
  { v2: Api }
) => {
  try {
    const { data } = await Api.axios({
      url: '/api/distribution_info',
      method: 'GET',
      headers: {
        ...Api.getAuthHeaders(getState().user),
      },
    })
    return dispatch(getCashoutMessageResponse(data))
  } catch (error) {
    return error
  }
}

export const notificationTime = () => async (
  dispatch,
  getState,
  { v2: Api }
) => {
  try {
    const { data } = await Api.axios({
      url: '/api/time',
      method: 'GET',
      headers: {
        ...Api.getAuthHeaders(getState().user),
      },
    })
    dispatch(notificationTimeResponse(data.time))
    return data
  } catch (error) {
    return dispatch(notificationTimeResponse(error))
  }
}

export const refreshToken = () => async (dispatch, getState, { v2: Api }) => {
  try {
    const { data } = await Api.axios({
      url: '/refresh',
      method: 'GET',
      headers: {
        ...Api.getAuthHeaders(getState().user),
      },
    })
    dispatch(signInUserSuccess({ ...data }))
    return dispatch(fetchUser())
  } catch (error) {
    return dispatch(
      signInUserError({
        error_message: error.response.data.error_message,
      })
    )
  }
}

export const canCall = payload => async (dispatch, getState, { v2: Api }) => {
  try {
    const { data } = await Api.axios({
      url: '/api/can_call',
      method: 'POST',
      data: qs.stringify(payload),
      headers: {
        ...Api.getAuthHeaders(getState().user),
      },
    })
    dispatch(canCallUserResponse(data))
    return data
  } catch (error) {
    return dispatch(canCallUserError(error))
  }
}

export const setAppDetails = payload => async (
  dispatch,
  getState,
  { v2: Api }
) => {
  try {
    const { data } = await Api.axios({
      url: '/api/set_web_details',
      method: 'POST',
      data: qs.stringify(payload),
      headers: {
        ...Api.getAuthHeaders(getState().user),
      },
    })
    return data
  } catch (error) {
    return error
  }
}

// User Reducer
// -----------------------------------------------------------------------------

// Default State
const defaultState = {}

// Reducer
export default handleActions(
  {
    [LOCATION_CHANGE]: {
      next: state => {
        return {
          ...state,
          error_message: null,
          isLoading: false,
        }
      },
    },
    [signInUserSuccess]: {
      next: (state, { payload }) => {
        Session.saveToken(payload.token)
        return {
          isLoading: true,
          ...state,
          ...payload,
        }
      },
    },
    [signInUserError]: {
      next: (_state, { payload }) => {
        return {
          isLoading: false,
          ...payload,
        }
      },
    },
    [signUpUserSuccess]: {
      next: (state, { payload }) => {
        Session.saveToken(payload.token)
        return {
          ...state,
          ...payload,
          isLoading: false,
          error_message: null,
        }
      },
    },
    [signUpUserError]: {
      next: (_state, { payload }) => {
        return {
          isLoading: false,
          ...payload,
        }
      },
    },
    [fetchUserRequest]: {
      next: state => ({
        isLoading: true,
        ...state,
      }),
    },
    [fetchUserResponse]: {
      next: (state, { payload }) => {
        return {
          isLoading: false,
          ...state,
          ...payload.user,
        }
      },
    },
    [fetchUserInterestsResponse]: {
      next: (state, { payload }) => {
        return {
          isLoading: false,
          ...state,
          interests: payload.interests,
        }
      },
    },
    [reportUserResponse]: {
      next: (state, { payload }) => {
        return {
          isLoading: false,
          ...state,
        }
      },
    },
    [blockUserResponse]: {
      next: (state, { payload }) => {
        return {
          isLoading: false,
          ...state,
        }
      },
    },
    [unblockUserResponse]: {
      next: (state, { payload }) => {
        return {
          isLoading: false,
          ...state,
        }
      },
    },
    [addInterestResponse]: {
      next: (state, { payload }) => {
        if (state.interests) {
          state.interests = state.interests.concat([payload.interestData])
        } else {
          state.interests = [payload.interestData]
        }
        return {
          isLoading: false,
          ...state,
        }
      },
    },
    [removeInterestResponse]: {
      next: (state, { payload }) => {
        const index = state.interests.findIndex(
          item => item.interest === payload.interest
        )
        state.interests.splice(index, 1)
        return {
          isLoading: false,
          ...state,
        }
      },
    },
    [signOutUser]: {
      next: (_state, { payload }) => {
        Session.removeToken()
        // https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store/35641992#35641992
        _state = undefined
        return {
          _state,
        }
      },
    },
    [changePasswordSuccess]: {
      next: (state, { payload }) => {
        if (payload.error_message) state.error_message = payload.error_message
        return {
          isLoading: false,
          ...payload,
          ...state,
        }
      },
    },
    [changePasswordError]: {
      next: (state, { payload }) => {
        return {
          isLoading: false,
          ...state,
          ...payload,
        }
      },
    },
    [updateUserError]: {
      next: (state, { payload }) => {
        return {
          isLoading: false,
          ...state,
          ...payload,
        }
      },
    },
    [deleteUserResponse]: {
      next: (state, { payload }) => {
        return {
          isLoading: false,
          ...state,
          ...payload,
        }
      },
    },
    [recoverPassViaEmailResponse]: {
      next: (state, { payload }) => {
        return {
          ...state,
          ...payload,
        }
      },
    },
    [recoverPassViaSmsResponse]: {
      next: (state, { payload }) => {
        return {
          ...state,
          ...payload,
        }
      },
    },
    [resetPasswordSuccess]: {
      next: (state, { payload }) => {
        Session.saveToken(payload.token)
        return {
          ...state,
          ...payload,
        }
      },
    },
    [resetPasswordError]: {
      next: (_state, { payload }) => {
        return {
          ...payload,
        }
      },
    },
    [getEarnedUensSuccess]: {
      next: (state, { payload }) => {
        return {
          ...state,
          earned_uens: payload.uens,
        }
      },
    },
    [getPendingPaymentSuccess]: {
      next: (state, { payload }) => {
        return {
          ...state,
          pendingPayment: payload.queued_distributions,
        }
      },
    },
    [getPaymentHistorySuccess]: {
      next: (state, { payload }) => {
        return {
          ...state,
          paymentHistory: payload.payments,
        }
      },
    },
    [getCallHistorySuccess]: {
      next: (state, { payload }) => {
        return {
          ...state,
          callHistory: payload.calls,
        }
      },
    },
    [getFinancialInfoResponse]: {
      next: (state, { payload }) => {
        return {
          ...state,
          financialInfo: payload.submerchant,
          submerchant_exits: payload.success === 1 ? true : false,
        }
      },
    },
    [updateFinancialInfoResponse]: {
      next: (state, { payload }) => {
        return {
          ...state,
          ...payload,
        }
      },
    },
    [createSubmerchantResponse]: {
      next: (state, { payload }) => {
        return {
          ...state,
          ...payload,
        }
      },
    },
    [getCashoutMessageResponse]: {
      next: (state, { payload }) => {
        return {
          ...state,
          cashoutMessage: payload.message,
        }
      },
    },
    [notificationTimeResponse]: {
      next: (state, { payload }) => {
        return {
          ...state,
          notificationTimeMiliseconds: payload,
        }
      },
    },
  },
  defaultState
)
