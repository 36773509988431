import React, { Component } from 'react'
import GenderFilter from 'components/GenderFilter'
import InterestedButton from 'components/InterestedButton'

class InterestedUsersNavBar extends Component {
  onBackClick = e => {
    e.preventDefault()
    this.props.onBackClick && this.props.onBackClick()
  }

  onActionClick = e => {
    e.preventDefault()
    this.props.onActionClick && this.props.onActionClick()
  }

  onTitleClick = e => {
    e.preventDefault()
    this.props.onTitleClick && this.props.onTitleClick()
  }

  isMutualInterest = () => {
    if (!this.props.interest) return false
    const {
      interest: { mutual_interest },
    } = this.props

    return mutual_interest === '1'
  }

  toggleInterest = e => {
    e.preventDefault()
    if (!this.props.interest) return
    const {
      interest: { interest },
      onToggleInterest,
    } = this.props

    onToggleInterest(interest, this.isMutualInterest())
  }

  render() {
    const { onGenderFilterToggle, user } = this.props
    const interested = this.isMutualInterest()
    return (
      <div style={{ background: '#222', padding: '10px' }}>
        <div className="row">
          <div className="col-xs-4">
            <div onClick={this.onBackClick} style={{ textAlign: 'left' }}>
              <i style={{ color: 'white' }} className="fa fa-angle-left" />
            </div>
          </div>
          <div className="col-xs-4">
            <div onClick={this.onTitleClick} style={{ textAlign: 'center' }}>
              <b
                style={{
                  color: 'white',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {this.props.title}
              </b>
            </div>
          </div>

          <div
            style={{
              float: 'right',
              color: '#FFF',
              height: '20px',
              margin: '0 15px 0 auto',
            }}
          >
            <InterestedButton
              toggle={this.toggleInterest}
              interested={interested}
            />
          </div>
          <div
            style={{
              float: 'right',
              height: '20px',
              width: '50px',
              margin: '0 15px 0 auto',
            }}
          >
            {onGenderFilterToggle && user && <GenderFilter {...this.props} />}
          </div>
        </div>
      </div>
    )
  }
}

export default InterestedUsersNavBar
