import React from 'react'
import { dateToString } from '../../routes/Chat/Date'

const PendingPayment = props => {
  const { pendingPayment } = props

  return (
    <div
      style={{
        textAlign: 'center',
        borderRadius: '7px',
        color: 'white',
        backgroundColor: 'white',
      }}
    >
      <h3
        style={{
          padding: '10px 0',
          marginBottom: '0',
          backgroundColor: '#01a3d0',
          borderTopLeftRadius: '7px',
          borderTopRightRadius: '7px',
        }}
      >
        Pending Payments
      </h3>

      {pendingPayment.map((payment, index) => {
        return (
          <div key={index}>
            <div
              style={{
                display: 'flex',
                backgroundColor: 'white',
                borderBottomLeftRadius: '7px',
                borderBottomRightRadius: '7px',
                padding: '10px 0',
              }}
            >
              <div
                className="container-fluid"
                style={{ textAlign: 'left', width: '70%' }}
              >
                <h4 style={{ color: 'black', textTransform: 'capitalize' }}>
                  <strong>{payment.status}</strong>
                </h4>
                <p style={{ color: '#b8b8b8' }}>
                  Submitted on {dateToString(payment.requested_date_time)}
                </p>
              </div>
              <div
                style={{
                  width: '30%',
                  textAlign: 'right',
                  paddingRight: '15px',
                  display: 'inline-table',
                }}
              >
                <h2 style={{ color: '#01a3d0' }}>
                  ${(payment.uens * 0.25).toFixed(2)}
                </h2>
              </div>
            </div>
            {pendingPayment.length !== index + 1 ? (
              <hr style={{ margin: '0' }} />
            ) : null}
          </div>
        )
      })}
    </div>
  )
}

export default PendingPayment
