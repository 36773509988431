export const countries = [
  '',
  'United States',
  'Argentina',
  'Austria',
  'Australia',
  'Bolivia',
  'Brazil',
  'Canada',
  'Chile',
  'Columbia',
  'Ecuador',
  'Finland',
  'France',
  'Germany',
  'Guatemala',
  'Iceland',
  'Ireland',
  'Italy',
  'Mexico',
  'New Zealand',
  'Nicaragua',
  'Norway',
  'Paraguay',
  'Peru',
  'Scotland',
  'Spain',
  'Sweden',
  'Uruguay',
  'United Kingdom',
  'Venezuela',
  'Other',
]
