import React, { Component } from 'react'
import { dateTimeNow, timeSince, secondsToTime } from '../../routes/Chat/Date'
// @ts-ignore
import arrow from '../../assets/icons/arrow.png'
const origin = process.env.REACT_APP_ORIGIN

class TheyCompletedCall extends Component {
  render() {
    /** @type {{to: string, from: string, message?: string, alert?: string, action?: string, cost_per_min?: number, total_call_cost?: number, message_date_time?: string, call_length?:number, environment?: string, media_url?: string, filename?: string, userAvatarFileName?: string, username?: string}} */
    const message = this.props.message
    const self = this.props.self
    const peer = this.props.peer
    return (
      <div
        style={{
          background: '#F6F6F6',
          borderRadius: '8px',
          margin: '20px 10px 10px 10px',
        }}
      >
        <div style={{ textAlign: 'center' }}>
          <img
            width="40"
            style={{
              borderRadius: '50%',
              marginTop: '-12px',
              border: '6px solid #F6F6F6',
            }}
            src={`${origin}/uploads/${peer.thumb}`}
            alt="user"
          />
          <img width="40" src={arrow} alt="user" />
          <img
            width="40"
            style={{
              borderRadius: '50%',
              marginTop: '-12px',
              border: '6px solid #F6F6F6',
            }}
            src={`${origin}/uploads/${self.thumb}`}
            alt="user"
          />
        </div>
        <div style={{ textAlign: 'center' }}>Completed Call</div>

        <div
          style={{
            fontSize: '12px',
            color: '#c2c2a3',
            textAlign: 'center',
          }}
        >
          {timeSince(message.message_date_time || dateTimeNow())}
        </div>

        {message.cost_per_min !== 0 ? (
          <div
            style={{
              fontSize: '12px',
              paddingBottom: '15px',
              paddingLeft: '15px',
              paddingTop: '5px',
              float: 'left',
            }}
          >
            {Math.abs(message.cost_per_min)} uen
            {Math.abs(message.cost_per_min) !== 1 ? `s` : ``}/min (
            {secondsToTime(message.call_length)})
          </div>
        ) : (
          <div
            style={{
              fontSize: '12px',
              paddingBottom: '15px',
              paddingLeft: '15px',
              paddingTop: '5px',
              float: 'left',
            }}
          >
            Chatted for free ({secondsToTime(message.call_length)})
          </div>
        )}
        {message.total_call_cost !== 0 ? (
          <div
            style={{
              fontSize: '12px',
              paddingBottom: '15px',
              paddingRight: '15px',
              paddingTop: '5px',
              float: 'right',
            }}
          >
            You {message.total_call_cost > 0 ? `earned` : `paid`}{' '}
            {Math.abs(message.total_call_cost)} uen
            {Math.abs(message.total_call_cost) !== 1 ? `s` : ``}
          </div>
        ) : (
          <div
            style={{
              fontSize: '12px',
              paddingBottom: '15px',
              paddingRight: '15px',
              paddingTop: '5px',
              float: 'right',
            }}
          >
            Free
          </div>
        )}
        <div style={{ clear: 'both' }}></div>
      </div>
    )
  }
}

export default TheyCompletedCall
