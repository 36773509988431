import Api from 'api'

const signalingOrigin = process.env.REACT_APP_SIGNALING_ORIGIN
const environment = process.env.REACT_APP_CHAT_ENVIRONMENT
let peerConnection
let heartbeatInterval
let disconnected = false
let totalCallCostRounded = 0
let callLength = 0
let user
let peer
let room
let onBailedCallback = str => console.log(str)
let onDeclinedCallback = str => console.log(str)
let onHangupCallback = str => console.log(str)
let onCallStateCallback = str => console.log(str)
let onStartCallback = str => console.log(str)
let getUserMediaAttempts = 5
let gettingUserMedia = false
let localVideo
let remoteVideos
let evtSource
let candidates = []

/** @type {RTCConfiguration} */
const config = {
  iceServers: [
    {
      urls: ['turn:relay.uenme.co:3478'],
      username: 'ninefingers',
      credential: 'youhavetoberealistic',
    },
    {
      urls: ['stun:stun.l.google.com:19302'],
    },
  ],
}

/** @type {MediaStreamConstraints} */
const constraints = {
  audio: true,
  video: { facingMode: 'user' },
}

function message(message) {
  fetch(`${signalingOrigin}/ctos/${room}/${user}/${peer}`, {
    headers: { 'Content-Type': 'text/plain' },
    body: JSON.stringify(message),
    method: 'POST',
  })
}

export function call(callInfo) {
  user = callInfo.user
  peer = callInfo.peer
  const cost = callInfo.cost
  const caller = callInfo.isCaller ? user : peer
  const callee = callInfo.isCaller ? peer : user
  room = `@${caller}${callee}`

  localVideo = document.querySelector('.localVideo')
  remoteVideos = document.querySelector('.remoteVideos')

  getUserMediaAttempts = 5
  gettingUserMedia = false

  function roomIsFull(evtSource, data, id) {
    alert('Room ' + id + ' is full')
  }

  function declinedCall(evtSource, data, id) {
    onDeclinedCallback()
  }

  function leftRoom(evtSource, data, id) {
    handleRemoteHangup()
  }

  function roomCount(evtSource, count, id) {
    count = Number(count)
    if (callLength > 0) {
      if (count !== 2) {
        console.log(
          "Rejoined but I'm the only person in the room now. Bail. (Does the server treat this as a missed call?)"
        )
        noConnectionSoBail()
      }
    }
    if (callInfo.isCaller) {
      if (count !== 1) {
        console.log(`${count} people are in this chat.`)
        noConnectionSoBail()
      }
    } else {
      if (count !== 2) {
        console.log(`${count} people are in this chat.`)
        noConnectionSoBail()
      }
    }
  }

  function receivedCallState(evtSource, data, id) {
    // console.log("call state received");
  }

  function receivedCallStateMessage(evtSource, data, id) {
    // console.log("call state received");
  }

  function finalCost(evtSource, data, id) {
    totalCallCostRounded = Math.round(Number(data))
    if (!disconnected) {
      disconnect(() => {
        onHangupCallback(totalCallCostRounded)
      })
    }
  }

  function receivedOrientation(evtSource, data, id) {
    console.log('received orientation')
  }

  function receivedCost(evtSource, data, id) {
    console.log('received cost')
  }

  function receivedRequestToLeave(evtSource, data, id) {
    console.log('received request to leave')
  }

  function receivedScreenshotNotification(evtSource, data, id) {
    console.log('received screenshot')
  }

  function receivedScreenrecordNotification(evtSource, data, id) {
    console.log('received screenrecord')
  }

  function receivedCallStats(evtSource, data, id) {
    // console.log("received callstats");
  }

  function joinedRoom(evtSource, data, id) {
    let alreadyAddedRemoteStream = false
    if (callInfo.isCaller) {
      message({ cost_per_min: `${cost}` })
    }
    if (!(localVideo instanceof HTMLVideoElement) || !localVideo.srcObject) {
      return
    }
    peerConnection = new RTCPeerConnection(config)
    if (localVideo instanceof HTMLVideoElement) {
      if (localVideo.srcObject instanceof MediaStream) {
        let stream = localVideo.srcObject
        stream
          .getTracks()
          .forEach(track => peerConnection.addTrack(track, stream))
      }
    }
    if (callInfo.isCaller) {
      peerConnection
        .createOffer()
        .then(sdp =>
          peerConnection.setLocalDescription(new RTCSessionDescription(sdp))
        )
        .then(function() {
          message(peerConnection.localDescription)
        })
    }
    peerConnection.ontrack = event => {
      if (event && event.streams && event.streams[0]) {
        let stream = event.streams[0]
        if (
          stream.getVideoTracks().length > 0 &&
          stream.getAudioTracks().length > 0 &&
          !alreadyAddedRemoteStream
        ) {
          alreadyAddedRemoteStream = true
          handleRemoteStreamAdded(stream, id)
        }
      }
    }
    peerConnection.onicecandidate = function(event) {
      if (event.candidate) {
        message(event.candidate)
      }
    }
  }

  function receivedOffer(evtSource, peerUser, description) {
    let alreadyAddedRemoteStream = false
    peerConnection = new RTCPeerConnection(config)
    if (localVideo instanceof HTMLVideoElement) {
      if (localVideo.srcObject instanceof MediaStream) {
        let stream = localVideo.srcObject
        stream
          .getTracks()
          .forEach(track => peerConnection.addTrack(track, stream))
      }
    }
    peerConnection
      .setRemoteDescription(new RTCSessionDescription(description))
      .then(() => peerConnection.createAnswer())
      .then(sdp =>
        peerConnection.setLocalDescription(new RTCSessionDescription(sdp))
      )
      .then(function() {
        message(peerConnection.localDescription)
      })
    peerConnection.ontrack = event => {
      if (event && event.streams && event.streams[0]) {
        let stream = event.streams[0]
        if (
          stream.getVideoTracks().length > 0 &&
          stream.getAudioTracks().length > 0 &&
          !alreadyAddedRemoteStream
        ) {
          alreadyAddedRemoteStream = true
          handleRemoteStreamAdded(stream, room)
        }
      }
    }
    peerConnection.onicecandidate = function(event) {
      if (event.candidate) {
        message(event.candidate)
      }
    }
  }

  function receivedCandidate(evtSource, peerUser, candidate) {
    if (peerConnection.remoteDescription) {
      candidates.forEach(candidate => {
        peerConnection.addIceCandidate(candidate).catch(e => console.log(e))
      })
      peerConnection
        .addIceCandidate(
          new RTCIceCandidate({
            candidate: candidate.candidate,
            sdpMLineIndex: candidate.sdpMLineIndex || candidate.label,
            sdpMid: candidate.sdpMid,
          })
        )
        .catch(e => console.log(e))
      return
    }
    candidates.push(
      new RTCIceCandidate({
        candidate: candidate.candidate,
        sdpMLineIndex: candidate.sdpMLineIndex || candidate.label,
        sdpMid: candidate.sdpMid,
      })
    )
  }

  function receivedAnswer(evtSource, peerUser, description) {
    peerConnection.setRemoteDescription(new RTCSessionDescription(description))
  }

  function getUserMediaSuccess(stream) {
    gettingUserMedia = false
    if (localVideo instanceof HTMLVideoElement) {
      !localVideo.srcObject && (localVideo.srcObject = stream)
    }
    evtSource && evtSource.close()
    evtSource = new EventSource(
      `${signalingOrigin}/stoc/${room}/${user}/${peer}/${cost}`
    )
    evtSource.onerror = function() {
      console.log('EventSource failed.')
    }
    evtSource.onopen = function() {
      if (callInfo.isCaller) {
        Api.post(
          '/api/notify_called',
          {
            environment: environment,
            called_username: `${callee}`,
            cost_per_min: `${cost}`,
          },
          (error, result) => {
            console.log(error, result)
          }
        )
      }
    }

    window.onunload = window.onbeforeunload = function() {
      evtSource && evtSource.close()
    }

    evtSource.addEventListener('join', function(/** @type {MessageEvent} */ e) {
      console.log('join', e.data)
      joinedRoom(evtSource, e.data, room)
    })

    evtSource.addEventListener('leave', function(
      /** @type {MessageEvent} */ e
    ) {
      console.log('leave', e.data)
      leftRoom(evtSource, e.data, room)
    })

    evtSource.addEventListener('sessionfull', function(
      /** @type {MessageEvent} */ e
    ) {
      console.log('sessionfull', e.data)
      roomIsFull(evtSource, e.data, room)
    })

    evtSource.addEventListener('declined', function(
      /** @type {MessageEvent} */ e
    ) {
      console.log('declined', e.data)
      declinedCall(evtSource, e.data, room)
    })

    evtSource.addEventListener('roomCount', function(
      /** @type {MessageEvent} */ e
    ) {
      console.log('roomCount', e.data)
      roomCount(evtSource, e.data, room)
    })

    evtSource.addEventListener('callState', function(
      /** @type {MessageEvent} */ e
    ) {
      let callState = {}
      try {
        callState = JSON.parse(e.data)
      } catch (error) {
        console.log(error)
      }
      onCallStateCallback(callState)
      onStartCallback && onStartCallback()
      onStartCallback = str => console.log(str)
      receivedCallState(evtSource, e.data, room)
    })

    evtSource.addEventListener('callStateMessage', function(
      /** @type {MessageEvent} */ e
    ) {
      // console.log("callStateMessage", e.data);
      receivedCallStateMessage(evtSource, e.data, room)
    })

    evtSource.addEventListener('keepalive', function(
      /** @type {MessageEvent} */ e
    ) {
      // console.log("keepalive", e.data);
    })

    evtSource.addEventListener('finalCost', function(
      /** @type {MessageEvent} */ e
    ) {
      console.log('finalCost', e.data)
      finalCost(evtSource, e.data, room)
    })

    evtSource.addEventListener(`user-${peer}`, function(
      /** @type {MessageEvent} */ e
    ) {
      let data = e.data
      let json = JSON.parse(data)

      if (json.sdp) {
        if (json.type === 'offer') {
          receivedOffer(evtSource, peer, json)
        } else {
          receivedAnswer(evtSource, peer, json)
        }
      } else if (json.candidate) {
        receivedCandidate(evtSource, peer, json)
      } else if (json.orientation) {
        receivedOrientation(evtSource, peer, json.orientation)
      } else if (json.cost_per_min) {
        receivedCost(evtSource, peer, json.cost_per_min)
      } else if (json.request_to_leave) {
        receivedRequestToLeave(evtSource, peer, json.request_to_leave)
      } else if (json.screenshot) {
        receivedScreenshotNotification(evtSource, peer, json.screenshot)
      } else if (json.screenrecord) {
        receivedScreenrecordNotification(evtSource, peer, json.screenrecord)
      } else if (json.callStats) {
        receivedCallStats(evtSource, peer, json.callStats)
      } else if (json.total_cost) {
        // console.log("total_cost (DEPRECATED):", json.total_cost);
      } else {
        console.log(evtSource, peer, data)
      }
    })
  }

  function handleRemoteStreamAdded(stream, id) {
    const remoteVideo = document.createElement('video')
    remoteVideo.srcObject = stream
    remoteVideo.setAttribute('id', id.replace(/[^a-zA-Z]+/g, '').toLowerCase())
    remoteVideo.setAttribute('playsinline', 'true')
    remoteVideo.setAttribute('autoplay', 'true')
    remoteVideos.appendChild(remoteVideo)
    heartbeatInterval = setInterval(() => {
      message({ keepalive: 'true' })
      // message({callStats})
    }, 1000)
  }

  function getUserMediaError(error) {
    console.error(error)
    gettingUserMedia = false
    --getUserMediaAttempts > 0 && setTimeout(getUserMediaDevices, 1000)
  }

  function getUserMediaDevices() {
    if (localVideo instanceof HTMLVideoElement) {
      if (localVideo.srcObject) {
        getUserMediaSuccess(localVideo.srcObject)
      } else if (!gettingUserMedia && !localVideo.srcObject) {
        gettingUserMedia = true
        navigator.mediaDevices
          .getUserMedia(constraints)
          .then(getUserMediaSuccess)
          .catch(getUserMediaError)
      }
    }
  }

  function handleRemoteHangup() {
    // disconnect()
  }

  function noConnectionSoBail() {
    if (!disconnected) {
      disconnect(() => {
        onBailedCallback()
      })
    }
  }

  function disconnect(cb) {
    disconnected = true
    peerConnection && peerConnection.close()
    peerConnection = null
    clearInterval(heartbeatInterval)
    if (localVideo instanceof HTMLVideoElement) {
      if (localVideo.srcObject instanceof MediaStream) {
        const stream = localVideo.srcObject
        stream.getTracks().forEach(track => track.stop())
      }
    }
    console.log('closing evtSource')
    evtSource && evtSource.close()
    evtSource = null
    const video = document.querySelector(
      '#' + room.replace(/[^a-zA-Z]+/g, '').toLowerCase()
    )
    video && video.remove()
    cb && cb()
  }

  getUserMediaDevices()
}

export function hangup() {
  message({ request_to_leave: `${totalCallCostRounded}` })
}

export function onhangup(cb) {
  onHangupCallback = cb
}

export function ondeclined(cb) {
  onDeclinedCallback = cb
}

export function onbailed(cb) {
  onBailedCallback = cb
}

export function onstart(cb) {
  onStartCallback = cb
}

export function onCallState(cb) {
  onCallStateCallback = cb
}
