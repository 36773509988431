import React, { Component } from 'react';
import Api from 'api';
class Loader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }
  componentDidMount() {
    Api.subscribe(count => {
      this.setState({ loading: count > 0 });
    });
  }
  render() {
    return this.state.loading ? (
      <div className="spinner-container">
        <div className="spinner" />
      </div>
    ) : (
      ''
    );
  }
}

export default Loader;
