import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as usersActions from 'reducers/users'

class TabBar extends Component {
  constructor(props) {
    super(props)
    ;['search', 'favorites', 'messages', 'account'].forEach(item => {
      if (props.location.pathname.indexOf(item) === 1) {
        window.localStorage.navigationSelected = item
      }
    })
    let selected = window.localStorage.navigationSelected || 'search'
    this.state = { selected }
  }

  componentWillReceiveProps() {
    this.setState({ redirect: null })
  }

  componentDidMount() {
    this.unreadMessageCount()
  }

  unreadMessageCount = () => {
    const {
      actions: { unreadMessageCount },
    } = this.props
    unreadMessageCount()
  }

  onClickSearch = e => {
    window.localStorage.navigationSelected = 'search'
    this.setState({
      selected: 'search',
      redirect: `/search`,
    })
  }
  onClickFavorites = e => {
    window.localStorage.navigationSelected = 'favorites'
    this.setState({
      selected: 'favorites',
      redirect: `/favorites`,
    })
  }
  onClickMessages = e => {
    window.localStorage.navigationSelected = 'messages'
    this.setState({
      selected: 'messages',
      redirect: `/messages`,
    })
  }
  onClickAccount = e => {
    window.localStorage.navigationSelected = 'account'
    this.setState({
      selected: 'account',
      redirect: `/account`,
    })
  }
  render() {
    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />
    }
    if (!this.state) {
      return <div />
    }
    let { selected } = this.state
    const {
      users: { messageCount },
    } = this.props
    return (
      <nav
        style={{
          position: 'fixed',
          height: '34px',
          bottom: '0',
          left: '0',
          right: '0',
          background: '#eee',
        }}
      >
        <div
          style={{
            height: '100%',
            backgroundColor: selected === 'search' ? '#222' : 'clear',
          }}
          onClick={this.onClickSearch}
          className={`col-xs-3 ${selected === 'search' ? 'selected' : ''}`}
        >
          <i
            style={{
              position: 'absolute',
              top: 'calc(50% - 5px)',
              left: 'calc(50% - 5px)',
              display: 'block',
              color: selected === 'search' ? 'white' : 'clear',
            }}
            className="fa fa-search"
          />
        </div>
        <div
          style={{
            height: '100%',
            backgroundColor: selected === 'favorites' ? '#222' : 'clear',
          }}
          onClick={this.onClickFavorites}
          className={`col-xs-3 ${selected === 'favorites' ? 'selected' : ''}`}
        >
          <i
            style={{
              position: 'absolute',
              top: 'calc(50% - 5px)',
              left: 'calc(50% - 5px)',
              display: 'block',
              color: selected === 'favorites' ? 'white' : 'clear',
            }}
            className="fa fa-star"
          />
        </div>
        <div
          style={{
            height: '100%',
            backgroundColor: selected === 'messages' ? '#222' : 'clear',
          }}
          onClick={this.onClickMessages}
          className={`col-xs-3 ${selected === 'messages' ? 'selected' : ''}`}
        >
          <i
            style={{
              position: 'absolute',
              top: 'calc(50% - 5px)',
              left: 'calc(50% - 5px)',
              display: 'block',
              color: selected === 'messages' ? 'white' : 'clear',
            }}
            className="fa fa-comment"
          />
          <div
            style={{
              position: 'absolute',
              height: '16px',
              width: '16px',
              top: 'calc(50% - 10px)',
              left: 'calc(50% + 3px)',
              textAlign: 'center',
              textOverflow: 'ellipsis',
              lineHeight: '16px',
              overflow: 'hidden',
              fontSize: '9px',
              whiteSpace: 'nowrap',
              backgroundColor: '#329ee8',
              color: '#fff',
              borderRadius: '50%',
            }}
          >
            {messageCount}
          </div>
        </div>
        <div
          style={{
            height: '100%',
            backgroundColor: selected === 'account' ? '#222' : 'clear',
          }}
          onClick={this.onClickAccount}
          className={`col-xs-3 ${selected === 'account' ? 'selected' : ''}`}
        >
          <i
            style={{
              position: 'absolute',
              top: 'calc(50% - 5px)',
              left: 'calc(50% - 5px)',
              display: 'block',
              color: selected === 'account' ? 'white' : 'clear',
            }}
            className="fa fa-user"
          />
        </div>
      </nav>
    )
  }
}

const mapStateToProps = state => {
  return {
    isLoading: state.users.isLoading, // forces a re-render
    users: state.users ? state.users : null,
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch,
  actions: bindActionCreators(
    {
      ...usersActions,
    },
    dispatch
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TabBar)
