import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import DeleteAccount from '../../DeleteAccount'
import AccountLayout from '../../layout'
import * as userActions from 'reducers/user'
import { Link, withRouter } from 'react-router-dom'
import Toggle from 'react-toggle'
import 'react-toggle/style.css'
import swal from 'sweetalert'
import styles from './Settings.module.scss'

class Settings extends Component {
  state = {
    closeModal: false,
  }

  updateUser = () => {
    const {
      actions: { updateUser },
      user,
    } = this.props
    user.allows_non_earning_calls === 'YES'
      ? (user.allows_non_earning_calls = 'NO')
      : (user.allows_non_earning_calls = 'YES')
    updateUser({ allows_non_earning_calls: user.allows_non_earning_calls })
  }

  deleteUser = async () => {
    const {
      actions: { deleteUser, signOutUser },
      user,
    } = this.props

    // @ts-ignore
    const confirm = await swal({
      title: 'Delete Account',
      text: 'Are You Sure?',
      className: styles.swalModal,
      buttons: {
        cancel: 'Cancel',
        submit: {
          text: 'Submit',
          closeModal: this.state.closeModal,
        },
      },
    })
    if (confirm) {
      const result = await deleteUser({ username: user.username })
      if (result) {
        // @ts-ignore
        swal.close()
        this.setState({ closeModal: true })
        signOutUser()
        this.props.history.push('/')
      }
    }
  }

  signOut = () => {
    const {
      actions: { signOutUser, updateUser },
    } = this.props
    // Set Available
    updateUser({ available: 'NO' })
    signOutUser()
    this.props.history.push('/')
  }

  render() {
    const { user } = this.props
    return (
      <AccountLayout>
        <Link
          style={{
            padding: `20px`,
            color: `#333`,
            display: `block`,
            fontSize: `1.5em`,
          }}
          to="/account/settings/change-password"
        >
          Change Password
        </Link>
        <hr />
        <h4
          style={{
            padding: `20px`,
            fontFamily: `'Oswald', sans-serif`,
            fontWeight: `bold`,
            display: `block`,
            fontSize: `1.7em`,
            margin: 0,
          }}
        >
          ACCOUNT SETTINGS
        </h4>
        <Link
          style={{
            marginLeft: `20px`,
            marginRight: `20px`,
            paddingTop: '20px',
            paddingBottom: '20px',
            color: `#333`,
            fontSize: `1.5em`,
            width: 'calc(100% - 40px)',
            display: 'flex',
            justifyContent: 'space-between',
            borderBottom: '1px solid lightgray',
          }}
          to="/account/settings/blocked-users"
        >
          Manage Blocked Users
          <i style={{ marginTop: 5 }} className="fa fa-chevron-right"></i>
        </Link>
        <Link
          style={{
            marginLeft: `20px`,
            marginRight: `20px`,
            paddingTop: '20px',
            paddingBottom: '20px',
            color: `#333`,
            fontSize: `1.5em`,
            width: 'calc(100% - 40px)',
            display: 'flex',
            justifyContent: 'space-between',
            borderBottom: '1px solid lightgray',
          }}
          to={{
            pathname: '/account/settings/password-recovery-options',
          }}
        >
          Password Recovery Options
        </Link>
        <div
          style={{
            marginLeft: `20px`,
            marginRight: `20px`,
            paddingTop: '20px',
            paddingBottom: '20px',
            color: `#333`,
            width: 'calc(100% - 40px)',
            display: 'flex',
            justifyContent: 'space-between',
            borderBottom: '1px solid lightgray',
          }}
        >
          <p
            style={{
              color: `#333`,
              float: 'left',
              fontSize: `1.5em`,
              margin: 0,
            }}
          >
            Allow Free Calls
          </p>
          <div style={{ float: 'right' }}>
            <Toggle
              defaultChecked={
                user.allows_non_earning_calls === 'YES' ? true : false
              }
              onChange={this.updateUser}
            />
          </div>
        </div>
        <h4
          style={{
            padding: `20px`,
            fontFamily: `'Oswald', sans-serif`,
            fontWeight: `bold`,
            display: `block`,
            fontSize: `1.7em`,
            margin: 0,
            marginTop: 20,
          }}
        >
          ABOUT
        </h4>
        <Link
          style={{
            marginLeft: `20px`,
            marginRight: `20px`,
            paddingTop: '20px',
            paddingBottom: '20px',
            color: `#333`,
            fontSize: `1.5em`,
            width: 'calc(100% - 40px)',
            display: 'flex',
            justifyContent: 'space-between',
            borderBottom: '1px solid lightgray',
          }}
          to="/account/settings/#"
        >
          Acknowledgements
        </Link>
        <br />
        <br />
        <br />
        <br />
        <br />
        <DeleteAccount deleteUser={this.deleteUser} />
      </AccountLayout>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user ? state.user : {},
})

const mapDispatchToProps = dispatch => ({
  dispatch,
  actions: bindActionCreators(
    {
      ...userActions,
    },
    dispatch
  ),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Settings)
)
