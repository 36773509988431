import React, { Component } from 'react'
import Api from 'api'
import {
  call,
  hangup,
  onhangup,
  onstart,
  onCallState,
  ondeclined,
  onbailed,
} from './RTCWorker'
import { Redirect } from 'react-router-dom'
import { secondsToTime } from '../../routes/Chat/Date'

class Call extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      user: {},
    }
  }

  componentWillReceiveProps() {
    this.setState({ redirect: null })
  }

  componentDidMount() {
    Api.get(
      '/api/user',
      (error, result) => {
        if (error) {
          console.log(error)
          return
        }
        let user = result.user || {}
        this.setState({
          user: user,
          loaded: true,
        })
        Api.get(
          '/api/user?user=' + this.props.match.params.peer,
          (error, result) => {
            if (error) {
              console.log(error)
              return
            }
            let peer = result.user || {}
            this.setState({
              peer: peer,
              loaded: true,
            })
            if (this.props.match.params.peer) {
              call({
                user: this.state.user.username,
                peer: this.props.match.params.peer,
                cost: this.props.match.params.cost,
                isCaller: this.props.match.params.incoming ? false : true,
              })
              onstart(() => this.setState({ started: true }))
              onCallState(callState => this.setState({ callState }))
              onhangup(totalCallCostRounded => {
                const isCaller = this.props.match.params.incoming ? false : true
                if (isCaller) {
                  alert(
                    `You ${
                      totalCallCostRounded >= 0 ? `paid` : `earned`
                    } ${Math.abs(totalCallCostRounded)} uen${
                      Math.abs(totalCallCostRounded) === 1 ? `` : `s`
                    }.`
                  )
                } else {
                  alert(
                    `You ${
                      totalCallCostRounded >= 0 ? `earned` : `paid`
                    } ${Math.abs(totalCallCostRounded)} uen${
                      Math.abs(totalCallCostRounded) === 1 ? `` : `s`
                    }.`
                  )
                }

                window.location.href = `/`
              })
              ondeclined(() => {
                alert('Declined')
                window.location.href = `/`
              })
              onbailed(() => {
                alert('Caller Left')
                window.location.href = `/`
              })
            }
          },
          false,
          null
        )
      },
      false,
      null
    )
  }

  onGoBackClick = e => {
    e.preventDefault()
    window.location.href = `/`
  }

  onHangupClick = e => {
    e.preventDefault()
    hangup()
  }

  render() {
    const origin = process.env.REACT_APP_ORIGIN
    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />
    }
    let callCostMsg = ``
    if (this.state.started) {
      const isCaller = this.props.match.params.incoming ? false : true
      const total_cost = this.state.callState.server_total_cost
      const total_cost_rounded = Math.abs(Math.round(total_cost))
      const cost_per_second = this.state.callState.cost_per_second
      const cost_per_minute_rounded = Math.abs(
        Math.round(parseInt(this.state.callState.cost_per_min))
      )
      const paidOrEarned = isCaller
        ? cost_per_second > 0
          ? `Paid`
          : `Earned`
        : cost_per_second > 0
        ? `Earned`
        : `Paid`

      if (cost_per_second === 0) {
        callCostMsg = `Chatting For Free`
      } else {
        callCostMsg = `${total_cost_rounded} Uen${
          total_cost_rounded === 1 ? '' : 's'
        } ${paidOrEarned} @ ${cost_per_minute_rounded}/Min`
      }
    }
    this.state.callState && console.log('callState', this.state.callState)
    return (
      <div>
        {this.state.started ? (
          <button
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '33%',
              zIndex: 2,
            }}
            onClick={this.onHangupClick}
            className="btn btn-success btn-block btn-lg"
          >
            Hang Up
          </button>
        ) : (
          <button
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '33%',
              zIndex: 2,
            }}
            onClick={this.onGoBackClick}
            className="btn btn-success btn-block btn-lg"
          >
            Go Back
          </button>
        )}

        <div
          className={
            this.state.started
              ? 'videosContainer started'
              : 'videosContainer not-started'
          }
        >
          <div className="remoteVideos" />
          <video className="localVideo" playsInline autoPlay muted />
          {this.state.started ? (
            <div className="info">
              <div
                style={{
                  fontSize: '1.3em',
                  flex: 1,
                  textAlign: 'center',
                  fontFamily: 'League Gothic',
                }}
              >
                {secondsToTime(this.state.callState.call_length)}
              </div>
              <div
                style={{
                  fontSize: '1.3em',
                  flex: 4,
                  textAlign: 'center',
                }}
              >
                {callCostMsg}
              </div>
              <div
                style={{
                  flex: 1,
                }}
              >
                <img
                  style={{
                    display: 'block',
                    border: `2px solid white`,
                    borderRadius: `100%`,
                    height: '40px',
                    margin: '0 auto',
                  }}
                  src={`${origin}/uploads/${this.state.peer.thumb}`}
                  alt="User"
                />
              </div>
            </div>
          ) : (
            <div className="info">
              <div
                style={{
                  fontFamily: 'League Gothic',
                  fontSize: '2em',
                }}
              >
                RINGING...
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default Call
