import React from 'react'
import { dateToString } from '../../routes/Chat/Date'

const CallHistory = props => {
  const { callHistory, user } = props

  const formatCallCost = uen => {
    let uenCost = Math.abs(parseInt(uen))
    let cost =
      uenCost === 1
        ? uenCost + ' uen'
        : uenCost === 0
        ? ' Free'
        : uenCost + ' uens'
    return cost
  }

  return (
    <div>
      <div
        style={{
          textAlign: 'center',
          borderRadius: '7px',
          color: 'white',
          backgroundColor: 'white',
        }}
      >
        <h3
          style={{
            padding: '10px 0',
            marginBottom: '0',
            backgroundColor: '#01a3d0',
            borderTopLeftRadius: '7px',
            borderTopRightRadius: '7px',
          }}
        >
          Call History
        </h3>

        {callHistory.map((call, index) => {
          return (
            <div key={index}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  backgroundColor: 'white',
                  borderBottomLeftRadius: '7px',
                  borderBottomRightRadius: '7px',
                  padding: '10px 0',
                }}
              >
                <div
                  className="container-fluid"
                  style={{
                    textAlign: 'left',
                    marginLeft: '0',
                    marginRight: '0',
                  }}
                >
                  <h4 style={{ color: 'black' }}>
                    <strong>
                      @
                      {call.username === user.username
                        ? call.completed_call_username
                        : call.username}
                    </strong>
                  </h4>
                  <p style={{ color: '#b8b8b8' }}>
                    Call on {dateToString(call.call_date_time)}
                  </p>
                </div>
                <div
                  style={{
                    textAlign: 'right',
                    paddingRight: '15px',
                    display: 'inline-table',
                  }}
                >
                  <h2
                    style={{
                      color:
                        call.completed_call_username === user.username &&
                        parseInt(call.total_call_cost) > 0
                          ? '#01a3d0'
                          : call.username === user.username &&
                            parseInt(call.total_call_cost) < 0
                          ? '#01a3d0'
                          : 'red',
                    }}
                  >
                    {formatCallCost(call.total_call_cost)}
                  </h2>
                </div>
              </div>

              {callHistory.length !== index + 1 ? (
                <hr style={{ margin: '0' }} />
              ) : null}
            </div>
          )
        })}

        {callHistory.length === 0 ? (
          <h4 style={{ color: 'black', margin: 0, padding: '10px 0' }}>
            No Data
          </h4>
        ) : null}
      </div>
      <p style={{ color: 'white', marginTop: '15px' }}>
        This list is limited to your 300 most recent calls.
      </p>
    </div>
  )
}

export default CallHistory
