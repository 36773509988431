import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import AccountLayout from '../../layout'
import * as userActions from 'reducers/user'
import { Formik } from 'formik'
import * as Yup from 'yup'
import cx from 'classnames'
import * as styles from './Settings.module.scss'

const VALIDATION = {
  current_password: Yup.string()
    .min(3)
    .max(32)
    .required('Enter current password'),
  new_password: Yup.string()
    .min(3)
    .max(64)
    .required('Enter new password'),
  confirm_new_password: Yup.string()
    .min(3)
    .max(64)
    .required('Confirm new password'),
}

class ChangePassword extends Component {
  onChangePassword = values => {
    if (values.new_password !== values.confirm_new_password)
      return console.log('password dont match')
    const {
      actions: { changePassword },
    } = this.props
    changePassword(values)
  }

  render() {
    const { user } = this.props

    return (
      <div className={styles.changePasswordContainer}>
        <AccountLayout>
          <h3 className="col-md-12 text-center">Change Password</h3>

          <Formik
            validateOnBlur={false}
            initialValues={{
              current_password: '',
              new_password: '',
              confirm_new_password: '',
            }}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false)
              this.onChangePassword(values)
            }}
            validationSchema={Yup.object().shape(VALIDATION)}
          >
            {props => {
              const {
                values,
                touched,
                errors,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
              } = props
              return (
                <form className="form" onSubmit={handleSubmit}>
                  <div className="form-group col-md-12">
                    <label htmlFor="current_password">Current Password</label>
                    <input
                      id="current_password"
                      placeholder="Enter your current password"
                      type="password"
                      autoComplete="off"
                      value={values.current_password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={cx('form-control input-md', {
                        'has-error':
                          errors.current_password && touched.current_password,
                      })}
                    />
                    {errors.current_password && touched.current_password && (
                      <div className="invalid-feedback">
                        {errors.current_password}
                      </div>
                    )}
                  </div>
                  <div className="form-group col-md-12">
                    <label htmlFor="new_password">New Password</label>
                    <input
                      id="new_password"
                      placeholder="Enter your new password"
                      type="password"
                      autoComplete="off"
                      value={values.new_password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={cx('form-control input-md', {
                        'has-error':
                          errors.new_password && touched.new_password,
                      })}
                    />
                    {errors.new_password && touched.new_password && (
                      <div className="invalid-feedback">
                        {errors.new_password}
                      </div>
                    )}
                  </div>
                  <div className="form-group col-md-12">
                    <label htmlFor="confirm_new_password">
                      Confirm New Password
                    </label>
                    <input
                      id="confirm_new_password"
                      placeholder="Enter your confirm new password"
                      type="password"
                      value={values.confirm_new_password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={cx('form-control input-md', {
                        'has-error':
                          errors.confirm_new_password &&
                          touched.confirm_new_password,
                      })}
                    />
                    {errors.confirm_new_password &&
                      touched.confirm_new_password && (
                        <div className="invalid-feedback">
                          {errors.confirm_new_password}
                        </div>
                      )}
                    {(values.new_password !== '' &&
                    values.confirm_new_password !== '' ? (
                      values.new_password !== values.confirm_new_password
                    ) : (
                      ''
                    )) ? (
                      <div className="invalid-feedback">
                        New password and confirm password should match.
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="form-group col-md-12">
                    {user.error_message && (
                      <div className="invalid-feedback">
                        {user.error_message}
                      </div>
                    )}
                  </div>
                  <div className="text-center">
                    <button
                      className="btn btn-dark btn-md"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Change Password
                    </button>
                  </div>
                </form>
              )
            }}
          </Formik>
        </AccountLayout>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user ? state.user : {},
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...userActions,
    },
    dispatch
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePassword)
