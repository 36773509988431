import React, { Component } from 'react'
import Api from 'api'
import { Redirect } from 'react-router-dom'
import RangeSlider from 'components/RangeSlider'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as userActions from 'reducers/user'
import swal from 'sweetalert'

class Negotiation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      user: {},
    }
  }

  componentWillReceiveProps() {
    this.setState({ redirect: null })
  }

  componentDidMount() {
    let user = {}
    Api.get(
      '/api/user?user=' + this.props.match.params.username,
      (error, result) => {
        error && console.log(error)
        user = result.user || {}
        this.setState({
          user: user,
          price: parseInt(user.cost_per_min),
          loaded: true,
        })
      }
    )
  }

  onCallClick = async e => {
    const {
      actions: { canCall },
    } = this.props
    const result = await canCall({
      cost_per_min: parseInt(this.state.price),
      callee: this.props.match.params.username,
      are_you_sure: 1,
    })
    if (result.success === 1) {
      this.setState({
        redirect: `/call/${this.props.match.params.username}/${parseInt(
          this.state.price
        )}`,
      })
    } else if (result.success === 0) {
      switch (result.error_options.length) {
        case 3:
          console.log(result.error_options.length)
          const case1 = await swal({
            title: result.error_header,
            text: result.error_message,
            buttons: {
              [result.error_options[0].replace(' ', '')]: {
                text: result.error_options[0],
              },
              [result.error_options[1].replace(' ', '')]: {
                text: result.error_options[1],
              },
              [result.error_options[2].replace(' ', '')]: {
                text: result.error_options[2],
              },
            },
          })
          if (case1 === 'BuyUens') {
            this.props.history.push('/account/add-uens')
          }
          if (case1 === 'CallAnyway') {
            const result = await canCall({
              cost_per_min: parseInt(this.state.price),
              callee: this.props.match.params.username,
              are_you_sure: 0,
            })
            if (result.success === 1) {
              this.setState({
                redirect: `/call/${this.props.match.params.username}/${parseInt(
                  this.state.price
                )}`,
              })
            } else if (result.success === 0) {
              const confirm = await swal({
                title: result.error_header,
                text: result.error_message,
                buttons: {
                  [result.error_options[0].replace(' ', '')]: {
                    text: result.error_options[0],
                  },
                  [result.error_options[1].replace(' ', '')]: {
                    text: result.error_options[1],
                  },
                },
              })
              if (confirm === 'BuyUens') {
                this.props.history.push('/account/add-uens')
              }
            }
          }
          break
        case 2:
          console.log(result.error_options.length)
          const case2 = await swal({
            title: result.error_header,
            text: result.error_message,
            buttons: {
              [result.error_options[0].replace(' ', '')]: {
                text: result.error_options[0],
              },
              [result.error_options[1].replace(' ', '')]: {
                text: result.error_options[1],
              },
            },
          })
          if (case2 === 'BuyUens') {
            this.props.history.push('/account/add-uens')
          }
          if (case2 === 'CallAnyway') {
            const result = await canCall({
              cost_per_min: parseInt(this.state.price),
              callee: this.props.match.params.username,
              are_you_sure: 0,
            })
            if (result.success === 1) {
              this.setState({
                redirect: `/call/${this.props.match.params.username}/${parseInt(
                  this.state.price
                )}`,
              })
            } else if (result.success === 0) {
              const confirm = await swal({
                title: result.error_header,
                text: result.error_message,
                buttons: {
                  [result.error_options[0].replace(' ', '')]: {
                    text: result.error_options[0],
                  },
                  [result.error_options[1].replace(' ', '')]: {
                    text: result.error_options[1],
                  },
                },
              })
              if (confirm === 'BuyUens') {
                this.props.history.push('/account/add-uens')
              }
            }
          }
          break
        case 1:
          console.log(result.error_options.length)
          const case3 = await swal({
            title: result.error_header,
            text: result.error_message,
            buttons: {
              [result.error_options[0].replace(' ', '')]: {
                text: result.error_options[0],
              },
            },
          })
          if (case3 === 'BuyUens') {
            this.props.history.push('/account/add-uens')
          }
          if (case2 === 'CallAnyway') {
            const result = await canCall({
              cost_per_min: parseInt(this.state.price),
              callee: this.props.match.params.username,
              are_you_sure: 0,
            })
            if (result.success === 1) {
              this.setState({
                redirect: `/call/${this.props.match.params.username}/${parseInt(
                  this.state.price
                )}`,
              })
            } else if (result.success === 0) {
              const confirm = await swal({
                title: result.error_header,
                text: result.error_message,
                buttons: {
                  [result.error_options[0].replace(' ', '')]: {
                    text: result.error_options[0],
                  },
                  [result.error_options[1].replace(' ', '')]: {
                    text: result.error_options[1],
                  },
                },
              })
              if (confirm === 'BuyUens') {
                this.props.history.push('/account/add-uens')
              }
            }
          }
          break
        default:
          const defaultCase = await swal({
            title: result.error_header,
            text: result.error_message,
          })
      }
    }
  }
  onUpdate = price => {
    this.setState({ price })
  }
  onGoBackClick = e => {
    window.history.back()
  }
  render() {
    if (!this.state.loaded) {
      return <div />
    }
    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />
    }
    const origin = process.env.REACT_APP_ORIGIN
    const { user } = this.state
    const { username, thumb, available, cost_per_min, uens } = user

    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: '#2c2d2f',
          color: '#FFF',
        }}
      >
        <div
          style={{
            position: 'fixed',
            top: '5%',
            left: '0',
            right: '0',
            height: '10%',
          }}
        >
          <img
            style={{
              display: 'block',
              border: `2px solid white`,
              borderRadius: `100%`,
              height: '100%',
              margin: '0 auto',
            }}
            src={`${origin}/uploads/${thumb}`}
            alt="User"
          />
        </div>
        <div style={{ position: 'fixed', top: '20%', left: '0', right: '0' }}>
          <div
            style={{
              fontSize: `1.3em`,
              fontFamily: `'Oswald', sans-serif`,
              fontWeight: `bold`,
              width: `100%`,
              textAlign: `center`,
              margin: `0`,
            }}
          >
            I WANT TO PAY
          </div>
          <div style={{ margin: '0 10%' }}>
            <RangeSlider
              name="price"
              onEnd={this.onUpdate}
              value={parseInt(this.state.price)}
              min={-30}
              max={30}
            />
          </div>
          <div
            style={{
              width: `100%`,
              textAlign: `center`,
              margin: `0`,
            }}
          >
            uens per minute
          </div>
        </div>
        <div
          style={{
            position: 'fixed',
            bottom: '20%',
            left: '0',
            right: '0',
          }}
        >
          <div style={{ margin: '0 10%' }}>
            <div className="row">
              <div className="col-xs-6">
                <button
                  className="btn btn-default btn-block btn-md"
                  onClick={this.onGoBackClick}
                >
                  Go Back
                </button>
              </div>
              <div className="col-xs-6">
                <button
                  className="btn btn-success btn-block btn-md"
                  onClick={this.onCallClick}
                >
                  Call
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user ? state.user : {}, // FIXME: user will contain an error message in this context, not a user.
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...userActions,
    },
    dispatch
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Negotiation)
