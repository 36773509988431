import React, { Component } from 'react'
import Slider from 'react-rangeslider'

class RangeSlider extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      value: props.value ? parseInt(props.value) : 0,
    }
  }

  handleChangeStart = () => {}

  handleChange = value => {
    this.setState({
      value: value,
    })
  }

  handleChangeComplete = () => {
    const { onEnd } = this.props

    if (onEnd) {
      onEnd(this.state.value)
    }
  }

  render() {
    const { value } = this.state
    return (
      <div className="slider">
        <Slider
          min={this.props.min}
          max={this.props.max}
          value={value}
          onChangeStart={this.handleChangeStart}
          onChange={this.handleChange}
          onChangeComplete={this.handleChangeComplete}
        />
        <div className="value">{value}</div>
      </div>
    )
  }
}

export default RangeSlider
