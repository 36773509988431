import React from 'react'

const BrowserIncompatible = props => {
  return (
    <div
      style={{
        backgroundColor: 'black',
        height: '100vh',
        width: '100vw',
        position: 'absolute',
      }}
    >
      <h4
        style={{
          color: 'white',
          textAlign: 'center',
          marginTop: '0',
          position: 'relative',
          top: '45%',
        }}
      >
        Sorry, your browser isn’t supported.
      </h4>
      <h4
        style={{
          color: 'white',
          textAlign: 'center',
          marginTop: '0',
          position: 'relative',
          top: '45%',
        }}
      >
        UenMe works best with Chrome!
      </h4>
    </div>
  )
}

export default BrowserIncompatible
