import React, { Component } from 'react'

import AccountLayout from '../../layout'
import CheckoutForm from 'components/CheckoutForm'
import RangeSlider from 'components/RangeSlider'
import Api from 'api'

export default class AddUens extends Component {
  state = { uens: 50, payment_method: 'Credit Card' }

  async componentDidMount() {
    const response = await this.calculatePrice({
      uens: this.state.uens,
      payment_method: this.state.payment_method,
    })
    this.setState({ cart: response.cart })
  }

  calculatePrice(obj) {
    return new Promise((resolve, reject) => {
      Api.get(
        `/api/price_calculate?uens=${obj.uens}&payment_method=${
          obj.payment_method
        }`,
        (error, result) => {
          if (error) {
            reject(error)
            return
          }
          resolve(result)
        },
        false,
        null
      )
    })
  }

  onUpdate = async uens => {
    const response = await this.calculatePrice({
      uens: uens,
      payment_method: this.state.payment_method,
    })
    this.setState({ uens, cart: response.cart })
  }

  render() {
    this.state && console.log(this.state)
    if (!this.state.cart) {
      return <></>
    }
    const { cart, uens } = this.state
    return (
      <AccountLayout>
        <div style={{ margin: '10% 10%' }}>
          <h3>Add Uens</h3>
          <RangeSlider onEnd={this.onUpdate} value={uens} min={10} max={500} />
          <hr />
          <div>
            <span>{this.state.cart.name}:</span>
            <span style={{ float: 'right' }}>{this.state.cart.cost}</span>
          </div>
          <div>
            <span>Fee:</span>
            <span style={{ float: 'right' }}>{this.state.cart.fee}</span>
          </div>
          <hr />
          <div>
            <span>Total:</span>
            <span style={{ float: 'right' }}>{this.state.cart.total}</span>
          </div>
          <CheckoutForm uens={cart.uens} paymentAmount={cart.total} />
        </div>
      </AccountLayout>
    )
  }
}
