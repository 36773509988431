import React, { Component } from 'react'

class SystemMessage extends Component {
  render() {
    const message = !this.props.message.msg
      ? this.props.message
      : this.props.message.msg
    return (
      <div
        style={{
          textAlign: 'left',
          backgroundColor: '#F6F6F6',
          margin: '20px 10px 10px 10px',
          borderRadius: '12px',
          padding: '25px 15px 25px 15px',
        }}
      >
        <div>
          <h4 style={{ fontWeight: 'bold' }}>System Message From UenMe</h4>
        </div>
        <div
          dangerouslySetInnerHTML={{
            __html: message,
          }}
        ></div>
        {/* <div>{timeSince(message.message_date_time || dateTimeNow())}</div> */}
      </div>
    )
  }
}

export default SystemMessage
