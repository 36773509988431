import React, { Component } from 'react'
import { FaCamera } from 'react-icons/fa'
import swal from 'sweetalert'
// @ts-ignore
import { Spinner } from 'react-activity'
import 'react-activity/dist/react-activity.css'

class AboutYouPhotoForm extends Component {
  state = {
    file: {},
    selectedFile: '',
    isUploading: false,
  }

  onChange = e => {
    const {
      user: { username },
    } = this.props
    this.setState({ file: e.currentTarget.files[0], isUploading: true }, () => {
      if (this.state.file) {
        const fileExt = this.state.file.name.split('.').pop()
        if (fileExt === 'jpg' || fileExt === 'png') {
          const data = new FormData()
          // @ts-ignore
          const blob = new Blob([this.state.file], {
            type: 'application/octet-stream',
          })
          const imageUrl = URL.createObjectURL(blob)
          this.setState({ selectedFile: imageUrl })
          data.append('photo', blob, `${username}-photo.jpg`)
          const {
            actions: { updateUserPhoto },
          } = this.props
          updateUserPhoto(data)
            .then(() => {
              this.setState({ isUploading: false })
              this.forceUpdate()
            })
            .catch(error => {
              console.log(`failed to update the user photo: `, error)
            })
        } else {
          swal({
            title: 'Incorrect Format',
            text: 'Please select valid format.',
          })
        }
      }
    })
  }

  render() {
    const { user } = this.props
    const origin = process.env.REACT_APP_ORIGIN

    return (
      <div className="signup">
        <div className="form-group row" style={{ margin: '0' }}>
          {user && user.thumb && (
            <img
              style={{ display: 'block', width: '100%' }}
              src={
                this.state.selectedFile !== ''
                  ? this.state.selectedFile
                  : `${origin}/uploads/${user.thumb}`
              }
              alt="User"
            />
          )}
          {this.state.isUploading && (
            <div style={{ position: 'absolute', top: '35%', left: '50%' }}>
              <Spinner />
            </div>
          )}
          <div className="button">
            <div
              style={{
                height: '60px',
                position: 'relative',
                marginTop: '-60px',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  right: '31px',
                  background: '#41acdd',
                  width: '40px',
                  height: '40px',
                  borderRadius: '50%',
                  textAlign: 'center',
                  lineHeight: '45px',
                }}
              >
                <label htmlFor="userImage">
                  <FaCamera
                    style={{
                      color: 'white',
                      height: '15px',
                      width: '15px',
                    }}
                  />
                </label>
              </div>
            </div>
            <input
              type="file"
              accept=".jpg, .png, image/jpeg, image/png"
              id="userImage"
              style={{ display: 'none' }}
              onChange={this.onChange}
            />
          </div>
          {/* <input
            id="photo"
            placeholder="photo (optional)"
            type="file"
            onChange={this.onChange}
          /> */}
        </div>
      </div>
    )
  }
}

export default AboutYouPhotoForm
