import React, { Component } from 'react'
import DropIn from 'braintree-web-drop-in-react'
import Api from 'api'
import Session from 'api/Session'
import swal from 'sweetalert'

export default class Checkout extends Component {
  instance

  state = {
    clientToken: null,
  }

  async componentDidMount() {
    const response = await this.fetchClientToken() // response is text/plain
    const clientToken = await response.text()
    this.setState({
      clientToken,
    })
  }

  buy = async () => {
    if (this.instance) {
      const { nonce } = await this.instance.requestPaymentMethod()
      const res = await this.makeBraintreePurchase({
        payment_method_nonce: nonce,
        payment_amount: this.props.paymentAmount.replace('$', ''),
        uens: this.props.uens,
        method: 'Credit Card',
      })
      if (!res.success) {
        swal('Error', res._attributes.message)
      }
    }
  }

  fetchClientToken() {
    const origin = process.env.REACT_APP_ORIGIN
    const url = '/api/braintree_token'
    /** @type {HeadersInit} */
    const headers = {}
    headers['Content-Type'] = 'application/json'
    headers['Username'] = Session.currentUser().username
    headers['Authorization'] = 'Bearer ' + Session.getToken()
    headers['Accept'] = 'text/plain'
    return fetch(origin + url, {
      method: 'GET',
      headers,
    })
  }

  makeBraintreePurchase(obj) {
    return new Promise((resolve, reject) => {
      Api.post('/api/braintree_purchase_uens', obj, (error, result) => {
        if (error) {
          reject(error)
          return
        }
        resolve(result)
      })
    })
  }

  render() {
    if (!this.state.clientToken) {
      return (
        <div>
          <h1>Loading...</h1>
        </div>
      )
    } else {
      return (
        <div>
          <DropIn
            options={{ authorization: this.state.clientToken }}
            onInstance={instance => (this.instance = instance)}
          />
          <button onClick={this.buy}>
            Buy {this.props.uens} Uens for {this.props.paymentAmount}
          </button>
        </div>
      )
    }
  }
}
