import React, { Component } from 'react'
import { connect } from 'react-redux'
import styles from './InterestCell.module.css'
import InterestedButton from 'components/InterestedButton'
import { push } from 'connected-react-router'

class InterestCell extends Component {
  onClick = e => {
    e.preventDefault()
    e.stopPropagation()
    const {
      onClick,
      interest: { interest },
    } = this.props

    if (onClick) {
      return onClick(interest, this.isMutualInterest())
    }

    if (onClick === false) {
      return
    }

    return this.props.goToInterest()
  }

  isMutualInterest = () => {
    if (!this.props.interest) return false
    const {
      interest: { mutual_interest },
    } = this.props

    return mutual_interest === '1'
  }

  toggleInterest = e => {
    e.preventDefault()
    e.stopPropagation()
    const {
      interest: { interest },
      onToggleInterest,
    } = this.props
    onToggleInterest(interest, this.isMutualInterest())
  }

  render() {
    const {
      interest: { interest, count },
    } = this.props
    const interested = this.isMutualInterest()

    return (
      <tr onClick={this.onClick} style={{ borderBottom: '1px solid #eee' }}>
        <td style={{ verticalAlign: 'baseline', padding: '4%' }}>
          <div className={styles.description}>
            <div>
              <span className={styles.name}>#{interest}</span>
              <em className={styles.count}>{count}</em>
            </div>
            <InterestedButton
              toggle={this.toggleInterest}
              interested={interested}
            />
          </div>
        </td>
      </tr>
    )
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  goToInterest: () =>
    dispatch(
      push(
        `/interested-users/${encodeURIComponent(ownProps.interest.interest)}`
      )
    ),
})

export default connect(
  null,
  mapDispatchToProps
)(InterestCell)
