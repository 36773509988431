import React, { Component } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import cx from 'classnames'
import FloatingLabel from 'floating-label-react'

const VALIDATION = {
  email: Yup.string()
    .email()
    .min(6)
    .max(32),
  phone: Yup.string().length(10),
}

class PasswordRecoveryForm extends Component {
  render() {
    const { onSubmit, user, onSkip } = this.props

    return (
      <div className="signup container-fluid" style={{ paddingTop: '30px' }}>
        <button
          style={{
            background: 'rgba(0,0,0,0)',
            position: 'fixed',
            top: '10px',
            right: '10px',
            color: 'white !important',
          }}
          className="btn btn-sm"
          onClick={onSkip}
        >
          {user.phone || user.email ? 'Next' : 'Skip'}
        </button>
        <Formik
          enableReinitialize
          initialValues={{ email: user.email || '', phone: user.phone || '' }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false)
            onSubmit(values)
          }}
          validationSchema={Yup.object().shape(VALIDATION)}
        >
          {props => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
            } = props
            return (
              <form className="form" onSubmit={handleSubmit}>
                <div className="form-group">
                  <h3>Make it easy to recover your profile</h3>
                  <p>We will never give out your information.</p>
                </div>
                <div className="form-group">
                  <FloatingLabel
                    id="email"
                    placeholder="Email (optional)"
                    type="text"
                    autoComplete="off"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={cx('', {
                      'has-error': errors.email && touched.email,
                    })}
                  />
                  {errors.email && touched.email && (
                    <div className="invalid-feedback">{errors.email}</div>
                  )}
                </div>
                <div className="form-group">
                  <FloatingLabel
                    id="phone"
                    placeholder="Phone (optional)"
                    type="phone"
                    value={values.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={cx('', {
                      'has-error': errors.phone && touched.phone,
                    })}
                  />
                  {errors.phone && touched.phone && (
                    <div className="invalid-feedback">{errors.phone}</div>
                  )}
                </div>
                {user.error_message && (
                  <div className="invalid-feedback">{user.error_message}</div>
                )}
                <button
                  className="btn btn-primary btn-block btn-lg"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Next
                </button>
              </form>
            )
          }}
        </Formik>
      </div>
    )
  }
}

export default PasswordRecoveryForm
