import React, { Component } from 'react'

import styles from './ActiveChatSessionCell.module.scss'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as usersActions from 'reducers/users'
import * as userActions from 'reducers/user'
import { dateTimeNow, timeSince } from '../../routes/Chat/Date'
import 'rc-swipeout/assets/index.css'

class ActiveChatSessionCell extends Component {
  state = {
    activeDrags: 0,
    showDeleteButton: false,
  }

  onClick = e => {
    e.preventDefault()
    e.stopPropagation()
    const {
      onClick,
      goToUser,
      activeChatSession: { username },
    } = this.props

    if (onClick) {
      return onClick(username)
    }

    if (onClick === false) {
      return
    }

    return goToUser()
  }

  onChatClick = e => {
    e.preventDefault()
    e.stopPropagation()
    const { goToChat } = this.props

    goToChat()
  }

  onNegotiationClick = e => {
    e.preventDefault()
    e.stopPropagation()
    const { goToNegotiation } = this.props

    goToNegotiation()
  }

  onFavoriteClick = e => {
    e.preventDefault()
    e.stopPropagation()
    const { onFavoritesClick } = this.props

    onFavoritesClick()
  }

  render() {
    const {
      me,
      activeChatSession: {
        call_date_time,
        message_date_time,
        message,
        available,
        cost_per_min,
        user_cost_per_min,
        sender,
        viewed,
        username,
        thumb,
        id,
      },
    } = this.props
    const coalescedCost = cost_per_min > 0 ? cost_per_min : user_cost_per_min

    const cost = Math.abs(Math.round(parseInt(coalescedCost)))
    const costStr =
      cost > 0 ? `${cost} uen${cost !== 1 ? 's' : ''}/min` : `FREE`

    let msgStr = ``
    if (call_date_time === message_date_time) {
      if (sender === me) {
        msgStr = `You called ${timeSince(
          call_date_time || dateTimeNow()
        )} for ${costStr}`
      } else {
        msgStr = `Called you ${timeSince(
          call_date_time || dateTimeNow()
        )} for ${costStr}`
      }
    } else {
      msgStr = `From ${sender === me ? `you` : `@${sender}`} ${timeSince(
        message_date_time || dateTimeNow()
      )}: ${message}`
    }
    const origin = process.env.REACT_APP_ORIGIN

    return (
      <div
        key={id}
        onClick={this.onClick}
        style={{
          display: 'flex',
          flexDirection: 'row',
          fontSize: 'smaller',
          borderLeft: '0',
          borderRight: '0',
          borderBottom: '2px solid white',
        }}
      >
        <div style={{ flex: '4' }}>
          <div className="user-img-wrapper">
            <img src={`${origin}/uploads/${thumb}`} alt="User" />
          </div>
        </div>
        <div style={{ flex: '6' }}>
          <div
            style={{
              position: 'relative',
              verticalAlign: 'baseline',
              padding: '3.333%' /* (2% * (10 / 6)) */,
              height: '100%',
            }}
          >
            <div className={styles.userName}>
              <b>@{username}</b>
              {viewed.toLowerCase() === 'no' ? (
                <i style={{ color: '#00b0df' }} className="fa fa-circle" />
              ) : (
                <></>
              )}
            </div>
            <div
              style={{
                marginTop: '5%',
                maxHeight: '51px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {msgStr}
            </div>
            {available.toLowerCase() === 'yes' ? (
              <div
                style={{
                  height: '30%',
                  position: 'absolute',
                  left: '0',
                  right: '0',
                  bottom: '0',
                  backgroundColor: '#5cb85c',
                  color: 'white',
                  cursor: 'pointer',
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    right: '30%',
                    bottom: '0',
                  }}
                  onClick={this.onNegotiationClick}
                >
                  <div
                    style={{
                      position: 'absolute',
                      top: 'calc(50% - 15px)',
                      left: '5%',
                      height: '30px',
                    }}
                  >
                    <div>{costStr}</div>
                    <div style={{ fontSize: 'smaller' }}>Call Now</div>
                  </div>
                </div>
                <div
                  style={{
                    position: 'absolute',
                    top: '0',
                    width: '20%',
                    right: '0',
                    bottom: '0',
                    backgroundColor: 'rgba(0,0,0,0.25)',
                  }}
                  onClick={this.onNegotiationClick}
                >
                  <i
                    style={{
                      position: 'absolute',
                      top: 'calc(50% - 5px)',
                      left: 'calc(50% - 2px)',
                      display: 'block',
                    }}
                    className="fa fa-phone"
                  />
                </div>
              </div>
            ) : (
              <div
                style={{
                  height: '30%',
                  position: 'absolute',
                  left: '0',
                  right: '0',
                  bottom: '0',
                  backgroundColor: '#d22421',
                  color: 'white',
                  cursor: 'pointer',
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    right: '30%',
                    bottom: '0',
                  }}
                  onClick={this.onChatClick}
                >
                  <div
                    style={{
                      position: 'absolute',
                      top: 'calc(50% - 15px)',
                      left: '5%',
                      height: '30px',
                    }}
                  >
                    <div>{costStr}</div>
                    <div style={{ fontSize: 'smaller' }}>Chat Now</div>
                  </div>
                </div>
                <div
                  style={{
                    position: 'absolute',
                    top: '0',
                    width: '20%',
                    right: '0',
                    bottom: '0',
                    backgroundColor: 'rgba(0,0,0,0.25)',
                  }}
                  onClick={this.onChatClick}
                >
                  <i
                    style={{
                      position: 'absolute',
                      top: 'calc(50% - 5px)',
                      left: 'calc(50% - 2px)',
                      display: 'block',
                    }}
                    className="fa fa-comment"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    activeChatSession: { username, is_favorite, id },
  } = ownProps

  const {
    favoriteUserByUsername,
    unfavoriteUserByUsername,
  } = bindActionCreators(
    {
      ...usersActions,
      ...userActions,
    },
    dispatch
  )

  return {
    goToUser: () => dispatch(push(`/user/${username}`)),
    goToChat: () => dispatch(push(`/chat/${username}`)),
    goToNegotiation: () => dispatch(push(`/negotiation/${username}`)),
    onFavoritesClick: () => {
      const action = !is_favorite
        ? favoriteUserByUsername
        : unfavoriteUserByUsername

      action(username)
    },
  }
}

export default connect(
  null,
  mapDispatchToProps
)(ActiveChatSessionCell)
