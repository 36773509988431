import React, { Component } from 'react'
import Api from 'api'
import { Redirect } from 'react-router-dom'
import Session from 'api/Session'
import NavBar from 'components/NavBar'
import SentMessage from 'components/ChatCells/SentMessage'
import ReceivedMessage from 'components/ChatCells/ReceivedMessage'
import AttemptedCall from 'components/ChatCells/AttemptedCall'
import CompletedCall from 'components/ChatCells/CompletedCall'
import TheyCompletedCall from 'components/ChatCells/TheyCompletedCall'
import SentPhoto from 'components/ChatCells/SentPhoto'
import ReceivedPhoto from 'components/ChatCells/ReceivedPhoto'
import MissedCall from 'components/ChatCells/MissedCall'
import SystemMessage from 'components/ChatCells/SystemMessage'
import * as userActions from '../../reducers/user'
import { FaCamera } from 'react-icons/fa'
import { FaPaperPlane } from 'react-icons/fa'
import swal from 'sweetalert'
import { goBack } from 'connected-react-router'
import { connect } from 'react-redux'
import styles from './Chat.module.scss'
import { bindActionCreators } from 'redux'
import { withLastLocation } from 'react-router-last-location'
const chatOrigin = process.env.REACT_APP_CHAT_ORIGIN
const chatUploadOrigin = process.env.REACT_APP_CHAT_UPLOAD_ORIGIN

class Chat extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      self: {},
      peer: {},
      /** @type {{to: string, from: string, message?: string, alert?: string, action?: string, cost_per_min?: number, total_call_cost?: number, message_date_time?: string, call_length?:number, environment?: string, media_url?: string, filename?: string, userAvatarFileName?: string, username?: string}[]} */
      messages: [],
      toggleAction: false,
    }
  }

  componentWillUnmount() {
    console.log(`Chat componentWillUnmount`)
    this.close()
  }

  close() {
    this.socket && (this.socket.onopen = () => {})
    this.socket && (this.socket.onclose = () => {})
    this.socket && (this.socket.onerror = () => {})
    this.socket && (this.socket.onmessage = () => {})
    this.socket && this.socket.close()
  }

  componentWillReceiveProps() {
    this.setState({ redirect: null })
  }

  listen = () => {
    if (!this.self || !this.peer) return
    // Serverside keepalive?
    this.socket = new WebSocket(
      `${chatOrigin}?from=${this.self.username}&to=${
        this.peer.username
      }&token=${Session.getToken()}`
    )
    this.socket.onmessage = e => {
      this.setState({
        messages: this.state.messages.concat([JSON.parse(e.data)]),
      })

      this.interval && clearInterval(this.interval)
      this.interval = setTimeout(() => {
        const chatMessages = document.querySelector('#chat-messages')
        chatMessages instanceof HTMLDivElement &&
          (chatMessages.scrollTop = chatMessages.scrollHeight)
      }, 250)
    }
    const chatPanel = document.querySelector('.chat')
    chatPanel &&
      chatPanel.addEventListener(
        'keypress',
        /** @param {KeyboardEvent} e */ e => {
          if (e.keyCode === 13) {
            this.sendWhateversInTheBox()
          }
        }
      )
    this.socket.onerror = e => {
      console.log(e)
    }
    this.socket.onclose = e => {
      console.log(e)
      this.setState({ messages: [] })
      setTimeout(() => {
        this.listen()
      }, 2000)
    }
    this.socket.onopen = e => {
      console.log(e)
    }
  }

  componentDidMount() {
    let self = {}
    let peer = {}
    Api.getSilent('/api/user', (error, result) => {
      error && console.log(error)
      self = result.user || {}
      Api.getSilent(
        '/api/user?user=' + this.props.match.params.username,
        (error, result) => {
          if (error) {
            console.log(error)
            return
          }
          peer = result.user || {}
          this.setState({
            self: self,
            peer: peer,
            loaded: true,
          })
          this.self = self
          this.peer = peer
          this.listen()
        }
      )
    })
  }

  onSendClick = e => {
    e.preventDefault()
    this.sendWhateversInTheBox()
  }

  onSendPhotoClick = async e => {
    const { files } = e.currentTarget
    if (files) {
      const fileExt = files[0].name.split('.').pop()
      if (fileExt === 'jpg' || fileExt === 'png') {
        const data = new FormData()
        const blob = new Blob([files[0]], { type: 'application/octet-stream' })
        data.append('photo', blob, `photo.jpg`)

        // Move this logic into a Redux Action Middleware
        if (this.self && this.peer) {
          await Api.v2.axios({
            baseURL: `${chatUploadOrigin}`,
            url: `/upload-sync?from=${this.self.username}&to=${
              this.peer.username
            }&token=${Session.getToken()}`,
            method: 'POST',
            data,
            headers: {
              'Content-Type': 'multipart/form-data',
              ...Api.v2.getAuthHeaders({
                // Use Redux Store User for this info:
                token: Session.getToken(),
                user: this.self.username,
              }),
            },
          })
        }
      } else {
        swal({ title: 'Incorrect Format', text: 'Please select .jpg format.' })
      }
    }
  }

  sendWhateversInTheBox = () => {
    /** @type {HTMLInputElement|null} */
    let messageInput = document.querySelector('#message')
    if (!messageInput) return
    let messageText = messageInput.value
    if (!messageText) {
      return
    }
    let message = JSON.stringify({
      message: messageText,
      environment: process.env.REACT_APP_CHAT_ENVIRONMENT,
      from: this.state.self.username,
      to: this.state.peer.username,
      username: this.state.self.username,
      action: `message_from_chat`,
    })
    this.socket && this.socket.send(message)
    this.setState({
      messages: this.state.messages.concat([JSON.parse(message)]),
    })
    messageInput.value = ''

    this.interval && clearInterval(this.interval)
    this.interval = setTimeout(() => {
      const chatMessages = document.querySelector('#chat-messages')
      chatMessages instanceof HTMLDivElement &&
        (chatMessages.scrollTop = chatMessages.scrollHeight)
    }, 250)
  }

  onCallClick = e => {
    this.setState({
      redirect: `/negotiation/${this.props.match.params.username}`,
    })
  }

  onBackClick = () => {
    let self = this
    if (!self.props.lastLocation) {
      return self.props.history.push('/search/users')
    } else {
      window.history.back()
    }
  }

  onActionClick = () => {
    this.setState({ toggleAction: !this.state.toggleAction })
  }

  blockuser = async e => {
    e.stopPropagation()
    this.setState({ toggleAction: false })
    const {
      dispatch,
      actions: { blockUser },
    } = this.props
    const { peer } = this.state
    const res = await blockUser(peer.username)
    if (res)
      swal('User blocked', peer.username, { className: styles.swalModalTwo })
    dispatch(goBack())
  }

  reportUser = async e => {
    try {
      e.stopPropagation()
      this.setState({ toggleAction: false })
      const {
        dispatch,
        actions: { reportUser },
      } = this.props
      const { peer } = this.state
      // @ts-ignore
      const reason = await swal({
        title: 'Why? (Optional)',
        text: 'What did they do? ',
        content: 'input',
        className: styles.swalModal,
        buttons: {
          cancel: 'Cancel',
          submit: {
            text: 'Submit',
            closeModal: false,
          },
        },
      })
      if (reason != null) {
        reportUser(peer.username, reason)
        swal('User reported', peer.username, { className: styles.swalModalTwo })
        dispatch(goBack())
      }
    } catch (error) {
      swal('Error', error, { className: styles.swalModalTwo })
    }
  }

  onTitleClick = () => {
    this.setState({ redirect: `/user/${this.props.match.params.username}` })
  }

  formatMessage = (message, color) => {
    let formatedMessage = {
      msg: message.message,
      actual_msg: message,
      date: message.message_date_time,
    }
    let expressions = [
      '@([A-Za-z0-9-]+)',
      'FAQ',
      '[U|u]ens',
      '(http://)?(www\\.)?uenme\\.co\\S*',
    ]
    expressions.forEach(exp => {
      let matchVal = message.message.match(exp)
      if (matchVal) {
        if (matchVal[0].startsWith(`@`)) {
          let username = matchVal[0].substring(1)
          let url = `/user/${username}`
          formatedMessage = {
            msg: formatedMessage.msg.replace(
              `@${username}`,
              `<a href=${url} style="color: ${color}">@${username}</a>`
            ),
            actual_msg: message,
            date: message.message_date_time,
          }
        }
        if (matchVal[0].startsWith(`FAQ`)) {
          let href = 'FAQ'
          let url = `https://www.uenme.co/faq`
          formatedMessage = {
            msg: formatedMessage.msg.replace(
              `${href}`,
              `<a href=${url} style="color: ${color}">${href}</a>`
            ),
            actual_msg: message.message,
            date: message.message_date_time,
          }
        }
        if (matchVal[0].startsWith(`http://www.uenme.co`)) {
          let href = matchVal[0].substring()
          let url = `http://www.uenme.co`
          formatedMessage = {
            msg: formatedMessage.msg.replace(
              `${href}`,
              `<a href=${url} style="color: ${color}">${href}</a>`
            ),
            actual_msg: message,
            date: message.message_date_time,
          }
        }
        if (matchVal[0].startsWith(`www.uenme.co`)) {
          let href = matchVal[0].substring()
          let url = `https://www.uenme.co`
          formatedMessage = {
            msg: formatedMessage.msg.replace(
              `${href}`,
              `<a href=${url} style="color: ${color}">${href}</a>`
            ),
            actual_msg: message,
            date: message.message_date_time,
          }
        }
        if (matchVal[0].startsWith(`uenme.co`)) {
          let href = matchVal[0].substring()
          let url = `https://www.uenme.co`
          formatedMessage = {
            msg: formatedMessage.msg.replace(
              `${href}`,
              `<a href=${url} style="color: ${color}">${href}</a>`
            ),
            actual_msg: message,
            date: message.message_date_time,
          }
        }
        if (matchVal[0].startsWith(`https://uenme.co`)) {
          let href = matchVal[0].substring()
          let url = `https://uenme.co`
          formatedMessage = {
            msg: formatedMessage.msg.replace(
              `${href}`,
              `<a href=${url} style="color: ${color}">${href}</a>`
            ),
            actual_msg: message,
            date: message.message_date_time,
          }
        }
        if (matchVal[0].toLowerCase() === `uens`) {
          let href = matchVal[0].substring()
          let url = `https://www.uenme.co/home/whatareuens`
          formatedMessage = {
            msg: formatedMessage.msg.replace(
              `${href}`,
              `<a href=${url} style="color: ${color}">${href}</a>`
            ),
            actual_msg: message,
            date: message.message_date_time,
          }
        }
      }
    })
    return formatedMessage
  }

  render() {
    if (!this.state.loaded) {
      return <div />
    }
    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />
    }
    const { self, peer } = this.state

    const rows = this.state.messages.map(message => {
      if (
        message.from === self.username &&
        message.action === `message_from_chat`
      ) {
        return (
          <SentMessage
            message={this.formatMessage(message, 'white')}
            self={self}
          />
        )
      } else if (
        message.to === self.username &&
        message.action === `message_from_chat`
      ) {
        return (
          <ReceivedMessage
            message={this.formatMessage(message, '#337ab7')}
            peer={peer}
          />
        )
      } else if (
        message.from === self.username &&
        message.action === `missed_call`
      ) {
        return <AttemptedCall message={message} self={self} peer={peer} />
      } else if (
        message.to === self.username &&
        message.action === `missed_call`
      ) {
        return <MissedCall message={message} self={self} peer={peer} />
      } else if (
        message.from === self.username &&
        message.action === `completed_call`
      ) {
        return <CompletedCall message={message} self={self} peer={peer} />
      } else if (
        message.to === self.username &&
        message.action === `completed_call`
      ) {
        return <TheyCompletedCall message={message} self={self} peer={peer} />
      } else if (
        message.from === self.username &&
        message.action === `media_message`
      ) {
        return <SentPhoto message={message} />
      } else if (
        message.to === self.username &&
        message.action === `media_message`
      ) {
        return <ReceivedPhoto message={message} />
      } else if (message.action === `system_message`) {
        return (
          <SystemMessage message={this.formatMessage(message, '#337ab7')} />
        )
      }
      return null
    })
    return (
      <div className="chat">
        <div
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            right: '0',
            height: '88px',
            zIndex: '100',
          }}
        >
          <NavBar
            title={`@${this.state.peer.username}`}
            onBackClick={this.onBackClick}
            onActionClick={this.onActionClick}
            onTitleClick={this.onTitleClick}
          />

          {this.state.toggleAction ? (
            <div
              style={{
                position: 'fixed',
                top: 'calc( 1.5em + 20px)',
                left: 0,
                right: 0,
                bottom: 0,
              }}
            >
              <ul
                style={{
                  background: '#fff',
                  position: 'absolute',
                  right: '0',
                  color: '#43afdc',
                  listStyle: 'none',
                  padding: '10px 17px',
                  marginBottom: '0',
                  zIndex: '101',
                }}
              >
                <li style={{ paddingBottom: '5px' }} onClick={this.blockuser}>
                  Block User
                </li>
                <li onClick={this.reportUser}>Report User</li>
              </ul>
              <div
                onClick={() => this.setState({ toggleAction: false })}
                style={{
                  height: '100vh',
                  width: '100%',
                  backgroundColor: 'black',
                  opacity: '0.4',
                }}
              ></div>
            </div>
          ) : (
            ''
          )}
          {peer && peer.available.toLowerCase() === 'yes' ? (
            <button
              onClick={this.onCallClick}
              className="btn btn-success btn-block btn-lg"
              style={{
                borderRadius: '0',
                zIndex: '299',
              }}
            >
              Call Now!
            </button>
          ) : (
            <button
              onClick={this.onCallClick}
              className="btn btn-de btn-block btn-lg"
              style={{
                backgroundColor: 'lightgray',
                opacity: 1,
                borderRadius: '0',
                zIndex: '299',
              }}
              disabled
            >
              Unavailable
            </button>
          )}
        </div>
        <div
          id="chat-messages"
          style={{
            position: 'fixed',
            top: '88px',
            left: '0',
            right: '0',
            height: 'calc(100% - 46px - 88px)',
            overflowY: 'scroll',
            WebkitOverflowScrolling: 'touch',
            backgroundColor: 'white',
            zIndex: -1,
          }}
        >
          {rows}
        </div>
        <div
          style={{
            position: 'fixed',
            bottom: '0',
            left: '0',
            right: '0',
            overflowX: 'hidden',
            overflowY: 'hidden',
            width: '100%',
            zIndex: -1,
          }}
        >
          <div className="button">
            <label htmlFor="multi">
              <FaCamera
                style={{
                  height: '22px',
                  width: '22px',
                  top: '30',
                  left: '15',
                  position: 'absolute',
                }}
              />
            </label>
            <input
              type="file"
              accept=".jpg, .png, image/jpeg, image/png"
              id="multi"
              style={{ display: 'none' }}
              onChange={this.onSendPhotoClick}
            />
          </div>

          <FaPaperPlane
            style={{
              height: '22px',
              width: '22px',
              top: '30',
              right: '15',
              position: 'absolute',
            }}
            onClick={this.onSendClick}
          />
          <input
            style={{
              border: 'none',
              width: '100%',
              padding: '0 65px',
              boxShadow: 'none',
            }}
            id="message"
            type="text"
            autoComplete="off"
            className="form-control input-lg"
            placeholder="Type a message..."
          />
          {/* <button
            
            className="send btn btn-success btn-block btn-lg"
          >
            Send
          </button> */}
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch,
  actions: bindActionCreators(
    {
      ...userActions,
    },
    dispatch
  ),
})

export default withLastLocation(
  connect(
    null,
    mapDispatchToProps
  )(Chat)
)
