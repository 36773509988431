import React, { Component } from 'react'
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux'
import configureStore, { history } from './store/index'

import Routes from './routes'
import Loader from 'components/Loader'
import { PersistGate } from 'redux-persist/integration/react'

const { store, persistor } = configureStore()
class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ConnectedRouter history={history}>
            <div className="app">
              <div id="page-wrapper">
                <Routes />
              </div>
              <Loader />
            </div>
          </ConnectedRouter>
        </PersistGate>
      </Provider>
    )
  }
}

export default App
