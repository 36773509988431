import Session from './Session'

import Api from './v2'

let loadingCount = 0
const subscribers = []
const origin = process.env.REACT_APP_ORIGIN

/** headers generator
 * If a custom token and username are provided, use them instead.
 * @param {String} contentType
 * @param {String|null} token
 * @param {String|null} username
 *
 */
export function headers(contentType, token = null, username = null) {
  /** @type {HeadersInit} */

  const headers = contentType ? { 'Content-Type': contentType } : {}

  if (token && username) {
    headers['Username'] = username
    headers['Authorization'] = `Bearer ${token}`
    return headers
  }

  if (Session.currentUser().username && Session.getToken()) {
    headers['Username'] = Session.currentUser().username
    headers['Authorization'] = 'Bearer ' + Session.getToken()
    headers['Accept'] = 'application/json'
  }
  return headers
}

/** @param {String} key */
function getStoredItem(key) {
  const cache = localStorage.getItem(key)
  if (cache) {
    try {
      return JSON.parse(cache)
    } catch (e) {
      console.log(e)
      localStorage.removeItem(key)
    }
  }
}

/** @param {Object.<string, object>} obj */
function truncateArrays(obj) {
  Object.keys(obj).forEach(key => {
    if (obj[key] instanceof Array) {
      obj[key] = obj[key].slice(0, 10)
    }
  })
  return obj
}

/** @param {String} key */
/** @param {Object.<string, object>} obj */
function setStoredItem(key, obj) {
  if (!(obj instanceof Object)) {
    return
  }
  obj = truncateArrays(obj)
  try {
    localStorage.setItem(key, JSON.stringify(obj))
  } catch (e) {
    console.log(e)
  }
}

/**
 * @param {String} url
 * @param {function(Error|null, Object.<string, object>): void} cb
 * @param {Boolean} [noSpinner=false]
 */
function get(url, cb, noSpinner = false, customHeaders) {
  !noSpinner && incrementLoad()
  let timedOut = false
  const storedItem = getStoredItem(url)
  const timeout = storedItem ? 5000 : 30000
  const interval = setTimeout(() => {
    !noSpinner && decrementLoad()
    timedOut = true
    cb(new Error('timeout'), storedItem)
  }, timeout)

  return fetch(origin + url, {
    headers: customHeaders || headers('application/json'),
    method: 'GET',
  })
    .then(response => {
      if (!response.ok) {
        Session.removeToken()
      }
      return response.json()
    })
    .then(json => {
      if (!timedOut) {
        clearInterval(interval)
        !noSpinner && decrementLoad()
        cb(null, json)
      }
      setStoredItem(url, json)
    })
    .catch(e => {
      !noSpinner && decrementLoad()
      cb(e, storedItem)
    })
}

/**
 * @param {String} url
 * @param {function(Error|null, Object.<string, object>): void} cb
 */
function getSilent(url, cb) {
  get(url, cb, true)
}

/**
 * @param {String} url
 * @param {function(Error|null, Object.<string, object>): void} cacheBack
 * @param {function(Error|null, Object.<string, object>): void} [cb]
 */
function getCacheThenResponse(url, cacheBack, cb) {
  cb = cb || cacheBack
  let cachedItem = getStoredItem(url)
  if (cachedItem) {
    cb(null, cachedItem)
    return getSilent(url, cb)
  } else {
    return get(url, cb)
  }
}

/**
 * @param {String} url
 * @param {function(Error|null, Object.<string, object>|null): void} cb
 */
function post(url, params, cb, customHeaders = null) {
  incrementLoad()
  const searchParams = Object.keys(params)
    .map(key => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
    })
    .join('&')

  return fetch(origin + url, {
    headers: customHeaders || headers('application/x-www-form-urlencoded'),
    body: searchParams,
    method: 'POST',
  })
    .then(response => {
      if (!response.ok) {
        Session.removeToken()
      }
      return response.json()
    })
    .then(json => {
      decrementLoad()
      cb(null, json)
    })
    .catch(e => {
      decrementLoad()
      cb(e, null)
    })
}

/**
 * @param {String} url
 * @param {function(Error|null, Object.<string, object>|null): void} cb
 */
function postFile(url, body, cb, customHeaders = null) {
  return fetch(origin + url, {
    headers: customHeaders || headers('multipart/form-data'),
    body,
    method: 'POST',
  })
    .then(response => {
      if (!response.ok) {
        Session.removeToken()
      }
      return response.json()
    })
    .then(json => {
      decrementLoad()
      cb(null, json)
    })
    .catch(e => {
      decrementLoad()
      cb(e, null)
    })
}

function decrementLoad() {
  loadingCount--
  emit()
}

function incrementLoad() {
  loadingCount++
  emit()
}

function subscribe(cb) {
  subscribers.push(cb)
}

function emit() {
  subscribers.forEach(cb => cb(loadingCount))
}

export default {
  get,
  getSilent,
  post,
  postFile,
  subscribe,
  getCacheThenResponse,
  v2: new Api(),
}
