import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { push } from 'connected-react-router'
import UserCell from 'components/UserCell'
import SearchBar from 'components/SearchBar'
import * as usersActions from 'reducers/users'
import * as userActions from 'reducers/user'

class Followers extends Component {
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.searchTerm !== '' &&
      prevProps.searchTerm !== this.props.searchTerm
    ) {
      this.fetch()
    }
  }

  componentDidMount() {
    this.fetch()
    this.fetchFollower()
  }

  fetchFollower = () => {
    const {
      actions: { fetchFollowers },
    } = this.props
    fetchFollowers()
  }

  fetch = () => {
    const {
      searchTerm,
      actions: { searchFollowers, fetchFollowers, getFollowerInfo },
    } = this.props
    getFollowerInfo()
    if (searchTerm === undefined) fetchFollowers()

    if (searchTerm) {
      return searchFollowers(searchTerm)
    }
  }

  onSearch = str => {
    const { dispatch } = this.props

    if (str && str !== '') {
      dispatch(push(`/search/followers/${str}`))
    } else {
      dispatch(push(`/search/followers`))
    }
  }

  updateGenderFilterToggle = async prefers_gender => {
    const {
      actions: { updateUser, fetchFollowers },
    } = this.props

    await updateUser({ prefers_gender })
    fetchFollowers()
  }

  render() {
    const {
      users: { followers, followersInfoMsg },
      user,
      isSearchingUsers,
      isLoading,
      searchTerm,
    } = this.props
    const rows = followers
      ? followers.map(follower => (
          <UserCell
            key={follower.id}
            user={follower}
            is_favorite={follower.is_favorite}
          />
        ))
      : []

    return (
      <div>
        {user && (
          <div
            style={{
              position: 'fixed',
              top: '0',
              left: '0',
              right: '0',
            }}
          >
            <SearchBar
              user={user}
              onGenderFilterToggle={this.updateGenderFilterToggle}
              selected="followers"
              onSearch={this.onSearch}
              value={searchTerm}
            />
          </div>
        )}
        <div
          style={{
            position: 'fixed',
            top: 'calc(107px)',
            left: '0',
            right: '0',
            height: 'calc(100% - 107px - 34px)',
            overflowX: 'hidden',
            overflowY: 'scroll',
            WebkitOverflowScrolling: 'touch',
            backgroundColor: 'white',
          }}
        >
          <div
            id="message"
            style={{
              color: 'white',
              backgroundColor: '#222',
              padding: '10px 15px',
            }}
          >
            {followersInfoMsg}
          </div>
          <table style={{ width: '100%' }}>
            <tbody>
              {rows.length ? (
                rows
              ) : isLoading || isSearchingUsers ? (
                <tr>
                  <td>Loading...</td>
                </tr>
              ) : (
                <tr>
                  <td>No Results.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { searchTerm },
    },
  } = ownProps

  return {
    searchTerm,
    isSearchingUsers: state.users.isSearching,
    isLoading: state.users.isLoading, // forces a re-render
    users: state.users ? state.users : null,
    user: state.user ? state.user : null,
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch,
  actions: bindActionCreators(
    {
      ...usersActions,
      ...userActions,
    },
    dispatch
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Followers)
