import { applyMiddleware, compose, createStore } from 'redux'

import Api from '../api/index'
import { createBrowserHistory } from 'history'
import rootReducer from 'reducers'
import { routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import UserTransform from './transforms'

export const history = createBrowserHistory()

export default () => {
  const initialState = {}
  const enhancers = []
  const middleware = [thunk.withExtraArgument(Api), routerMiddleware(history)]

  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-underscore-dangle
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension())
    }
  }

  const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
  )

  const persistConfig = {
    key: 'root',
    storage,
    transforms: [UserTransform],
    whitelist: ['user', 'users', 'interests', 'userDetails', 'interestDetails'],
  }

  const persistedReducer = persistReducer(persistConfig, rootReducer)

  const store = createStore(persistedReducer, initialState, composedEnhancers)

  const persistor = persistStore(store)

  return { store, persistor }
}
