import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import FloatingLabel from 'floating-label-react'
import { Formik } from 'formik'
import cx from 'classnames'
import * as Yup from 'yup'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as userActions from 'reducers/user'

const VALIDATION = {
  phone_number: Yup.string().max(10),
}
class PasswordRecoverySms extends Component {
  onBack = () => {
    const { history } = this.props
    history.goBack()
  }

  recoverPassword = async phone_number => {
    const {
      history,
      actions: { recoverPassViaNumber },
    } = this.props
    const result = await recoverPassViaNumber(phone_number)
    if (result.error)
      history.push({
        pathname: '/forgot-password/sms/not-found',
        state: phone_number,
      })
    else
      history.push({
        pathname: '/forgot-password/sms/link-sent',
        state: phone_number,
      })
  }

  render() {
    return (
      <div
        style={{
          backgroundColor: '#191919',
          position: 'fixed',
          left: '0',
          right: '0',
          height: '100%',
          color: '#fff',
        }}
      >
        <div
          style={{ marginTop: '20px', marginLeft: '20px' }}
          onClick={this.onBack}
        >
          <i
            style={{ color: 'white', fontSize: 'x-large' }}
            className="fa fa-angle-left"
          />
        </div>
        <div className="container-fluid">
          <h3 style={{ marginBottom: '20px' }}>Forgot Password?</h3>
          <p style={{ marginBottom: '10px' }}>
            Enter your phone number and we'll send you a link to log in.
          </p>
          <div className="signup">
            <Formik
              validateOnBlur={false}
              initialValues={{ phone_number: '' }}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false)
                this.recoverPassword(values)
              }}
              validationSchema={Yup.object().shape(VALIDATION)}
            >
              {props => {
                const {
                  values,
                  touched,
                  errors,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                } = props
                return (
                  <form className="form" onSubmit={handleSubmit}>
                    <div
                      className="form-group"
                      style={{ marginBottom: '30px' }}
                    >
                      <FloatingLabel
                        id="phone_number"
                        placeholder="Phone Number"
                        type="tel"
                        autoComplete="off"
                        value={values.phone_number}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={cx('', {
                          'has-error':
                            errors.phone_number && touched.phone_number,
                        })}
                      />
                      {errors.phone_number && touched.phone_number && (
                        <div className="invalid-feedback">
                          {errors.phone_number}
                        </div>
                      )}
                    </div>
                    <button
                      className="btn btn-primary btn-block btn-lg"
                      style={{
                        borderRadius: '3px',
                        backgroundColor: values.phone_number
                          ? '#00abea'
                          : '#8e8b8f',
                        color: 'white',
                        borderColor: values.phone_number
                          ? '#00abea'
                          : '#8e8b8f',
                        marginTop: '10px',
                        marginBottom: '15px',
                        fontSize: '1.4em',
                        height: '50px',
                      }}
                      onClick={() => console.log('object')}
                      disabled={isSubmitting}
                    >
                      Next
                    </button>
                  </form>
                )
              }}
            </Formik>
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...userActions,
    },
    dispatch
  ),
})

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(PasswordRecoverySms)
)
