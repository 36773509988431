import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import ActiveChatSessionCell from 'components/ActiveChatSessionCell'
import * as usersActions from 'reducers/users'
import { getUsers } from 'store/selectors'
import Swipeout from 'rc-swipeout'

class Messages extends Component {
  componentDidMount() {
    this.fetch()
  }

  fetch = () => {
    const {
      actions: { fetchActiveChatSessions },
    } = this.props
    fetchActiveChatSessions()
  }

  onClick = username => {
    const { dispatch } = this.props
    dispatch(push(`/chat/${username}`))
  }

  render() {
    const {
      activeChatSessions,
      isLoading,
      username,
      actions: { deleteChatByChatID },
    } = this.props

    const rows = activeChatSessions.map(activeChatSession => (
      <Swipeout
        right={[
          {
            text: 'delete',
            onPress: () =>
              deleteChatByChatID(activeChatSession.id).then(() => {
                this.fetch()
              }),
            style: { backgroundColor: '#d22421', color: 'white' },
          },
        ]}
        onOpen={() => console.log('open')}
        onClose={() => console.log('close')}
      >
        <ActiveChatSessionCell
          key={activeChatSession.id}
          activeChatSession={activeChatSession}
          isLoading={isLoading}
          onClick={this.onClick}
          me={username}
        />
      </Swipeout>
    ))

    return (
      <div
        style={{
          position: 'fixed',
          top: '0',
          left: '0',
          right: '0',
          height: 'calc(100% - 34px)',
          overflowX: 'hidden',
          overflowY: 'scroll',
          WebkitOverflowScrolling: 'touch',
          backgroundColor: 'white',
        }}
      >
        <div style={{ width: '100%' }}>
          {rows.length ? (
            rows
          ) : isLoading ? (
            <div>
              <div>Loading...</div>
            </div>
          ) : (
            <div>
              <td>You have no messages.</td>
            </div>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    isLoading: state.users.isLoading, // forces a re-render
    activeChatSessions: getUsers(state, `/messages`),
    username: state.user.username,
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch,
  actions: bindActionCreators(
    {
      ...usersActions,
    },
    dispatch
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Messages)
