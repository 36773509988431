import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as userActions from 'reducers/user'
import { goBack } from 'connected-react-router'
import { withRouter } from 'react-router-dom'
import NavBar from 'components/NavBar'
import PendingPayment from 'components/PendingPayment'
import PaymentHistory from 'components/PaymentHistory'
import EarnedUens from 'components/EarnedUens'
import CallHistory from 'components/CallHistory'
import { withLastLocation } from 'react-router-last-location'

class SellersConsole extends Component {
  onBackClick = () => {
    if (!this.props.lastLocation) {
      return this.props.history.push('/search/users')
    } else {
      const { dispatch } = this.props
      dispatch(goBack())
    }
  }

  componentDidMount() {
    this.fetchPayments()
  }

  fetchPayments = () => {
    const {
      actions: {
        getEarnedUens,
        getPaymentHistory,
        getPendingPayment,
        getCallHistory,
        getCashoutMessage,
      },
    } = this.props
    getPaymentHistory()
    getEarnedUens()
    getPendingPayment()
    getCallHistory()
    getCashoutMessage()
  }

  render() {
    const { user } = this.props

    return (
      <div
        style={{
          position: 'fixed',
          top: '0',
          left: '0',
          right: '0',
        }}
      >
        <NavBar title={`Seller Console`} onBackClick={this.onBackClick} />
        <div
          style={{
            position: 'fixed',
            top: 'calc(1.5em + 20px)',
            left: '0',
            right: '0',
            height: 'calc(100% - 1.5em - 20px)',
            overflowX: 'hidden',
            overflowY: 'scroll',
            WebkitOverflowScrolling: 'touch',
            backgroundColor: '#222222',
          }}
        >
          {isNaN(user.earned_uens) === false ? (
            <EarnedUens user={user} />
          ) : null}
          <div className="container-fluid">
            <button
              className="btn btn-block btn-lg"
              disabled={user && user.earned_uens ? false : true}
              style={{
                color: 'white',
                backgroundColor: user && user.earned_uens ? '#01a3d0' : 'gray',
                marginTop: '15px',
              }}
              onClick={() =>
                this.props.history.push('/account/sellers-console/cash-out')
              }
            >
              Cash Out
            </button>
            <button
              className="btn btn-block btn-lg"
              style={{
                marginTop: '15px',
                borderColor: '#01a3d0',
                backgroundColor: '#222222',
                color: '#01a3d0',
              }}
              onClick={() =>
                (window.location.href = 'https://www.uenme.co/support')
              }
            >
              Get Help
            </button>
            {user && user.pendingPayment && user.pendingPayment.length !== 0 ? (
              <PendingPayment pendingPayment={user.pendingPayment} />
            ) : null}
            <div style={{ color: 'white', marginTop: '20px' }}>
              <p style={{ lineHeight: '1.5em' }}>{user.cashoutMessage}</p>
            </div>
            {user && user.paymentHistory && user.paymentHistory.length !== 0 ? (
              <PaymentHistory paymentHistory={user.paymentHistory} />
            ) : null}
            {user && user.callHistory ? (
              <CallHistory callHistory={user.callHistory} user={user} />
            ) : null}
            <br />
            <br />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user ? state.user : {},
})

const mapDispatchToProps = dispatch => ({
  dispatch,
  actions: bindActionCreators(
    {
      ...userActions,
    },
    dispatch
  ),
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withLastLocation(SellersConsole))
)
