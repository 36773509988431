import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as userActions from 'reducers/user'

import { SignInForm } from './Forms'

class SigninPage extends Component {
  setAppDetails = () => {}
  render() {
    const {
      user,
      actions: { signInUser, setAppDetails },
    } = this.props

    return (
      <div
        style={{
          backgroundColor: '#191919',
          position: 'fixed',
          top: '0',
          left: '0',
          right: '0',
          height: '100%',
          overflowX: 'hidden',
          overflowY: 'scroll',
          WebkitOverflowScrolling: 'touch',
          color: '#fff',
        }}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12" style={{ paddingTop: '30px' }}>
              <SignInForm
                user={user}
                onSubmit={signInUser}
                setAppDetails={setAppDetails}
              />
              <br />
              <br />
              <Link to="/signup">Sign Up</Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user ? state.user : {}, // FIXME: user will contain an error message in this context, not a user.
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...userActions,
    },
    dispatch
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SigninPage)
