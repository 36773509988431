import React, { Component } from 'react'
import NavBar from 'components/NavBar'
import InterestCell from 'components/InterestCell'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import cx from 'classnames'
import { getUserInterests, getUser } from 'store/selectors'

import * as usersActions from 'reducers/users'
import * as userActions from 'reducers/user'
import * as userDetailActions from 'reducers/userDetails'
import * as interestsActions from 'reducers/interests'
import { goBack, push } from 'connected-react-router'
import swal from 'sweetalert'
import styles from './UserProfile.module.scss'
import Session from 'api/Session'
import { withLastLocation } from 'react-router-last-location'
import { Link } from 'react-router-dom'

class User extends Component {
  static defaultProps = {
    userInterests: [],
  }

  state = {
    toggleAction: false,
  }

  constructor(props) {
    super(props)
    this.clickedShare = this.clickedShare.bind(this)
  }

  componentDidMount() {
    this.fetch()
  }

  fetch = () => {
    const {
      match: {
        params: { username },
      },
    } = this.props
    const {
      actions: { fetchUserDetails },
    } = this.props
    fetchUserDetails(username)
  }

  onFavoriteClick = e => {
    const {
      user: { username, is_favorite },
      actions: { favoriteUserByUsername, unfavoriteUserByUsername },
    } = this.props
    const action = !is_favorite
      ? favoriteUserByUsername
      : unfavoriteUserByUsername
    action(username)
  }

  onChatClick = e => {
    const {
      dispatch,
      match: {
        params: { username },
      },
    } = this.props
    e.preventDefault()
    dispatch(push(`/chat/${username}`))
  }

  onNegotiationClick = (e, available) => {
    const {
      dispatch,
      match: {
        params: { username },
      },
    } = this.props
    e.preventDefault()
    if (available.toUpperCase() !== 'NO') {
      dispatch(push(`/negotiation/${username}`))
    } else {
      dispatch(push(`/chat/${username}`))
    }
  }

  onBackClick = () => {
    if (!this.props.lastLocation) {
      return this.props.history.push('/search/users')
    } else {
      const { dispatch } = this.props
      dispatch(goBack())
    }
  }

  onActionClick = () => {
    this.setState({ toggleAction: !this.state.toggleAction })
  }

  onTitleClick = () => {
    //alert('Title Click.')
  }

  onToggleInterest = (interest, isMutual) => {
    const {
      actions: { addInterest, removeInterest },
    } = this.props

    if (isMutual) {
      removeInterest(interest)
    } else {
      addInterest(interest)
    }
  }

  blockuser = async e => {
    e.stopPropagation()
    this.setState({ toggleAction: false })
    const {
      dispatch,
      user: { username },
      actions: { blockUser },
    } = this.props
    const res = await blockUser(username)
    if (res) swal('User blocked', username, { className: styles.swalModalTwo })
    dispatch(goBack())
  }

  reportUser = async e => {
    try {
      e.stopPropagation()
      this.setState({ toggleAction: false })
      const {
        dispatch,
        user: { username },
        actions: { reportUser },
      } = this.props
      // @ts-ignore
      const reason = await swal({
        title: 'Why? (Optional)',
        text: 'What did they do? ',
        content: 'input',
        className: styles.swalModal,
        buttons: {
          cancel: 'Cancel',
          submit: {
            text: 'Submit',
            closeModal: false,
          },
        },
      })
      if (reason != null) {
        reportUser(username, reason)
        swal('User reported', username, { className: styles.swalModalTwo })
        dispatch(goBack())
      }
    } catch (error) {
      swal('Error', error, { className: styles.swalModalTwo })
    }
  }

  callOrChat = (e, available) => {
    const {
      dispatch,
      match: {
        params: { username },
      },
    } = this.props
    e.preventDefault()
    if (available.toUpperCase() === 'NO') {
      dispatch(push(`/chat/${username}`))
    } else {
      dispatch(push(`/negotiation/${username}`))
    }
  }

  clickedShare = link => {}

  render() {
    const {
      user,
      userInterests,
      isLoadingInterest,
      isFavorite,
      username,
    } = this.props
    const currentUser = Session.currentUser().username

    if (!user || !user.id) {
      return null
    }
    const origin = process.env.REACT_APP_ORIGIN
    return (
      <div>
        <div
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            right: '0',
            zIndex: 2,
            height: 'calc(1.5em + 20px)',
          }}
        >
          <NavBar
            title={`@${user.username}`}
            onBackClick={this.onBackClick}
            onActionClick={currentUser !== username ? this.onActionClick : null}
            onTitleClick={this.onTitleClick}
          />
          {this.state.toggleAction ? (
            <ul
              style={{
                background: '#fff',
                position: 'absolute',
                right: '0',
                color: '#43afdc',
                listStyle: 'none',
                padding: '10px 17px',
                marginBottom: '0',
                zIndex: 2,
              }}
            >
              <li style={{ paddingBottom: '5px' }} onClick={this.blockuser}>
                Block User
              </li>
              <li onClick={this.reportUser}>Report User</li>
            </ul>
          ) : (
            ''
          )}
          {this.state.toggleAction ? (
            <div
              onClick={() => this.setState({ toggleAction: false })}
              style={{
                height: '100vh',
                width: '100%',
                backgroundColor: 'black',
                opacity: '0.4',
              }}
            ></div>
          ) : (
            ''
          )}
        </div>
        <div
          style={{
            position: 'fixed',
            top: 'calc(1.5em + 20px)',
            left: '0',
            right: '0',
            height:
              currentUser === username
                ? 'calc(100% - 1.5em - 20px - 34px)'
                : 'calc(100% - 1.5em - 20px - 34px - 51px)',
            overflowX: 'hidden',
            overflowY: 'scroll',
            WebkitOverflowScrolling: 'touch',
            backgroundColor: 'white',
          }}
        >
          <div className="user-img-wrapper">
            <img src={`${origin}/uploads/${user.photo}`} alt="User" />
          </div>
          <div className="row container-fluid">
            <div className="col-xs-12">
              <h3 style={{ marginBottom: '15px' }}>
                {user.fullname ? user.fullname : user.username}
              </h3>
              {isFavorite && currentUser !== username ? (
                <figure
                  className={cx('fa fa-star fa-lg')}
                  style={{
                    color: '#43afdc',
                    float: 'right',
                    marginTop: '-37px',
                  }}
                  onClick={this.onFavoriteClick}
                />
              ) : currentUser !== username ? (
                <figure
                  className={cx('fa fa-star-o fa-lg')}
                  style={{
                    color: '#43afdc',
                    float: 'right',
                    marginTop: '-37px',
                  }}
                  onClick={this.onFavoriteClick}
                />
              ) : null}

              <h5 style={{ marginBottom: '15px' }}>
                <b>
                  <Link
                    rel="noopener noreferrer"
                    style={{ color: '#333333' }}
                    to={'/user/' + user.username}
                  >
                    uenme.co/user/{user.username}
                  </Link>
                </b>
                <figure
                  className="fa fa-share-square fa-lg"
                  style={{
                    color: 'gray',
                    marginLeft: '15px',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    const url = `${window.location.origin}/user/${user.username}`
                    try {
                      navigator.share({ url })
                    } catch (err) {
                      navigator.clipboard.writeText(url)
                    }
                  }}
                />
              </h5>
            </div>
          </div>
          <div className="row container-fluid">
            <div className="col-xs-12">
              <p>{user.about}</p>
            </div>
          </div>
          <table style={{ width: '100%' }}>
            <tbody>
              {userInterests.map(interest => (
                <InterestCell
                  key={interest.id}
                  interest={interest}
                  onToggleInterest={this.onToggleInterest}
                  isLoadingInterest={isLoadingInterest}
                />
              ))}
            </tbody>
          </table>
        </div>

        {currentUser !== username ? (
          <div
            style={{
              position: 'fixed',
              bottom: '34px',
              left: '0',
              right: '0',
              backgroundColor:
                user.available.toUpperCase() === 'YES' ? '#51c892' : '#c80046',
              color: 'white',
              padding: '8px 0',
              fontSize: '25px',
            }}
          >
            <div className="row">
              <div className="col-xs-8">
                <div className="row">
                  <div className="col-xs-4" style={{ textAlign: 'center' }}>
                    <figure
                      className={cx('fa fa-phone')}
                      onClick={e => this.onNegotiationClick(e, user.available)}
                    />
                  </div>
                  <div
                    className="col-xs-8"
                    style={{ marginLeft: '-30px' }}
                    onClick={e => this.callOrChat(e, user.available)}
                  >
                    <h4 style={{ margin: '0' }}>
                      {user.available.toUpperCase() === 'YES'
                        ? 'Call Now'
                        : 'Unavailable'}
                    </h4>
                    <h6 style={{ margin: '0' }}>
                      {parseInt(user.cost_per_min) === 0
                        ? 'FREE'
                        : parseInt(user.cost_per_min) === 1
                        ? '1 UEN PER MIN'
                        : `${parseInt(user.cost_per_min)} UENS PER MIN`}
                    </h6>
                  </div>
                </div>
              </div>
              <div className="col-xs-4" style={{ textAlign: 'center' }}>
                <figure
                  className={cx('fa fa-comment')}
                  onClick={this.onChatClick}
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { username },
    },
  } = ownProps
  const user = getUser(state, username)
  return {
    username,
    isSearchingUsers: state.users.isSearching,
    isLoadingInterest: state.interests.isLoading,
    userInterests: getUserInterests(state, username),
    user,
    isFavorite: user.is_favorite,
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch,
  actions: bindActionCreators(
    {
      ...usersActions,
      ...userActions,
      ...userDetailActions,
      ...interestsActions,
    },
    dispatch
  ),
})

export default withLastLocation(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(User)
)
