import React from 'react'
import { withRouter } from 'react-router-dom'
// @ts-ignore
import magiclink from '../../../../../assets/images/magic-link-graphic.png'

const SmsNotFound = props => {
  const onBack = () => {
    const { history } = props
    history.goBack()
  }

  return (
    <div
      style={{
        backgroundColor: '#2c2d2f',
        position: 'fixed',
        left: '0',
        right: '0',
        height: '100%',
        color: '#fff',
      }}
    >
      <div style={{ marginTop: '20px', marginLeft: '20px' }} onClick={onBack}>
        <i
          style={{ color: 'white', fontSize: 'x-large' }}
          className="fa fa-angle-left"
        />
      </div>
      <div className="container-fluid">
        <h3 style={{ marginBottom: '20px' }}>Magic Link not sent.</h3>
        <p style={{ marginBottom: '20px' }}>
          We couldn't find anyone with the number{' '}
          {props.location.state.phone_number}.
        </p>
      </div>
      <div style={{ margin: 'auto', width: '50%', padding: '10px' }}>
        <img src={magiclink} alt="" style={{ width: '100%' }} />
      </div>
    </div>
  )
}

export default withRouter(SmsNotFound)
