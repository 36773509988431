import React, { Component } from 'react'
import { goBack } from 'connected-react-router'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import * as userActions from 'reducers/user'
import { bindActionCreators } from 'redux'
import NavBar from 'components/NavBar'
import EarnedUens from 'components/EarnedUens'
import { Formik } from 'formik'
import cx from 'classnames'
import * as Yup from 'yup'
import Checkbox from 'react-simple-checkbox'
import { states } from './states'
import { countries } from './countries'
import swal from 'sweetalert'
import { withLastLocation } from 'react-router-last-location'

class CashOut extends Component {
  VALIDATION = {
    first_name: Yup.string().required('First Name is required.'),
    last_name: Yup.string().required('Last Name is required.'),
    phone: Yup.string(),
    street_address: Yup.string().required('Address is required.'),
    city: Yup.string().required('City is required.'),
    state: Yup.string()
      .notRequired()
      .when('country', {
        is: country => country === 'United States',
        then: Yup.string().required('State is required.'),
      }),
    zipcode: Yup.string()
      .required()
      .when('country', {
        is: country => country === 'United States',
        then: Yup.string().required('Zipcode is required.'),
      }),
    country: Yup.string(),
    venmo_email: Yup.string().email(),
  }

  constructor(props) {
    super(props)
    this.state = {
      selectPaypal: false,
      selectBank: false,
      country: '',
    }
  }

  componentDidMount() {
    this.getFinancialInfo().then(() => {
      this.setPaymentMethod()
    })
  }

  setPaymentMethod = () => {
    const {
      user: { financialInfo },
    } = this.props
    if (financialInfo) {
      this.setState({
        selectPaypal: financialInfo.destination === 'email' ? true : false,
        selectBank: financialInfo.destination === 'bank' ? true : false,
        country: financialInfo ? financialInfo.country : '',
      })
    }
  }

  onBackClick = () => {
    if (!this.props.lastLocation) {
      return this.props.history.push('/search/users')
    } else {
      const { dispatch } = this.props
      dispatch(goBack())
    }
  }

  getFinancialInfo = () => {
    const {
      actions: { getFinancialInfo },
    } = this.props
    return getFinancialInfo()
  }

  finishedPayment = () => {
    this.props.history.push('/account')
    window.open('https://www.jotform.com/form/213546975467066', '_blank')
  }

  updateFinancialInfo = async values => {
    const {
      actions: {
        updateFinancialInfo,
        validateRoutingNumber,
        validateBankAccount,
        queueDistribution,
        createSubmerchant,
      },
      user,
    } = this.props
    let funding_destination
    this.state.selectPaypal === true
      ? (funding_destination = 'email')
      : (funding_destination = 'bank')
    if (funding_destination === 'bank') {
      const isBankNumberValid = await validateBankAccount(values.account_number)
      const isRoutingNumberValid = await validateRoutingNumber(
        values.routing_number
      )
      if (values.account_number !== '' && values.routing_number !== '') {
        if (
          values.account_number === values.confirm_account_number &&
          values.routing_number === values.confirm_routing_number
        ) {
          if (isBankNumberValid.isValid && isRoutingNumberValid.isValid) {
            if (user.submerchant_exits) {
              const updateResult = await updateFinancialInfo({
                ...values,
                funding_destination,
              })
              if (updateResult) {
                this.getFinancialInfo()
                const cashoutResult = await queueDistribution()
                if (cashoutResult.success === 1) {
                  swal({
                    title: cashoutResult.success_title,
                    text: cashoutResult.success_message,
                  }).then(this.finishedPayment)
                } else {
                  swal({
                    title: cashoutResult.error_title,
                    text: cashoutResult.error_message,
                    dangerMode: true,
                  })
                }
              }
            } else {
              const createResult = await createSubmerchant({
                ...values,
                funding_destination,
              })
              if (createResult) {
                this.getFinancialInfo()
                const cashoutResult = await queueDistribution()
                if (cashoutResult.success === 1) {
                  swal({
                    title: cashoutResult.success_title,
                    text: cashoutResult.success_message,
                  }).then(this.finishedPayment)
                } else {
                  swal({
                    title: cashoutResult.error_title,
                    text: cashoutResult.error_message,
                    dangerMode: true,
                  })
                }
              }
            }
          } else {
            swal('Invalid Account Number or Routing Number.')
          }
        } else {
          swal(
            'Either Account number and Confirm Account number or Routing number and Confirm Routing number do not match.'
          )
        }
      } else {
        swal('Either Account number or Routing number is empty.')
      }
    } else {
      if (user.submerchant_exits) {
        const updateResult = await updateFinancialInfo({
          ...values,
          funding_destination,
        })
        if (updateResult) {
          this.getFinancialInfo()
          const cashoutResult = await queueDistribution()
          if (cashoutResult.success === 1) {
            swal({
              title: cashoutResult.success_title,
              text: cashoutResult.success_message,
            }).then(this.finishedPayment)
          } else {
            swal({
              title: cashoutResult.error_title,
              text: cashoutResult.error_message,
              dangerMode: true,
            })
          }
        }
      } else {
        const createResult = await createSubmerchant({
          ...values,
          funding_destination,
        })
        if (createResult) {
          this.getFinancialInfo()
          const cashoutResult = await queueDistribution()
          if (cashoutResult.success === 1) {
            swal({
              title: cashoutResult.success_title,
              text: cashoutResult.success_message,
            }).then(this.finishedPayment)
          } else {
            swal({
              title: cashoutResult.error_title,
              text: cashoutResult.error_message,
              dangerMode: true,
            })
          }
        }
      }
    }
  }

  render() {
    const {
      user,
      user: { financialInfo },
    } = this.props
    return (
      <div
        style={{
          position: 'fixed',
          top: '0',
          left: '0',
          right: '0',
        }}
      >
        <NavBar title={`Cash Out`} onBackClick={this.onBackClick} />
        <div
          style={{
            position: 'fixed',
            top: 'calc(1.5em + 20px)',
            left: '0',
            right: '0',
            height: 'calc(100% - 1.5em - 20px - 34px)',
            overflowX: 'hidden',
            overflowY: 'scroll',
            WebkitOverflowScrolling: 'touch',
            backgroundColor: '#222222',
          }}
        >
          {isNaN(user.earned_uens) === false ? (
            <EarnedUens user={user} />
          ) : null}
          <Formik
            enableReinitialize
            initialValues={{
              first_name:
                financialInfo && financialInfo.first_name
                  ? financialInfo.first_name
                  : '',
              last_name:
                financialInfo && financialInfo.last_name
                  ? financialInfo.last_name
                  : '',
              phone:
                financialInfo && financialInfo.phone ? financialInfo.phone : '',
              street_address:
                financialInfo && financialInfo.street_address
                  ? financialInfo.street_address
                  : '',
              city:
                financialInfo && financialInfo.city ? financialInfo.city : '',
              state:
                financialInfo && financialInfo.state ? financialInfo.state : '',
              zipcode:
                financialInfo && financialInfo.zipcode
                  ? financialInfo.zipcode
                  : '',
              country:
                financialInfo && financialInfo.country
                  ? financialInfo.country
                  : '',
              account_number:
                financialInfo && financialInfo.account_number
                  ? financialInfo.account_number
                  : '',
              routing_number:
                financialInfo && financialInfo.routing_number
                  ? financialInfo.routing_number
                  : '',
              confirm_account_number:
                financialInfo && financialInfo.confirm_account_number
                  ? financialInfo.confirm_account_number
                  : '',
              confirm_routing_number:
                financialInfo && financialInfo.confirm_routing_number
                  ? financialInfo.confirm_routing_number
                  : '',
              venmo_email:
                financialInfo && financialInfo.venmo_email
                  ? financialInfo.venmo_email
                  : '',
            }}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false)
              this.updateFinancialInfo(values)
            }}
            validationSchema={Yup.object().shape(this.VALIDATION)}
          >
            {props => {
              const {
                values,
                touched,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              } = props

              return (
                <form
                  id="aboutyouform"
                  className="form"
                  style={{
                    backgroundColor: '#222222',
                    color: 'white',
                  }}
                  onSubmit={handleSubmit}
                >
                  <div
                    className="form-group row"
                    style={{ marginTop: '0px', marginBottom: '0px' }}
                  >
                    <h5
                      className="col-xs-12 col-form-label"
                      style={{ marginLeft: '12px', marginBottom: '0' }}
                    >
                      Seller Information
                    </h5>
                  </div>

                  <div
                    className="form-group row"
                    style={{ marginTop: '0px', marginBottom: '0px' }}
                  >
                    <label
                      htmlFor="first_name"
                      className="col-xs-4 col-form-label"
                      style={{
                        margin: '13px 0 0 13px',
                        fontWeight: 'normal',
                        color: '#959595',
                      }}
                    >
                      First Name
                    </label>
                    <div className="col-xs-7">
                      <input
                        id="first_name"
                        type="text"
                        autoComplete="off"
                        value={values.first_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={{
                          border: 'none',
                          boxShadow: 'none',
                          backgroundColor: '#222222',
                          color: 'white',
                          fontSize: '16px',
                          paddingLeft: '0',
                        }}
                        className={cx('form-control input-lg', {
                          'has-error': errors.first_name && touched.first_name,
                        })}
                      />
                    </div>
                    {errors.first_name && touched.first_name && (
                      <div className="col-xs-8">
                        <div
                          className="invalid-feedback"
                          style={{ marginLeft: '15px' }}
                        >
                          {errors.first_name}
                        </div>
                      </div>
                    )}
                  </div>

                  <hr style={{ marginTop: '0', marginBottom: '0' }} />

                  <div
                    className="form-group row"
                    style={{ marginTop: '0px', marginBottom: '0px' }}
                  >
                    <label
                      htmlFor="last_name"
                      className="col-xs-4 col-form-label"
                      style={{
                        margin: '13px 0 0 13px',
                        fontWeight: 'normal',
                        color: '#959595',
                      }}
                    >
                      Last Name
                    </label>
                    <div className="col-xs-7">
                      <input
                        id="last_name"
                        type="text"
                        autoComplete="off"
                        value={values.last_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={{
                          border: 'none',
                          boxShadow: 'none',
                          backgroundColor: '#222222',
                          color: 'white',
                          fontSize: '16px',
                          paddingLeft: '0',
                        }}
                        className={cx('form-control input-lg', {
                          'has-error': errors.last_name && touched.last_name,
                        })}
                      />
                    </div>
                    {errors.last_name && touched.last_name && (
                      <div className="col-xs-8">
                        <div
                          className="invalid-feedback"
                          style={{ marginLeft: '15px' }}
                        >
                          {errors.last_name}
                        </div>
                      </div>
                    )}
                  </div>

                  <hr style={{ marginTop: '0', marginBottom: '0' }} />

                  <div
                    className="form-group row"
                    style={{ marginTop: '0px', marginBottom: '0px' }}
                  >
                    <label
                      htmlFor="phone"
                      className="col-xs-4 col-form-label"
                      style={{
                        margin: '13px 0 0 13px',
                        fontWeight: 'normal',
                        color: '#959595',
                      }}
                    >
                      Phone #
                    </label>
                    <div className="col-xs-7">
                      <input
                        id="phone"
                        type="phone"
                        autoComplete="off"
                        value={values.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={{
                          border: 'none',
                          boxShadow: 'none',
                          backgroundColor: '#222222',
                          color: 'white',
                          fontSize: '16px',
                          paddingLeft: '0',
                        }}
                        className={cx('form-control input-lg', {
                          'has-error': errors.phone && touched.phone,
                        })}
                      />
                    </div>
                    {errors.phone && touched.phone && (
                      <div className="col-xs-8">
                        <div
                          className="invalid-feedback"
                          style={{ marginLeft: '15px' }}
                        >
                          {errors.phone}
                        </div>
                      </div>
                    )}
                  </div>

                  <hr style={{ marginTop: '0', marginBottom: '0' }} />

                  <div
                    className="form-group row"
                    style={{ marginTop: '0px', marginBottom: '0px' }}
                  >
                    <label
                      htmlFor="street_address"
                      className="col-xs-4 col-form-label"
                      style={{
                        margin: '13px 0 0 13px',
                        fontWeight: 'normal',
                        color: '#959595',
                      }}
                    >
                      Address
                    </label>
                    <div className="col-xs-7">
                      <input
                        id="street_address"
                        type="text"
                        autoComplete="off"
                        value={values.street_address}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={{
                          border: 'none',
                          boxShadow: 'none',
                          backgroundColor: '#222222',
                          color: 'white',
                          fontSize: '16px',
                          paddingLeft: '0',
                        }}
                        className={cx('form-control input-lg', {
                          'has-error':
                            errors.street_address && touched.street_address,
                        })}
                      />
                    </div>
                    {errors.street_address && touched.street_address && (
                      <div className="col-xs-8">
                        <div
                          className="invalid-feedback"
                          style={{ marginLeft: '15px' }}
                        >
                          {errors.street_address}
                        </div>
                      </div>
                    )}
                  </div>

                  <hr style={{ marginTop: '0', marginBottom: '0' }} />

                  <div
                    className="form-group row"
                    style={{ marginTop: '0px', marginBottom: '0px' }}
                  >
                    <label
                      htmlFor="city"
                      className="col-xs-4 col-form-label"
                      style={{
                        margin: '13px 0 0 13px',
                        fontWeight: 'normal',
                        color: '#959595',
                      }}
                    >
                      City
                    </label>
                    <div className="col-xs-7">
                      <input
                        id="city"
                        type="text"
                        autoComplete="off"
                        value={values.city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={{
                          border: 'none',
                          boxShadow: 'none',
                          backgroundColor: '#222222',
                          color: 'white',
                          fontSize: '16px',
                          paddingLeft: '0',
                        }}
                        className={cx('form-control input-lg', {
                          'has-error': errors.city && touched.city,
                        })}
                      />
                    </div>
                    {errors.city && touched.city && (
                      <div className="col-xs-8">
                        <div
                          className="invalid-feedback"
                          style={{ marginLeft: '15px' }}
                        >
                          {errors.city}
                        </div>
                      </div>
                    )}
                  </div>

                  <hr style={{ marginTop: '0', marginBottom: '0' }} />

                  <div
                    className="form-group row"
                    style={{ marginTop: '0px', marginBottom: '0px' }}
                  >
                    <label
                      htmlFor="state"
                      className="col-xs-4 col-form-label"
                      style={{
                        margin: '13px 0 0 13px',
                        fontWeight: 'normal',
                        color: '#959595',
                      }}
                    >
                      State
                    </label>
                    <div className="col-xs-7">
                      <select
                        id="state"
                        autoComplete="off"
                        value={values.state}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={{
                          border: 'none',
                          boxShadow: 'none',
                          backgroundColor: '#222222',
                          color: 'white',
                          fontSize: '16px',
                          paddingLeft: '0',
                        }}
                        className={cx('form-control input-lg', {
                          'has-error': errors.state && touched.state,
                        })}
                      >
                        {states.map((state, index) => {
                          return (
                            <option key={index} value={state}>
                              {state}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                    {errors.state && touched.state && (
                      <div className="col-xs-8">
                        <div
                          className="invalid-feedback"
                          style={{ marginLeft: '15px' }}
                        >
                          {errors.state}
                        </div>
                      </div>
                    )}
                  </div>

                  <hr style={{ marginTop: '0', marginBottom: '0' }} />

                  <div
                    className="form-group row"
                    style={{ marginTop: '0px', marginBottom: '0px' }}
                  >
                    <label
                      htmlFor="zipcode"
                      className="col-xs-4 col-form-label"
                      style={{
                        margin: '13px 0 0 13px',
                        fontWeight: 'normal',
                        color: '#959595',
                      }}
                    >
                      Zip Code
                    </label>
                    <div className="col-xs-7">
                      <input
                        id="zipcode"
                        type="text"
                        autoComplete="off"
                        value={values.zipcode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={{
                          border: 'none',
                          boxShadow: 'none',
                          backgroundColor: '#222222',
                          color: 'white',
                          fontSize: '16px',
                          paddingLeft: '0',
                        }}
                        className={cx('form-control input-lg', {
                          'has-error': errors.zipcode && touched.zipcode,
                        })}
                      />
                    </div>
                    {errors.zipcode && touched.zipcode && (
                      <div className="col-xs-8">
                        <div
                          className="invalid-feedback"
                          style={{ marginLeft: '15px' }}
                        >
                          {errors.zipcode}
                        </div>
                      </div>
                    )}
                  </div>

                  <hr style={{ marginTop: '0', marginBottom: '0' }} />

                  <div
                    className="form-group row"
                    style={{ marginTop: '0px', marginBottom: '0px' }}
                  >
                    <label
                      htmlFor="country"
                      className="col-xs-4 col-form-label"
                      style={{
                        margin: '13px 0 0 13px',
                        fontWeight: 'normal',
                        color: '#959595',
                      }}
                    >
                      Country
                    </label>
                    <div className="col-xs-7">
                      <select
                        id="country"
                        autoComplete="off"
                        value={values.country}
                        onChange={e => {
                          handleChange(e)
                          this.setState({ country: e.target.value })
                          if (
                            e.target.value !== 'United States' &&
                            this.state.selectBank
                          )
                            this.setState({
                              selectBank: false,
                              selectPaypal: true,
                            })
                        }}
                        onBlur={handleBlur}
                        style={{
                          border: 'none',
                          boxShadow: 'none',
                          backgroundColor: '#222222',
                          color: 'white',
                          fontSize: '16px',
                          paddingLeft: '0',
                        }}
                        className={cx('form-control input-lg', {
                          'has-error': errors.country && touched.country,
                        })}
                      >
                        {countries.map((country, index) => {
                          return (
                            <option key={index} value={country}>
                              {country}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                    {errors.country && touched.country && (
                      <div className="col-xs-8">
                        <div
                          className="invalid-feedback"
                          style={{ marginLeft: '15px' }}
                        >
                          {errors.country}
                        </div>
                      </div>
                    )}
                  </div>

                  <hr style={{ marginTop: '0', marginBottom: '0' }} />

                  <div
                    className="row"
                    style={{ marginTop: '10px', marginBottom: '10px' }}
                  >
                    <h5
                      className="col-xs-12"
                      style={{
                        marginLeft: '15px',
                        fontWeight: 'normal',
                      }}
                    >
                      Where should we send your money?
                    </h5>
                  </div>

                  <div
                    style={{
                      color: 'white',
                    }}
                  >
                    <div
                      className="row"
                      style={{
                        marginTop: '0px',
                        marginBottom: '10px',
                        padding: '0 30px',
                      }}
                      onClick={() => {
                        if (!this.state.selectPaypal)
                          this.setState({
                            selectPaypal: !this.state.selectPaypal,
                            selectBank: false,
                          })
                      }}
                    >
                      <div
                        className="col-xs-12"
                        style={{
                          padding: '0 20px',
                          display: 'flex',
                          justifyContent: 'space-between',
                          backgroundColor: this.state.selectPaypal
                            ? '#01a3d0'
                            : '#2d2d2d',
                          alignItems: 'center',
                          borderRadius: '5px',
                          height: '58px',
                        }}
                      >
                        <h4>
                          {this.state.selectPaypal ? 'Use PayPal' : 'PayPal'}
                        </h4>
                        <div style={{ marginTop: '-7px' }}>
                          <Checkbox
                            color="#ffffff"
                            onChange={() => {
                              if (!this.state.selectPaypal)
                                this.setState({
                                  selectPaypal: !this.state.selectPaypal,
                                  selectBank: false,
                                })
                            }}
                            checked={this.state.selectPaypal}
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      className="row"
                      style={{
                        marginTop: '0px',
                        marginBottom: '10px',
                        padding: '0 30px',
                      }}
                      onClick={() => {
                        if (
                          this.state.country === 'United States' &&
                          !this.state.selectBank
                        )
                          this.setState({
                            selectBank: !this.state.selectBank,
                            selectPaypal: false,
                          })
                      }}
                    >
                      <div
                        className="col-xs-12"
                        style={{
                          padding: '0 20px',
                          display: 'flex',
                          justifyContent: 'space-between',
                          backgroundColor: this.state.selectBank
                            ? '#01a3d0'
                            : '#2d2d2d',
                          opacity:
                            values.country && values.country === 'United States'
                              ? 1
                              : 0.25,
                          alignItems: 'center',
                          borderRadius: '5px',
                          height: '58px',
                        }}
                      >
                        <h4>
                          {this.state.selectBank
                            ? 'Use Bank Account'
                            : 'Bank Account - US Only'}
                        </h4>
                        <div style={{ marginTop: '-7px' }}>
                          <Checkbox
                            color="#ffffff"
                            checked={this.state.selectBank}
                            onChange={() => {
                              if (
                                this.state.country === 'United States' &&
                                !this.state.selectBank
                              )
                                this.setState({
                                  selectBank: !this.state.selectBank,
                                  selectPaypal: false,
                                })
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    {this.state.selectPaypal ? (
                      <div>
                        <div
                          className="form-group row"
                          style={{ marginTop: '20px', marginBottom: '0px' }}
                        >
                          <h5
                            className="col-xs-12 col-form-label"
                            style={{ marginLeft: '12px', marginBottom: '0' }}
                          >
                            Account Information
                          </h5>
                        </div>

                        <div
                          className="form-group row"
                          style={{ marginTop: '0px', marginBottom: '0px' }}
                        >
                          <label
                            htmlFor="venmo_email"
                            className="col-xs-5 col-form-label"
                            style={{
                              margin: '13px 0 0 13px',
                              fontWeight: 'normal',
                              color: '#959595',
                            }}
                          >
                            PayPal Email
                          </label>
                          <div className="col-xs-6">
                            <input
                              id="venmo_email"
                              type="email"
                              placeholder="Required"
                              autoComplete="off"
                              value={values.venmo_email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              style={{
                                border: 'none',
                                boxShadow: 'none',
                                backgroundColor: '#222222',
                                color: 'white',
                                fontSize: '16px',
                                paddingLeft: '0',
                              }}
                              className={cx('form-control input-lg', {
                                'has-error':
                                  errors.venmo_email && touched.venmo_email,
                              })}
                            />
                          </div>
                          {errors.venmo_email && touched.venmo_email && (
                            <div className="col-xs-8">
                              <div
                                className="invalid-feedback"
                                style={{ marginLeft: '15px' }}
                              >
                                {errors.venmo_email}
                              </div>
                            </div>
                          )}
                        </div>

                        <hr style={{ marginTop: '0', marginBottom: '0' }} />

                        <div
                          className="row"
                          style={{ marginTop: '10px', marginBottom: '10px' }}
                        >
                          <h5
                            className="col-xs-12"
                            style={{
                              marginLeft: '15px',
                              marginBottom: '10px',
                              fontWeight: 'normal',
                            }}
                          >
                            Your money will be sent to your PayPal account.
                          </h5>
                        </div>
                      </div>
                    ) : null}

                    {this.state.selectBank ? (
                      <div>
                        <div
                          className="form-group row"
                          style={{ marginTop: '20px', marginBottom: '0px' }}
                        >
                          <h5
                            className="col-xs-12 col-form-label"
                            style={{ marginLeft: '12px', marginBottom: '0' }}
                          >
                            Account Information
                          </h5>
                        </div>

                        <div
                          className="form-group row"
                          style={{ marginTop: '0px', marginBottom: '0px' }}
                        >
                          <label
                            htmlFor="account_number"
                            className="col-xs-5 col-form-label"
                            style={{
                              margin: '13px 0 0 13px',
                              fontWeight: 'normal',
                              color: '#959595',
                            }}
                          >
                            Account#
                          </label>
                          <div className="col-xs-6">
                            <input
                              id="account_number"
                              type="number"
                              autoComplete="off"
                              value={values.account_number}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              style={{
                                border: 'none',
                                boxShadow: 'none',
                                backgroundColor: '#222222',
                                color: 'white',
                                fontSize: '16px',
                                paddingLeft: '0',
                              }}
                              className={cx('form-control input-lg', {
                                'has-error':
                                  errors.account_number &&
                                  touched.account_number,
                              })}
                            />
                          </div>
                          {errors.account_number && touched.account_number && (
                            <div className="col-xs-8">
                              <div
                                className="invalid-feedback"
                                style={{ marginLeft: '15px' }}
                              >
                                {errors.account_number}
                              </div>
                            </div>
                          )}
                        </div>

                        <hr style={{ marginTop: '0', marginBottom: '0' }} />

                        <div
                          className="form-group row"
                          style={{ marginTop: '0px', marginBottom: '0px' }}
                        >
                          <label
                            htmlFor="confirm_account_number"
                            className="col-xs-5 col-form-label"
                            style={{
                              margin: '13px 0 0 13px',
                              fontWeight: 'normal',
                              color: '#959595',
                            }}
                          >
                            Confirm Account#
                          </label>
                          <div className="col-xs-6">
                            <input
                              id="confirm_account_number"
                              type="number"
                              autoComplete="off"
                              value={values.confirm_account_number}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              style={{
                                border: 'none',
                                boxShadow: 'none',
                                backgroundColor: '#222222',
                                color: 'white',
                                fontSize: '16px',
                                paddingLeft: '0',
                              }}
                              className={cx('form-control input-lg', {
                                'has-error':
                                  errors.confirm_account_number &&
                                  touched.confirm_account_number,
                              })}
                            />
                          </div>
                          {errors.confirm_account_number &&
                            touched.confirm_account_number && (
                              <div className="col-xs-8">
                                <div
                                  className="invalid-feedback"
                                  style={{ marginLeft: '15px' }}
                                >
                                  {errors.confirm_account_number}
                                </div>
                              </div>
                            )}
                        </div>

                        <hr style={{ marginTop: '0', marginBottom: '0' }} />

                        <div
                          className="form-group row"
                          style={{ marginTop: '0px', marginBottom: '0px' }}
                        >
                          <label
                            htmlFor="routing_number"
                            className="col-xs-5 col-form-label"
                            style={{
                              margin: '13px 0 0 13px',
                              fontWeight: 'normal',
                              color: '#959595',
                            }}
                          >
                            Routing#
                          </label>
                          <div className="col-xs-6">
                            <input
                              id="routing_number"
                              type="number"
                              autoComplete="off"
                              value={values.routing_number}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              style={{
                                border: 'none',
                                boxShadow: 'none',
                                backgroundColor: '#222222',
                                color: 'white',
                                fontSize: '16px',
                                paddingLeft: '0',
                              }}
                              className={cx('form-control input-lg', {
                                'has-error':
                                  errors.routing_number &&
                                  touched.routing_number,
                              })}
                            />
                          </div>
                          {errors.routing_number && touched.routing_number && (
                            <div className="col-xs-8">
                              <div
                                className="invalid-feedback"
                                style={{ marginLeft: '15px' }}
                              >
                                {errors.routing_number}
                              </div>
                            </div>
                          )}
                        </div>

                        <hr style={{ marginTop: '0', marginBottom: '0' }} />

                        <div
                          className="form-group row"
                          style={{ marginTop: '0px', marginBottom: '0px' }}
                        >
                          <label
                            htmlFor="confirm_routing_number"
                            className="col-xs-5 col-form-label"
                            style={{
                              margin: '13px 0 0 13px',
                              fontWeight: 'normal',
                              color: '#959595',
                            }}
                          >
                            Confirm Routing#
                          </label>
                          <div className="col-xs-6">
                            <input
                              id="confirm_routing_number"
                              type="number"
                              autoComplete="off"
                              value={values.confirm_routing_number}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              style={{
                                border: 'none',
                                boxShadow: 'none',
                                backgroundColor: '#222222',
                                color: 'white',
                                fontSize: '16px',
                                paddingLeft: '0',
                              }}
                              className={cx('form-control input-lg', {
                                'has-error':
                                  errors.confirm_routing_number &&
                                  touched.confirm_routing_number,
                              })}
                            />
                          </div>
                          {errors.confirm_routing_number &&
                            touched.confirm_routing_number && (
                              <div className="col-xs-8">
                                <div
                                  className="invalid-feedback"
                                  style={{ marginLeft: '15px' }}
                                >
                                  {errors.confirm_routing_number}
                                </div>
                              </div>
                            )}
                        </div>

                        <hr style={{ marginTop: '0', marginBottom: '0' }} />

                        <div
                          className="row"
                          style={{ marginTop: '10px', marginBottom: '0px' }}
                        >
                          <h5
                            className="col-xs-12"
                            style={{
                              marginLeft: '15px',
                              marginBottom: '10px',
                              fontWeight: 'normal',
                            }}
                          >
                            Your money will be sent to your bank account.
                          </h5>
                        </div>
                      </div>
                    ) : null}

                    <div className="container-fluid">
                      <button
                        className="btn btn-block btn-lg"
                        style={{
                          color: 'white',
                          backgroundColor: '#01a3d0',
                          borderRadius: '4px',
                          marginTop: '15px',
                          height: '50px',
                        }}
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Cash Out
                      </button>
                    </div>
                  </div>

                  {user.error_message && (
                    <div className="invalid-feedback">{user.error_message}</div>
                  )}
                </form>
              )
            }}
          </Formik>

          <div
            className="container-fluid"
            style={{
              marginBottom: '15px',
            }}
          >
            <button
              className="btn btn-block btn-lg"
              style={{
                marginTop: '15px',
                borderColor: '#01a3d0',
                borderRadius: '4px',
                backgroundColor: '#222222',
                height: '65px',
                color: '#01a3d0',
              }}
              onClick={() =>
                (window.location.href = 'https://www.uenme.co/faq')
              }
            >
              Frequently Asked Questions
            </button>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user ? state.user : {},
})

const mapDispatchToProps = dispatch => ({
  dispatch,
  actions: bindActionCreators(
    {
      ...userActions,
    },
    dispatch
  ),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withLastLocation(CashOut))
)
