import React, { Component } from 'react'

class DeleteAccount extends Component {
  render() {
    return (
      <div className="form-group">
        <button
          style={{
            borderRadius: 0,
            border: 'none',
            margin: '0 20px',
            width: 'calc(100% - 40px)',
            backgroundColor: '#848689',
          }}
          onClick={this.props.deleteUser}
          className="btn btn-danger btn-lg"
        >
          Delete Account
        </button>
      </div>
    )
  }
}

export default DeleteAccount
