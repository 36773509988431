import React, { Component } from 'react'
import { dateTimeNow, timeSince } from '../../routes/Chat/Date'
import { Lightbox } from 'react-modal-image'

class ReceivedPhoto extends Component {
  state = {
    open: false,
  }

  closeLightbox = () => {
    this.setState({ open: false })
  }

  openLightbox = () => {
    this.setState({ open: true })
  }

  render() {
    /** @type {{to: string, from: string, message?: string, alert?: string, action?: string, cost_per_min?: number, total_call_cost?: number, message_date_time?: string, call_length?:number, environment?: string, media_url?: string, filename?: string, userAvatarFileName?: string, username?: string}} */
    const message = this.props.message
    return (
      <div
        style={{
          textAlign: 'left',
          marginLeft: '15px',
        }}
      >
        <img
          width="200"
          onClick={this.openLightbox}
          style={{
            borderRadius: '8% 8% 8% 0',
          }}
          src={message.media_url}
          alt="from user"
        />
        <div>{timeSince(message.message_date_time || dateTimeNow())}</div>
        {this.state.open && (
          <Lightbox
            hideDownload={true}
            medium={message.media_url}
            onClose={this.closeLightbox}
          />
        )}
      </div>
    )
  }
}

export default ReceivedPhoto
