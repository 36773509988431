import React, { Component } from 'react'

import AccountLayout from '../../layout'

export default class ShareProfile extends Component {
  render() {
    return (
      <AccountLayout>
        <h3>Share Profile</h3>
      </AccountLayout>
    )
  }
}
