import React, { Component } from 'react'
import cx from 'classnames'
import styles from './GenderButton.module.scss'

class GenderButton extends Component {

  onClick = e => {
    e.preventDefault()
    this.props.onClick(this.props.gender)
  }

  render() {
    const { gender, active } = this.props
    const genderLowerCase = gender.toLowerCase()
    return (
      <div
        onClick={this.onClick}
        className={cx(styles.genderIconWrapper, {
          [styles.genderIconWrapperActive]: active,
        })}
      >
        <i
          className={cx(`fa fa-${genderLowerCase}`, styles.genderIcon, {
            [styles.genderIconActive]: active,
          })}
        />
      </div>
    )
  }
}
export default GenderButton
